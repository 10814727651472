import ApartmentIcon from '@mui/icons-material/Apartment';
import {
  Box,
  Button,
  Divider,
  Avatar,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { ComponentProps, Fragment, useCallback, useState } from 'react';

import { ReviewDialog } from '../Review/ReviewDialog';

import { ReviewItem } from '@app/components/Users/ReviewItem';
import { Product } from '@app/types/catalog';
import { Review } from '@app/types/review';
import { getProductDayWeekTitle } from '@app/utils/catalog';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

export interface UserProfileProps {
  headerContents?: React.ReactNode;
  isAdmin?: boolean;
  loading?: boolean;
  onChange?: (reviews: Review[] | undefined) => void;
  onClickNext?: () => void;
  reviewProducts?: Product[];
  reviews?: Review[];
  reviewsDisplayCount?: number;
  reviewsTotal?: number;
}

export function UserReviews({
  reviews,
  reviewsTotal,
  reviewProducts,
  onClickNext,
  reviewsDisplayCount,
  loading,
  onChange,
  headerContents,
  isAdmin,
}: UserProfileProps) {
  const [isOpenReviewDialog, setIsOpenReviewDialog] = useState(false);
  const [selectReview, setSelectReview] = useState(
    undefined as Review | undefined
  );
  const theme = useTheme();

  const handleClosReviewDialog: ComponentProps<typeof ReviewDialog>['onClose'] =
    useCallback(
      (confirm, newReview) => {
        setIsOpenReviewDialog(false);
        if (confirm === 'confirm') {
          onChange?.(
            reviews
              ? [...reviews].map((r) =>
                  r.id === newReview?.id ? newReview : r
                )
              : undefined
          );
        }
      },
      [onChange, reviews]
    );

  if (loading) {
    return (
      <ColBox gap={2}>
        <Typography fontSize="1.8rem">評価</Typography>
        <Divider sx={{ width: '50rem' }} />
        <ColBox width="50rem" alignItems="center" justifyContent="center" p={1}>
          <CircularProgress />
        </ColBox>
      </ColBox>
    );
  }

  return (
    <ColBox gap={2} id="review">
      <RowBox alignItems="center" gap={2} width="50.5rem">
        <Typography fontSize="1.8rem">
          評価{`（${reviewsTotal ?? '-'}件）`}
        </Typography>
        {headerContents}
      </RowBox>
      <ColBox gap={2} width="fit-content" mb={5}>
        {reviewsTotal === 0 && (
          <>
            <Divider sx={{ width: '50rem' }} />
            <ColBox
              alignItems="center"
              justifyContent="center"
              height="4rem"
              bgcolor={theme.palette.grey[100]}
              border={`solid 2px ${theme.palette.grey[300]}`}
              borderRadius={2}
            >
              <Typography fontWeight={600}>まだ評価はありません</Typography>
            </ColBox>
          </>
        )}
        {reviews
          ?.filter((r, i) => i < (reviewsDisplayCount ?? 0))
          .map((review, i, ary) => {
            const prev = ary[i - 1];
            const productId =
              prev?.customFields?.productId !== review.customFields?.productId
                ? review.customFields?.productId
                : undefined;
            const product = reviewProducts?.find((p) => p.id === productId);
            return (
              <Fragment key={i}>
                {productId && (
                  <ColBox gap={2}>
                    <Divider sx={{ width: '50rem' }} />
                    <RowBox alignItems="center" gap={2}>
                      <Avatar
                        sx={{
                          backgroundColor: '#53BAC8',
                          height: 32,
                          width: 32,
                        }}
                      >
                        <ApartmentIcon />
                      </Avatar>
                      <Typography>
                        {getProductDayWeekTitle(product) || productId}
                        の来店先
                      </Typography>
                    </RowBox>
                  </ColBox>
                )}
                <ReviewItem
                  review={review}
                  onClickEdit={() => {
                    setSelectReview(review);
                    setIsOpenReviewDialog(true);
                  }}
                  isAdmin={isAdmin}
                />
              </Fragment>
            );
          })}
        {(reviewsTotal ?? 0) > (reviewsDisplayCount ?? 0) && (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ mx: 'auto', width: '8rem' }}
            onClick={onClickNext}
          >
            続きを見る
          </Button>
        )}
      </ColBox>

      <ReviewDialog
        open={isOpenReviewDialog}
        review={selectReview}
        onClose={handleClosReviewDialog}
        isAdmin
      />
    </ColBox>
  );
}
