import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Toolbar,
  Typography,
  useTheme,
  styled,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
  ComponentProps,
  useRef,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  getOrdersListWithFilter,
  updateOrderStatus,
} from '../adapter/order-service';
import { OrderTableComponent } from '../components/Orders/OrderTableComponent';
import {
  CustomTabPanel,
  TabsComponent,
} from '../components/Orders/TabsComponent';
import { MonthSelect } from '../components/Shared/MonthSelect';
import { PageTitle } from '../components/Shared/PageTitle';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '../domain/app';
import { tabIndexState } from '../domain/order';
import { organizationSelector } from '../domain/organization';
import { SupportedOrderStatus, OrderLocalized } from '../types/order';
import { defaultOrderStatusFilters } from '../utils/constants';
import { isError } from '../utils/error';
import { convertOrderLocalized } from '../utils/order';

import { setProductsStatus } from '@app/adapter/catalog-service';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export function Orders(): ReactElement {
  const theme = useTheme();
  const [pendingOrders, setPendingOrders] = useState<OrderLocalized[]>([]);
  const [acceptedOrders, setAcceptedOrders] = useState<OrderLocalized[]>([]);
  const [processingOrders, setProcessingOrders] = useState<OrderLocalized[]>(
    []
  );
  const [waitingOrders, setWaitingOrders] = useState<OrderLocalized[]>([]);
  const [allOrders, setAllOrders] = useState<OrderLocalized[]>([]);
  const organizationState = useRecoilValue(organizationSelector);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [tabIndex, setTabIndex] = useRecoilState(tabIndexState);
  const [keywordFilter, setKeywordFilter] = useState('');
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [processingCount, setProcessingCount] = useState(0);
  const [waitingCount, setWaitingCount] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isOpenAcceptedDialog, setIsOpenAcceptedDialog] = useState(false);
  const [isOpenCanceledDialog, setIsOpenCanceledDialog] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isProductArchived, setIsProductArchived] = useState(false);

  // データ取得に使用するタイマー（無駄な検索が走らないための対策用）
  const loadTimerRef = useRef({
    ms: 0,
    timeoutId: undefined as NodeJS.Timeout | undefined,
  });
  const selectedOrders = selectedIDs.map((id) =>
    pendingOrders.find((o) => o.id === id)
  );
  const selectedName = `チェックした${selectedOrders.length}名`;

  const getOrdersStatus = useCallback(
    async (status?: SupportedOrderStatus) => {
      if (!organizationState || !organizationState.id) {
        console.error(
          'organizationState or organizationState.id is not defined'
        );
        return;
      }
      try {
        const result = await getOrdersListWithFilter(organizationState.id, {
          order: 'productType,customFields.productDay,createdAt desc',
          pageNumber: pagination.page,
          pageSize: pagination.pageSize,
          statuses: status ? [status] : defaultOrderStatusFilters,
          ...(keywordFilter ? { keyword: keywordFilter } : {}),
          ...(startMonth && endMonth
            ? {
                dateRange: {
                  end: endOfMonth(new Date(endMonth)).toISOString(),
                  isEmpty: true,
                  start: startOfMonth(new Date(startMonth)).toISOString(),
                },
              }
            : {}),
        });
        if (result.status === 200) {
          const orderList = result.data.value.map((order) =>
            convertOrderLocalized(order)
          );
          switch (status) {
            case 'PENDING':
              setPendingOrders(orderList);
              setPendingCount(result.data.total);
              break;
            case 'ACCEPTED':
              setAcceptedOrders(orderList);
              setAcceptedCount(result.data.total);
              break;
            case 'PROCESSING':
              setProcessingOrders(orderList);
              setProcessingCount(result.data.total);
              break;
            case 'WAITING':
              setWaitingOrders(orderList);
              setWaitingCount(result.data.total);
              break;
            case undefined:
              setAllOrders(orderList);
              setAllCount(result.data.total);
              break;
          }
        } else {
          setSnackbarText(`応募の取得に失敗しました, ${result.status}`);
          setSnackbarOpen(true);
        }
      } catch (error: unknown) {
        if (isError(error)) {
          setSnackbarText(`応募の取得に失敗しました, ${error.message}`);
        } else {
          setSnackbarText(`応募の取得に失敗しました`);
        }
        setSnackbarOpen(true);
      }
    },
    [
      organizationState,
      startMonth,
      endMonth,
      keywordFilter,
      pagination,
      setSnackbarOpen,
      setSnackbarText,
    ]
  );

  const getOrders = useCallback(async () => {
    setIsLoadingOrder(true);
    await getOrdersStatus('PENDING');
    await getOrdersStatus('ACCEPTED');
    await getOrdersStatus('PROCESSING');
    await getOrdersStatus('WAITING');
    await getOrdersStatus();
    setIsLoadingOrder(false);
  }, [getOrdersStatus]);

  useEffect(() => {
    // NOTE:無駄な検索防止に一定時間検索待機
    clearTimeout(loadTimerRef.current.timeoutId);
    const timeoutId = setTimeout(() => {
      void getOrders();
    }, loadTimerRef.current.ms);
    loadTimerRef.current = { ms: 0, timeoutId };
  }, [getOrders]);

  const statusChange = useCallback(
    async (newStatus: SupportedOrderStatus) => {
      // TODO:ループしているが将来的にはAPIで一括更新したほうが良い
      for (const order of selectedOrders) {
        if (!order) {
          continue;
        }
        try {
          await updateOrderStatus(order.organization, order.id, newStatus);
        } catch (error) {
          setSnackbarText(
            `応募の更新に失敗しました, ${
              error instanceof Error ? error.message : error
            }`
          );
          setSnackbarOpen(true);
        }
      }
    },
    [selectedOrders, setSnackbarText, setSnackbarOpen]
  );

  const updateProductArchived = useCallback(async () => {
    const productIds = selectedOrders
      .map((o) => o?.lineItem.product as string)
      .filter((id) => id);
    if (!productIds.length) {
      return;
    }
    try {
      const result = await setProductsStatus(
        organizationState.id,
        productIds,
        'ARCHIVED'
      );
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
    } catch (error) {
      setSnackbarText(
        `プランの更新に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [selectedOrders, organizationState, setSnackbarText, setSnackbarOpen]);

  const handleClickAcceptedButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenAcceptedDialog(true);
    }, []);

  const handleChangeProductArchived: NonNullable<
    ComponentProps<typeof Checkbox>['onChange']
  > = useCallback((e, checked) => {
    setIsProductArchived(checked);
  }, []);

  const handleCloseAcceptedDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm) {
        setIsOpenAcceptedDialog(false);
        return;
      }
      setIsLoadingDialog(true);
      await statusChange('ACCEPTED');
      if (isProductArchived) {
        await updateProductArchived();
      }
      // TODO:予約承認メールを送信する
      setIsLoadingDialog(false);
      setIsOpenAcceptedDialog(false);
      setSnackbarText(`${selectedName}を予約承認しました。`);
      setSnackbarState('info');
      setSnackbarOpen(true);
      await getOrders();
    },
    [
      statusChange,
      isProductArchived,
      updateProductArchived,
      selectedName,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
      getOrders,
    ]
  );

  const handleClickCanceledButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenCanceledDialog(true);
    }, []);

  const handleCloseCanceledDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm) {
        setIsOpenCanceledDialog(false);
        return;
      }
      setIsLoadingDialog(true);
      await statusChange('CANCELED');
      // TODO:予約否認メールを送信する
      setIsLoadingDialog(false);
      setIsOpenCanceledDialog(false);
      setSnackbarText(`${selectedName}さんを予約否認しました。`);
      setSnackbarState('info');
      setSnackbarOpen(true);
      await getOrders();
    },
    [
      statusChange,
      selectedName,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
      getOrders,
    ]
  );

  const handleChangeKeywordFilter = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      loadTimerRef.current = { ...loadTimerRef.current, ms: 1000 }; //NOTE:無駄な検索防止に一定時間検索待機
      setKeywordFilter(target.value);
    },
    []
  );

  const handleStartDateChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadTimerRef.current = { ...loadTimerRef.current, ms: 500 }; //NOTE:無駄な検索防止に一定時間検索待機
    setStartMonth(value);
  }, []);

  const handleEndDateChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadTimerRef.current = { ...loadTimerRef.current, ms: 500 }; //NOTE:無駄な検索防止に一定時間検索待機
    setEndMonth(value);
  }, []);

  return (
    <>
      <Box sx={{ mb: 3, mt: 1 }}>
        <Box alignItems="baseline" display="flex" gap={5}>
          <PageTitle title="応募一覧" data-e2e="product-title" />
          <Box
            alignItems="baseline"
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={{ mr: 1 }}>期間</Typography>
            <MonthSelect
              value={startMonth}
              onChange={handleStartDateChange}
              isEmpty
            />
            <Typography sx={{ pl: 1, pr: 1 }}>〜</Typography>
            <MonthSelect
              value={endMonth}
              onChange={handleEndDateChange}
              isEmpty
            />
          </Box>
        </Box>
      </Box>

      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <TabsComponent
              value={tabIndex}
              onChange={(event, newValue) => setTabIndex(newValue)}
              acceptedCount={acceptedCount}
              pendingCount={pendingCount}
              processingCount={processingCount}
              waitingCount={waitingCount}
            />
          </Grid>
          {tabIndex === 0 && selectedIDs.length > 0 && (
            <>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClickAcceptedButton}
                  disabled={selectedIDs.length === 0}
                >
                  一括予約承認
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ color: theme.palette.error.main }}
                  onClick={handleClickCanceledButton}
                  disabled={selectedIDs.length === 0}
                >
                  一括予約否認
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        <Toolbar sx={{ px: '1rem !important' }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                placeholder="プランタイトルで検索"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={keywordFilter}
                onChange={handleChangeKeywordFilter}
              />
            </Grid>
          </Grid>
        </Toolbar>

        <CustomTabPanel value={tabIndex} index={0}>
          <OrderTableComponent
            orders={pendingOrders}
            selectionIDs={selectedIDs}
            setSelectedIDs={setSelectedIDs}
            rowCount={pendingCount}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            loading={isLoadingOrder}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <OrderTableComponent
            orders={acceptedOrders}
            rowCount={acceptedCount}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            loading={isLoadingOrder}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <OrderTableComponent
            orders={processingOrders}
            rowCount={processingCount}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            loading={isLoadingOrder}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={3}>
          <OrderTableComponent
            orders={waitingOrders}
            rowCount={waitingCount}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            loading={isLoadingOrder}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={4}>
          <OrderTableComponent
            orders={allOrders}
            rowCount={allCount}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            loading={isLoadingOrder}
          />
        </CustomTabPanel>
      </Paper>

      <ConfirmDialog
        title={`${selectedName}を予約承認しますか？`}
        okButtonText="予約承認する"
        okButtonLoading={isLoadingDialog}
        open={isOpenAcceptedDialog}
        onClose={handleCloseAcceptedDialog}
      >
        <ColBox width="24rem" gap={2}>
          <Typography>
            「予約承認する」を押すと、{selectedName}に予約承認通知が送られます。
          </Typography>
          <Typography color={theme.palette.grey[500]}>
            ※予約承認後のキャンセルはご遠慮ください。
          </Typography>
          <ColBox p={2}>
            <FormControlLabel
              sx={{ width: 'fit-content' }}
              control={
                <Checkbox
                  checked={isProductArchived}
                  onChange={handleChangeProductArchived}
                />
              }
              label="対象のプランについては応募を締め切る"
            />
          </ColBox>
        </ColBox>
      </ConfirmDialog>
      <ConfirmDialog
        title={`${selectedName}さんを予約否認しますか？`}
        okButtonText="予約否認する"
        okButtonLoading={isLoadingDialog}
        open={isOpenCanceledDialog}
        onClose={handleCloseCanceledDialog}
      >
        <ColBox width="24rem" gap={2}>
          <Typography>予約否認のメッセージを送ります。</Typography>
        </ColBox>
      </ConfirmDialog>
    </>
  );
}
