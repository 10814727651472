import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Link as MuiLink,
  Divider,
  Typography,
  useTheme,
  BoxProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { ReactNode } from 'react';

import { SocialMediaIcons } from '../Orders/SocialMediaIcons';

import { StarsRating } from '@app/components/Shared/StarsRating';
import { SupportedOrderStatus } from '@app/types/order';
import { User } from '@app/types/user';
import { getAge } from '@app/utils';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function ItemBox({
  children,
  title,
  sx,
  ...boxProps
}: { title: ReactNode } & Omit<BoxProps, 'title'>) {
  return (
    <RowBox
      alignItems="center"
      {...boxProps}
      sx={[...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
      gap={1}
    >
      <ColBox width="12rem" fontSize="0.9rem">
        {title}
      </ColBox>
      {children}
    </RowBox>
  );
}

export interface UserProfileProps {
  averageRate?: number;
  orderStatus?: SupportedOrderStatus;
  reviewsTotal?: number;
  user?: User;
}

export function UserProfile({
  user,
  averageRate,
  reviewsTotal,
  orderStatus,
}: UserProfileProps) {
  const theme = useTheme();
  return (
    <ColBox gap={2}>
      <Typography>プロフィール</Typography>
      <Divider sx={{ my: 1, width: '30rem' }} />
      <ItemBox title="評価">
        <RowBox gap={1} alignItems="center">
          {!reviewsTotal ? (
            <Typography>-</Typography>
          ) : (
            <>
              <Typography>{averageRate}</Typography>
              <StarsRating
                rate={averageRate}
                iconProps={{ fontSize: 'small' }}
              />
              <MuiLink href="#review" color={theme.palette.text.primary}>
                <Typography fontSize="0.9rem">
                  {reviewsTotal ?? '-'}件の評価
                </Typography>
              </MuiLink>
            </>
          )}{' '}
        </RowBox>
      </ItemBox>
      <Divider sx={{ my: 1, width: '30rem' }} />
      <ItemBox title="性別/年齢">
        <RowBox gap={1} alignItems="center">
          <Typography>{user?.customFields?.gender || '-'}</Typography>
          <Typography>/</Typography>
          <Typography>
            {getAge(user?.customFields?.birthday) || '-'}歳
          </Typography>
        </RowBox>
      </ItemBox>
      <Divider sx={{ my: 1, width: '40rem' }} />
      <ItemBox title="連絡先">
        <ColBox gap={1}>
          {!user?.addressLine1 &&
          !user?.addressLine2 &&
          !user?.addressLine3 &&
          !user?.customFields?.addressLine4 &&
          !user?.phoneNumber &&
          !user?.email ? (
            <Typography>-</Typography>
          ) : (
            <>
              <Typography>
                {user?.addressLine1}
                {user?.addressLine2}
                {user?.addressLine3}
                {user?.customFields?.addressLine4}
              </Typography>
              <Typography>{user?.phoneNumber}</Typography>
              <Typography>{user?.email}</Typography>
            </>
          )}
        </ColBox>
      </ItemBox>
      <Divider sx={{ my: 1, width: '40rem' }} />

      <ItemBox title="SNS名">
        <ColBox gap={1}>
          <Typography>{user?.customFields?.socialMediaName || '-'}</Typography>
        </ColBox>
      </ItemBox>
      <Divider sx={{ my: 1, width: '40rem' }} />
      <ItemBox title="利用SNS">
        <ColBox gap={1}>
          {user?.customFields?.socialMediaUrl &&
          user.customFields.socialMediaUrl.length > 0 ? (
            <SocialMediaIcons urls={user.customFields.socialMediaUrl} />
          ) : (
            <Typography>-</Typography>
          )}
        </ColBox>
      </ItemBox>
      <Divider sx={{ my: 1, width: '40rem' }} />
      <ItemBox title="利用SNSのURL">
        <ColBox gap={1}>
          {user?.customFields?.socialMediaUrl ? (
            user.customFields.socialMediaUrl.map((c: string, i: number) => (
              <a href={c} target="_blank" rel="noopener noreferrer" key={i}>
                <Typography>{c}</Typography>
              </a>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </ColBox>
      </ItemBox>
      <Divider sx={{ my: 1, width: '40rem' }} />
      <ItemBox title="特記事項">
        <ColBox gap={1}>
          <Typography>{user?.customFields?.notes || '-'}</Typography>
        </ColBox>
      </ItemBox>
      <Divider sx={{ my: 1, width: '40rem' }} />
      <ItemBox title="添付資料名">
        <ColBox gap={2}>
          {!user?.customFields?.orderAfterFiles?.length &&
          !user?.customFields?.orderMatchedFiles?.length ? (
            <Typography>-</Typography>
          ) : (
            [
              ...(user?.customFields?.orderAfterFiles ?? []),
              ...(!(
                !orderStatus ||
                orderStatus === 'PENDING' ||
                orderStatus === 'CANCELED'
              )
                ? user?.customFields?.orderMatchedFiles ?? []
                : []),
            ].map((f, i) => {
              return (
                <RowBox minWidth="25rem" key={i}>
                  <Typography>{f.name}</Typography>
                  <RowBox
                    alignItems="center"
                    gap={0.5}
                    color={theme.palette.primary.light}
                    ml="auto"
                  >
                    <OpenInNewIcon fontSize="small" />
                    <MuiLink target="_blank" href={f.fileUrl}>
                      表示する
                    </MuiLink>
                  </RowBox>
                </RowBox>
              );
            })
          )}
        </ColBox>
      </ItemBox>
      <Divider sx={{ mb: 5, mt: 1, width: '40rem' }} />
    </ColBox>
  );
}
