import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { Controller, useForm, FieldErrors } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { ProgressStepper } from '@app/components/Registration/Confirmation/ProgressStepper';
import { userState } from '@app/domain/register';
import { UseInfoForm, UserInfoFormData } from '@app/schemas/registration';

interface OrganizationFormProps {
  onSubmit: (formData: UserInfoFormData) => void;
}

/**
 * blocks-5f90
 *
 * A form to let new vendors put their information.
 * After they submit the information, tanty admins will give them checkups.
 */
export function UserInfoForm({
  onSubmit,
}: OrganizationFormProps): ReactElement {
  const [userData, setUserData] = useRecoilState(userState);
  const { control, handleSubmit, formState } = useForm<UserInfoFormData>({
    defaultValues: userData,
    mode: 'onChange',
    resolver: UseInfoForm.resolver,
  });

  const { isValid } = formState;

  const onSubmitValid = useCallback(
    (data: UserInfoFormData) => {
      setUserData((prev) => ({ ...prev, ...data }));
      onSubmit(data);
    },
    [onSubmit, setUserData]
  );
  const onSubmitInvalid = useCallback(
    (errors: FieldErrors<UserInfoFormData>) => {
      console.debug('[UserInfoFrom] onSubmitInvalid()', errors);
    },
    []
  );

  return (
    <Box>
      <ProgressStepper activeStep={0} />
      <Typography
        variant="h6"
        fontWeight={700}
        textAlign="center"
        sx={{ mb: 5 }}
      >
        ユーザー情報
      </Typography>
      <Typography variant="body2" textAlign="left" gutterBottom sx={{ mb: 5 }}>
        主に使用する担当者様の情報をご記入ください
        <br />
        <Typography component="span" color="error">
          *
        </Typography>
        は必須事項です
      </Typography>

      <form onSubmit={handleSubmit(onSubmitValid, onSubmitInvalid)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                >
                  姓
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.familyName"
                control={control}
                defaultValue={userData.customFields.familyName}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={userData.customFields.familyName}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        customFields: {
                          ...userData.customFields,
                          familyName: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'姓を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                >
                  名
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.firstName"
                control={control}
                defaultValue={userData.customFields.firstName}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={userData.customFields.firstName}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        customFields: {
                          ...userData.customFields,
                          firstName: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'名を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                >
                  姓(かな)
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.familyNameKana"
                control={control}
                defaultValue={userData.customFields.familyNameKana}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={userData.customFields.familyNameKana}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        customFields: {
                          ...userData.customFields,
                          familyNameKana: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'姓(せい)を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                >
                  名(かな)
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.firstNameKana"
                control={control}
                defaultValue={userData.customFields.firstNameKana}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={userData.customFields.firstNameKana}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        customFields: {
                          ...userData.customFields,
                          firstNameKana: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'名(めい)を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <Grid item xs={12} sx={{ pt: 6, px: 3 }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {}}
                disabled={!isValid}
              >
                保存して次へ
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
