import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { AsYouType } from 'libphonenumber-js';
import React, { ReactNode, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getAttributeById } from '@app/adapter/catalog-service';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { loggedInUserState } from '@app/domain/app';
import { organization as organizationAtom } from '@app/domain/organization';
import { theme } from '@app/theme';
import { unescapeAndConvertNewLines, unescapeHtml } from '@app/utils/pattern';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

const RowItem: React.FC<{
  children?: ReactNode;
  title: string;
}> = ({ children = null, title }) => {
  return (
    <>
      <Grid container p={2}>
        <Grid item xs={3}>
          <strong>{title}</strong>
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

const DEFAULT_COUNTRY_CODE = 'JP';

// 編集画面からの遷移
export interface ClinicDetailLocationState {
  referrer?: string;
  success?: boolean;
}

export function ClinicDetail() {
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [currentClinic, setCurrentClinic] = useRecoilState(organizationAtom);

  const location = useLocation();

  // snackbar
  const setSnackbar = useSetSnackbar();

  useEffect(() => {
    const locationState = location.state as ClinicDetailLocationState;
    if (!locationState) {
      return;
    }
    // 編集画面から戻るとき
    if (locationState?.success) {
      setSnackbar(true, '店舗情報を保存しました', 'success');
    }
  }, [location, setSnackbar]);

  useEffect(() => {
    const fetchAttributeAndSetDescription = async () => {
      if (currentClinic && currentClinic.description) {
        try {
          const attributeResult = await getAttributeById(
            currentClinic.description
          );
          if (
            attributeResult.status === 200 &&
            attributeResult.data.value.length > 0
          ) {
            const updatedClinic = {
              ...currentClinic,
              description: attributeResult.data.value[0].name,
            };
            setCurrentClinic(updatedClinic);
          } else {
            return;
          }
        } catch (error) {
          console.error('attributeの取得中にエラーが発生しました:', error);
          setSnackbar(true, 'attributeの取得中にエラーが発生しました', 'error');
        }
      }
    };
    void fetchAttributeAndSetDescription();
  }, [currentClinic, setSnackbar, setCurrentClinic]);

  //
  // Render
  //
  if (!loggedInUser || !currentClinic) {
    return null;
  }

  const formattedPhoneNumber = // 電話番号format (とりあえず日本限定)
    (currentClinic?.phoneNumber &&
      new AsYouType(DEFAULT_COUNTRY_CODE).input(currentClinic.phoneNumber)) ||
    '';
  const joinedAddress = [
    currentClinic.customFields?.addressByCity,
    currentClinic.addressLine3,
    currentClinic.customFields?.addressLine4,
  ]
    .filter((item) => !!item)
    .join(' ');

  return (
    <>
      <Box display="flex" mb={2}>
        <Link to="/shop">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ height: '40px', mr: 2, width: '40px' }}
          >
            <ArrowBackIcon />
          </Button>
        </Link>
        <PageTitle title="店舗詳細" />
      </Box>
      <Card>
        <CardContent>
          {/* header*/}
          <Grid container>
            <Grid item xs>
              <Typography variant="h6" mt={2}>
                {unescapeHtml(currentClinic.name)}
              </Typography>
              {currentClinic.iconImageUri && (
                <div
                  style={{ height: '300px', padding: '10px', width: '100%' }}
                >
                  <img
                    alt="shop"
                    style={{
                      height: '100%',
                      objectFit: 'contain',
                      width: 'auto',
                    }}
                    src={currentClinic.iconImageUri}
                  />
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={4}
              p={1}
              sx={{ alignItems: 'end', display: 'flex', justifyContent: 'end' }}
            >
              <Link to={`/shop/${currentClinic.id}/edit`}>
                <Button size="small" color="secondary" variant="outlined">
                  編集
                </Button>
              </Link>
            </Grid>
          </Grid>

          {/* table view*/}
          <Divider />
          <RowItem title="店舗名">{unescapeHtml(currentClinic.name)}</RowItem>
          <RowItem title="法人名">{currentClinic.description}</RowItem>
          <RowItem title="連絡先電話番号">{formattedPhoneNumber}</RowItem>
          <RowItem title="住所">{unescapeHtml(joinedAddress)}</RowItem>
          <RowItem title="アクセス情報">
            {unescapeHtml(currentClinic.customFields?.access || '-')}
          </RowItem>
          <RowItem title="ジャンル">
            {currentClinic.customFields?.genre &&
              currentClinic.customFields?.genre.join(', ')}
            <Typography
              color={theme.customPalette.gray}
              variant="body2"
              component="span"
              sx={{ ml: 4 }}
            >
              ※インフルエンサー側に表示されるジャンルは最大2個までです
            </Typography>
          </RowItem>
          <RowItem title="店長名">
            {currentClinic.customFields?.ownerName?.trim() || loggedInUser.name}
          </RowItem>
          <RowItem title="担当者メールアドレス">{loggedInUser.email}</RowItem>
          <RowItem title="ジャンル（その他）">
            {unescapeHtml(currentClinic.customFields?.genreOther || '-')}
          </RowItem>
          <RowItem title="店舗の一言アピール">
            {unescapeAndConvertNewLines(
              currentClinic.customFields?.clinicAppealMessage || '-'
            )}
          </RowItem>
        </CardContent>
      </Card>
    </>
  );
}
