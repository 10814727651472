import { AxiosPromise } from 'axios';

import { Paginated } from '../types/common';
import { Message } from '../types/notifications';

import { axiosInstance, NOTIFICATION_SERVICE } from './axios';

export function getMessageListWithFilter(
  userId: string
): AxiosPromise<Paginated<Message>> {
  const urlParams = [['$orderBy', 'createdAt desc']];

  return axiosInstance.get(
    `${NOTIFICATION_SERVICE}/users/${userId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function getSingleMessage(messageId: string): AxiosPromise<Message> {
  return axiosInstance.get(`${NOTIFICATION_SERVICE}/messages/${messageId}`);
}
