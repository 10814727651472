import {
  format as dateFnsFormat,
  isToday,
  isYesterday,
  isThisYear,
  parseISO,
  addDays,
} from 'date-fns';
import dateFnsJa from 'date-fns/locale/ja';

const localeJP = 'ja-JP';
const currencyJP = 'JPY';
const defaultLocale = localeJP;
const defaultCurrency = currencyJP;

export function localizedPrice(price: number) {
  return new Intl.NumberFormat(defaultLocale, {
    currency: defaultCurrency,
    style: 'currency',
  }).format(price);
}

export function formatPrice(price?: number) {
  if (price === undefined) return 'お問い合わせ後の価格';
  return localizedPrice(price);
}

export function restoreSanitizedString(str: string) {
  let tempStr = str.replace(/\\0/g, '\0');
  tempStr = tempStr.replace(/\\n/g, '\n');
  tempStr = tempStr.replace(/\\r/g, '\r');
  return tempStr;
}

export function jsonParse(jsonStr: string) {
  return JSON.parse(jsonStr.replace(/\\+/g, ''));
}

/**
 * 日付を"YYYY年MM月DD日(曜)"形式にフォーマットする。
 * @param date "YYYY/MM/DD"形式など
 * @returns "YYYY年MM月DD日(曜)"形式 ※変換できないときは空白
 */
export function formatDate(date?: string | Date, isWeek = true) {
  if (!date) {
    return '';
  }
  const d = typeof date === 'string' ? new Date(date) : date;
  if (isNaN(d.getDate())) {
    return '';
  }
  return dateFnsFormat(d, isWeek ? 'yyyy年MM月dd日(eee)' : 'yyyy年MM月dd日', {
    locale: dateFnsJa,
  });
}

/**
 * 複数の日付を"YYYY年MM月DD日(曜)"形式にフォーマットする。
 * @param dates "YYYY/MM/DD"形式などの日付の配列
 * @returns "YYYY年MM月DD日(曜)"形式の文字列の配列 ※変換できないときは空白
 */
export function formatDates(dates?: (string | Date)[]) {
  if (!dates || dates.length === 0) {
    return [];
  }
  return dates.map((date) => {
    const d = typeof date === 'string' ? new Date(date) : date;
    if (isNaN(d.getDate())) {
      return '';
    }
    return dateFnsFormat(d, 'yyyy年MM月dd日(eee)', { locale: dateFnsJa });
  });
}

/**
 * 曜日を"月曜・火曜・水曜"形式にフォーマットする。
 * @param week 曜日の数値配列
 * @returns "月曜・火曜・水曜"形式
 */
export function formatWeek(week?: number[]) {
  if (!week?.length) {
    return '';
  }
  return week
    ?.map(
      (w) =>
        `${dateFnsJa.localize?.day(w, {
          width: 'short',
        })}曜`
    )
    .join('・');
}

/**
 * 時間を"hh"形式にフォーマットする。(日付が変わる場合は"翌hh"、"翌々hh"などとする)
 * @param time "hh"形式など ※日を跨ぐ時間（"25"等）でもOK
 * @returns "hh"形式 ※変換できないときは空白
 */
export function formatHour(hourTime?: string | number) {
  if (!hourTime && hourTime !== 0) {
    return '';
  }
  const h = Number(hourTime);
  if (isNaN(h)) {
    return '';
  }
  const dayCount = Math.floor(h / 24);
  const hour = h % 24;
  return `${dayCount > 0 ? '翌' : ''}${
    dayCount > 0 ? '々'.repeat(dayCount - 1) : ''
  }${hour.toString().padStart(2, '0')}`;
}

/**
 * 時間を"hh:mm"形式にフォーマットする。(日付が変わる場合は"翌hh:mm"、"翌々hh:mm"などとする)
 * @param time "hh:mm"形式など ※日を跨ぐ時間（"25:00"等）でもOK
 * @returns "hh:mm"形式 ※変換できないときは空白
 */
export function formatTime(time?: string) {
  if (!time) {
    return '';
  }
  const t = time.split(':');
  const h = Number(t[0]);
  const m = Number(t[1]);
  if (isNaN(h) || isNaN(m)) {
    return '';
  }
  return `${formatHour(h)}:${m.toString().padStart(2, '0')}`;
}

/**
 * 指定の日付を日本語フォーマットに変換して返す
 * @param date Date|string
 * @returns string
 */
export function formatRelativeDate(date: Date | string) {
  const dateObj = convertToDate(date);
  if (isToday(dateObj)) {
    return '今日';
  } else if (isYesterday(dateObj)) {
    return '昨日';
  } else if (isThisYear(dateObj)) {
    return dateFnsFormat(dateObj, 'M月d日(E)', { locale: dateFnsJa });
  }
  return dateFnsFormat(dateObj, 'yyyy年M月d日(E)', { locale: dateFnsJa });
}

function convertToDate(date: Date | string) {
  return typeof date === 'string' ? parseISO(date) : date;
}

/**
 * 指定された期間と曜日の配列に基づいて日付範囲を生成し、フォーマットする。
 * @param startPeriod 開始日付（Date型）
 * @param endPeriod 終了日付（文字列、"yyyy-MM-dd"形式）
 * @param repeatWeek 処理を適用する曜日の配列（0（日曜日）から6（土曜日）までの数値）
 * @returns フォーマットされた日付の配列（"yyyy-MM-dd"形式）。指定された曜日に該当する日付のみを含む。
 */
export const formatDatesForRepeatWeek = (
  startPeriod: Date,
  endPeriod: string,
  repeatWeek: number[]
) => {
  const dateRange = [];
  let currentDate = new Date(startPeriod);
  const endDate = new Date(endPeriod);
  while (currentDate <= endDate) {
    if (repeatWeek.includes(currentDate.getDay())) {
      dateRange.push(dateFnsFormat(currentDate, 'yyyy-MM-dd'));
    }
    currentDate = addDays(currentDate, 1);
  }
  return dateRange;
};

export const convertKana = (str: string) => {
  const katakanaToHiragana = (str: string) =>
    str.replace(/[\u30A1-\u30F6]/g, (match: string) =>
      String.fromCharCode(match.charCodeAt(0) - 0x60)
    );

  const hiraganaToKatakana = (str: string) =>
    str.replace(/[\u3041-\u3096]/g, (match: string) =>
      String.fromCharCode(match.charCodeAt(0) + 0x60)
    );

  return hiraganaToKatakana(katakanaToHiragana(str));
};
