import { ArrowBack as ArrowBackIcon } from '@mui/icons-material/';
import { Button, Paper, PaperProps } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

import { SubPageTitle } from '../Shared/SubPageTitle';

export type OrderLayoutProps = PaperProps;

export function OrderLayout({ children, ...paperProps }: OrderLayoutProps) {
  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" mb={3} mt={1}>
        <Link to="/orders">
          <Button
            color="secondary"
            variant="outlined"
            sx={{ height: '40px', mr: 2, width: '40px' }}
            data-e2e="product-detail-back-btn"
          >
            <ArrowBackIcon color="action" />
          </Button>
        </Link>
        <SubPageTitle title="応募" />
      </Box>
      <Paper
        {...paperProps}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minHeight: 660,
            p: 3,
          },
          // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
          ...(Array.isArray(paperProps.sx) ? paperProps.sx : [paperProps.sx]),
        ]}
      >
        {children}
      </Paper>
    </>
  );
}
