import { AxiosPromise, AxiosRequestConfig, CanceledError } from 'axios';
import { format as dateFnsFormat } from 'date-fns';

import { axiosInstance, CATALOG_SERVICE } from './axios';

import {
  Catalog,
  Product,
  ProductInput,
  Attribute,
  AttributeListResponse,
  CategoryResponse,
  LocationListResponse,
  ProductUpdate,
  ProductLocation,
  SupportedProductPublicationStatus,
  SupportedLocationType,
  ProductVariantUpdate,
  AttachmentCreation,
  Attachment,
} from '@app/types/catalog';
import { Paginated } from '@app/types/common';
import { filterSyntaxGen } from '@app/utils';
import { getError } from '@app/utils/error';
import { getUploadedFileUrl } from '@app/utils/file_upload';

/**
 * プロダクトのリストを取得する。
 * @param organizationId
 * @param options
 * @returns
 */
export async function getCatalog(
  /** 未指定時は公開されているもの(publication.status = 'ACTIVE')のみ取得可能 */
  organizationId?: string,
  options?: {
    attributesClinicalDepartment?: string[];
    attributesJobType?: string[];
    categoryIds?: string[];
    /** 来店日で範囲検索 */
    dateRange?: {
      end: string;
      /** 空データを抽出するかどうか */
      isEmpty?: boolean;
      start: string;
    };
    ids?: string[];
    /** trueの場合は管理者のみ使用可能 */
    isAdmin?: boolean;
    keyword?: string;
    nextToken?: string;
    order?: 'createdAt' | string;
    organizationExpand?: boolean;
    pageNumber?: number | 0;
    pageSize?: number | 10;
    previousToken?: string;
    /** 掲載日日で範囲検索 */
    publicationRange?: {
      end: string;
      start: string;
    };
    statuses?: SupportedProductPublicationStatus[];
  }
) {
  try {
    const urlParams = [];

    if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
      urlParams.push([`$top`, options.pageSize.toString()]);
      urlParams.push([
        `$skip`,
        (options.pageNumber * options.pageSize).toString(),
      ]);
    }

    if (options?.nextToken) {
      urlParams.push(['$nextToken', options.nextToken]);
    }
    if (options?.previousToken) {
      urlParams.push(['$previousToken', options.previousToken]);
    }

    const filterParam = [];
    if (options?.keyword) {
      const keyword = options.keyword;
      const keywordFilters = [
        `category.name co '${keyword}'`,
        `name co '${keyword}'`,
        `attributes.value co '${keyword}'`,
        `customFields.startTime co '${keyword}'`,
        `customFields.endTime co '${keyword}'`,
        `description co '${keyword}'`,
        `variants.description co '${keyword}'`,
        `customFields.orderConditions co '${keyword}'`,
        `customFields.selection co '${keyword}'`,
        `additionalInformation co '${keyword}'`,
        `customFields.workPostalCode co '${keyword}'`,
        `locations.name co '${keyword}'`,
        `customFields.workAddress1 co '${keyword}'`,
        `customFields.workAddress2 co '${keyword}'`,
        `customFields.access co '${keyword}'`,
      ];
      filterParam.push(`(${keywordFilters.join(' or ')})`);
    }

    if (options?.attributesClinicalDepartment?.length) {
      filterParam.push(
        `attributes.attributeId in ${filterSyntaxGen(
          options.attributesClinicalDepartment
        )}`
      );
    }

    if (options?.attributesJobType?.length) {
      filterParam.push(
        `attributes.attributeId in ${filterSyntaxGen(
          options.attributesJobType
        )}`
      );
    }

    if (options?.statuses?.length) {
      filterParam.push(
        `publication.status in ${filterSyntaxGen(options.statuses)}`
      );
    }

    if (options?.categoryIds?.length) {
      filterParam.push(`categoryId in ${filterSyntaxGen(options.categoryIds)}`);
    }

    if (options?.ids?.length) {
      filterParam.push(`id in ${filterSyntaxGen(options.ids)}`);
    }

    if (options?.dateRange) {
      const param = `(customFields.days ge '${dateFnsFormat(
        new Date(options.dateRange.start),
        'yyyy-MM-dd'
      )}' and customFields.days le '${dateFnsFormat(
        new Date(options.dateRange.end),
        'yyyy-MM-dd'
      )}')`;
      if (options?.dateRange.isEmpty) {
        filterParam.push(`(${param} or customFields.days eq '')`);
      } else {
        filterParam.push(param);
      }
    }

    if (options?.publicationRange) {
      filterParam.push(
        `((ymdhms(publication.until) ge '${new Date(
          options.publicationRange.start
        )
          .toISOString()
          .replace('T', ' ')
          .substring(0, 19)}' and ` +
          `ymdhms(publication.since) le '${new Date(
            options.publicationRange.end
          )
            .toISOString()
            .replace('T', ' ')
            .substring(0, 19)}')`
      );
    }

    if (filterParam.length > 0) {
      urlParams.push(['$filter', filterParam.join(' and ')]);
    }

    if (options?.order) {
      urlParams.push(['$orderBy', options?.order]);
    }

    urlParams.push([
      '$expand',
      ['variants,images,organization,category,locations'].join(' and '),
    ]);

    if (!organizationId && options?.isAdmin) {
      return await axiosInstance.get<Catalog>(
        `${CATALOG_SERVICE}/admin/products?${new URLSearchParams(
          urlParams
        ).toString()}`
      );
    }

    if (!organizationId) {
      return await axiosInstance.get<Catalog>(
        `${CATALOG_SERVICE}/products?${new URLSearchParams(
          urlParams
        ).toString()}`
      );
    }

    return await axiosInstance.get<Catalog>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products?${new URLSearchParams(
        urlParams
      ).toString()}`
    );
  } catch (error) {
    throw getError(error);
  }
}

export async function getSingleProduct(productId: string) {
  try {
    const expandParam = ['variants,images,organization,category,locations'];
    const urlParams = [];

    urlParams.push(['$expand', expandParam.join(' and ')]);

    return await axiosInstance.get<Product>(
      `${CATALOG_SERVICE}/products/${productId}?${new URLSearchParams(
        urlParams
      ).toString()}`
    );
  } catch (error) {
    throw getError(error);
  }
}

export async function createProduct(
  data: ProductInput,
  organizationId: string
) {
  try {
    return await axiosInstance.post<Product>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products`,
      data
    );
  } catch (error) {
    throw getError(error);
  }
}

export async function createProducts(
  data: ProductInput[],
  organizationId: string
) {
  try {
    return await axiosInstance.post<Product[]>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products:batchPost`,
      data
    );
  } catch (error) {
    throw getError(error);
  }
}

export async function deleteProduct(productId: string) {
  try {
    return await axiosInstance.delete<void>(
      `${CATALOG_SERVICE}/products/${productId}`
    );
  } catch (error) {
    throw getError(error);
  }
}

/**
 * Returns the promise of the batch delete request.
 *
 * @param organizationId - The id of the organization
 * @param productIds - The ids of the products
 * @returns The promise of the batch delete request
 */
export async function deleteProducts(
  organizationId: string,
  productIds: string[]
) {
  try {
    return await axiosInstance.post<void>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products:batchDelete`,
      {
        ids: productIds,
      }
    );
  } catch (error) {
    throw getError(error);
  }
}

/**
 * Returns the promise of the product copy request.
 *
 * @param productId - The id of the product
 * @returns The promise of the product copy request
 */
export async function copyProduct(productId: string) {
  try {
    return await axiosInstance.post<void>(
      `${CATALOG_SERVICE}/products/${productId}:copy`,
      null
    );
  } catch (error) {
    throw getError(error);
  }
}

/**
 * Sets the status for multiple products
 *
 * @param organizationId - The id of the organization
 * @param productIds - The ids of the products
 * @returns The promise of the set status for multiple products
 */
export async function setProductsStatus(
  organizationId: string,
  productIds: string[],
  status: SupportedProductPublicationStatus
) {
  try {
    return await axiosInstance.patch<void>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products:batchPatch`,
      {
        data: {
          publication: {
            status: status.toString(),
          },
        },
        ids: productIds,
      }
    );
  } catch (error) {
    throw getError(error);
  }
}

export async function getProductsStatus(
  organizationId: string,
  productIds: string[]
) {
  try {
    const response = await axiosInstance.get<Product[]>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products:batchGet`,
      {
        params: {
          ids: productIds.join(','),
        },
      }
    );
    return response.data.map((product) => product.publication.status);
  } catch (error) {
    throw getError(error);
  }
}

export async function updateProduct(data: ProductUpdate, productId: string) {
  try {
    return await axiosInstance.patch<Product>(
      `${CATALOG_SERVICE}/products/${productId}`,
      data
    );
  } catch (error) {
    throw getError(error);
  }
}

export async function updateProducts(
  ids: string[],
  data: ProductUpdate,
  organizationId: string
) {
  try {
    return await axiosInstance.patch<void>(
      `${CATALOG_SERVICE}/orgs/${organizationId}/products:batchPatch`,
      { data, ids }
    );
  } catch (error) {
    throw getError(error);
  }
}

export async function updateVariants(
  ids: string[],
  data: ProductVariantUpdate
) {
  try {
    return await axiosInstance.patch<void>(
      `${CATALOG_SERVICE}/variants:batchPatch`,
      { data, ids }
    );
  } catch (error) {
    throw getError(error);
  }
}

export function fetchCategories() {
  return axiosInstance.get<CategoryResponse>(
    `${CATALOG_SERVICE}/category-tree`
  );
}

export function getLocationById(id: string) {
  return axiosInstance.get<ProductLocation>(
    `${CATALOG_SERVICE}/locations/${id}`
  );
}

export function getLocationList(options?: {
  ids?: string[];
  level?: number;
  parentId?: string;
  type?: SupportedLocationType;
}) {
  const urlParams = [];
  const filterParam = [];

  if (options?.level !== undefined) {
    filterParam.push(`level in [${options.level}]`);
  }

  if (options?.ids?.length) {
    filterParam.push(`parentId in ${filterSyntaxGen(options.ids)}`);
  }

  if (options?.type) {
    filterParam.push(`type eq '${options?.type}'`);
  }

  if (options?.parentId) {
    filterParam.push(`parentId eq '${options?.parentId}'`);
  }

  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  return axiosInstance.get<LocationListResponse>(
    `${CATALOG_SERVICE}/locations?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * 属性のリストを取得する。
 * @param options オプション
 *  - pageNumber:0始まりのページ番号
 *  - pageSize:ページごとの取得件数（1～1000）
 *  - filter:フィルタリングする属性の各プロパティ（配列で複数指定可）
 *  - nextToken:次のデータのトークン
 *  - previousToken:前のデータのトークン
 *  - order:並べ替え条件（複数指定時はカンマ区切り、降順項目には"desc"を付ける）
 * @returns
 */
export async function getAttributes(options?: {
  filter?: {
    categoryIds?: string[];
    delFlg?: number | number[];
    groupName?: string | string[];
    id?: string | string[];
    items?: {
      groupName?: string | string[];
      key?: string | string[];
      value?: string | string[];
    };
    name?: string | string[];
    order?: number | number[];
    type?: Attribute['type'] | Attribute['type'][];
  };
  nextToken?: string;
  order?: 'createdAt' | 'order' | string;
  pageNumber?: number;
  pageSize?: number;
  previousToken?: string;
}) {
  try {
    const urlParams: string[][] = [];
    if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
      urlParams.push([`$top`, options.pageSize.toString()]);
      urlParams.push([
        `$skip`,
        (options.pageNumber * options.pageSize).toString(),
      ]);
    }
    if (options?.nextToken) {
      urlParams.push(['$nextToken', options.nextToken]);
    }
    if (options?.previousToken) {
      urlParams.push(['$previousToken', options.previousToken]);
    }
    if (options?.filter) {
      const param = getFilterParam({ delFlg: 0, ...options.filter }); // NOTE:デフォルトでは未削除のものだけ抽出する
      if (param) {
        urlParams.push(['$filter', param]);
      }
    }
    urlParams.push(['$orderBy', options?.order?.toString() ?? 'order']);
    urlParams.push(['$expand', 'category,organization']);

    return await axiosInstance.get<AttributeListResponse>(
      `${CATALOG_SERVICE}/attributes?${new URLSearchParams(
        urlParams
      ).toString()}`
    );
  } catch (error) {
    throw getError(error);
  }
}

/**
 * フィルターパラメータ（$filter）用の値を取得する。
 * 下記オブジェクトを渡すと
 * {param1:"a", param2:123, param3:["a","b","c"], param4:{param4_1:"a", param4_2:123}}
 * 下記文字列を返す。
 * "param1 eq 'a' and param2 eq 123 and param3 in ['a','b','c'] and param4.param4_1 eq 'a' and param4.param4_2 eq 123"
 * オブジェクトの配列には非対応。（それでフィルターすることはないと思われる）
 * @param filter フィルターに使うオブジェクト
 * @param paramName ※再起処理用なので使わない
 * @returns
 */
function getFilterParam<T extends object>(
  filter: T,
  paramName?: string
): string {
  const entries = Object.entries(filter);
  const filterParam = entries
    .map((item) => {
      const key = paramName ? `${paramName}.${item[0]}` : item[0];
      const value = item[1];
      if (!key || value === undefined) {
        return '';
      }
      if (Array.isArray(value)) {
        if (!value.length) {
          return '';
        }
        if (typeof value[0] === 'string') {
          // NOTE:シングルコーテーションのエスケープが必要かも
          return `${key} in [${value.map((v) => `'${v}'`).join(',')}]`;
        }
        return `${key} in [${value.join(',')}]`;
      }
      if (typeof value === 'object') {
        return getFilterParam(value, key);
      }
      if (typeof value === 'string') {
        return `${key} eq '${value.toString()}'`;
      }
      return `${key} eq ${value.toString()}`;
    })
    .filter((v) => v);
  if (!filterParam.length) {
    return '';
  }
  return filterParam.join(' and ');
}

export function getLocationByLevel() {
  const filterParam = `level eq 1`;
  const urlParams = new URLSearchParams();
  urlParams.append('$filter', filterParam);
  urlParams.append('$top', '50');

  return axiosInstance.get<LocationListResponse>(
    `${CATALOG_SERVICE}/locations?${urlParams}`
  );
}

export function getLocationByParentId(parentId: string) {
  const urlParams = new URLSearchParams();
  urlParams.append('$filter', `parentId eq '${parentId}' and level eq 2`);
  urlParams.append('$top', '100');

  return axiosInstance.get<LocationListResponse>(
    `${CATALOG_SERVICE}/locations?${urlParams}`
  );
}

/**
 * (orgによらず) product 取得
 *
 */
export function getProducts<T>(
  config?: AxiosRequestConfig
): AxiosPromise<Paginated<T>> {
  return axiosInstance
    .get(`${CATALOG_SERVICE}/products`, config)
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if (error.response && 'message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * attachment
 */
export function postAttachment(
  orgId: string,
  payload: AttachmentCreation
): AxiosPromise<{ id: string; url: string }> {
  return axiosInstance.post(
    `${CATALOG_SERVICE}/orgs/${orgId}/attachments`,
    payload
  );
}

export function deleteAttachment(
  orgId: string,
  id: string
): AxiosPromise<Paginated<Attachment[]>> {
  return axiosInstance
    .delete(`${CATALOG_SERVICE}/orgs/${orgId}/attachments/${id}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function uploadBlob(
  orgId: string,
  file: Blob
): Promise<Attachment> {
  if (!file) {
    throw new Error('blob is not defined');
  }
  const signedUrl = await getProductUploadSignedUrl(orgId, file);
  const uploadedUrl = await getUploadedFileUrl(file, signedUrl);

  const objectId = new URL(uploadedUrl).pathname
    .split('?')[0]
    .replace(/^\/[^/]+\//, '');
  if (!objectId) {
    throw new Error('objectId is not undefined, upload may got error');
  }

  const objectSplits = objectId?.split('.');
  let extension = 'jpg';
  if (objectSplits && objectSplits.length > 1) {
    extension = objectSplits[objectSplits.length - 1];
  }
  const response = await postAttachment(orgId, {
    objectId,
    type: extension,
  });
  return response.data;
}

export function getProductUploadSignedUrl(
  orgId: string,
  blob: Blob
): Promise<string> {
  return axiosInstance
    .get(
      `${CATALOG_SERVICE}/orgs/${orgId}/attachment-upload-url?contentLength=${blob.size}&contentType=${blob.type}`
    )
    .then((response) => response.data);
}

/**
 * Attributesのname検索
 */
export async function getAttributesByName(options?: {
  filter?: {
    groupName?: string;
    name: string | string[];
  };
}) {
  try {
    const urlParams = new URLSearchParams();

    const { name } = options?.filter || {};
    if (name) {
      const names = Array.isArray(name) ? name : [name];
      const nameConditions = names.map((n) => `name co '${n}'`).join(' or ');
      urlParams.set('$filter', `(${nameConditions})`);
    }

    const url = `${CATALOG_SERVICE}/attributes?${urlParams}`;
    return await axiosInstance.get<AttributeListResponse>(url);
  } catch (error) {
    throw getError(error);
  }
}

/**
 * Attributes作成
 */
export async function createAttribute(name: string) {
  try {
    const response = await axiosInstance.post(`${CATALOG_SERVICE}/attributes`, {
      groupName: 'corporation',
      name,
      type: 'Select',
    });
    return response.data;
  } catch (error) {
    throw getError(error);
  }
}

/**
 * AttributesをIDで取得
 */
export async function getAttributeById(id: string) {
  try {
    const urlParams = new URLSearchParams();

    urlParams.set('$filter', `(groupName eq 'corporation' and id eq '${id}')`);

    const url = `${CATALOG_SERVICE}/attributes?${urlParams}`;
    return await axiosInstance.get<AttributeListResponse>(url);
  } catch (error) {
    throw getError(error);
  }
}
