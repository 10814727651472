import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Grid,
  Typography,
  Box,
  Paper,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  TablePagination,
  TableFooter,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  DialogContentText,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  deleteMessageTemplate,
  getMessageTemplatesByOrgId,
} from '../../adapter/chat-service';
import { getUserInvolvedOrganizations } from '../../adapter/organization-service';
import { loggedInUserState } from '../../domain/app';
import { templatesState } from '../../domain/chat';
import { User } from '../../types/user';

import { truncateString } from '@app/utils/pattern';

export const TemplateList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState('');
  const templates = useRecoilValue(templatesState).value || [];
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const allTemplateIds = templates.map((t) => t.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const setTemplates = useSetRecoilState(templatesState);
  const loggedInUser = useRecoilValue(loggedInUserState) as User;
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);

  useEffect(() => {
    let firstOrgId: string | undefined;
    setIsLoading(true);

    getUserInvolvedOrganizations(loggedInUser.id)
      .then((response) => {
        const organizations = response.data.value;

        if (organizations.length > 0) {
          firstOrgId = organizations[0].id;
        }

        if (firstOrgId) {
          return getMessageTemplatesByOrgId(firstOrgId);
        }
      })
      .then((response) => {
        if (response) {
          setTemplates(response.data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [loggedInUser.id, setTemplates]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedTemplates(allTemplateIds);
    } else {
      setSelectedTemplates([]);
    }
  };

  const handleSelectOne = (id: string) => {
    if (selectedTemplates.includes(id)) {
      setSelectedTemplates((prev) => prev.filter((tid) => tid !== id));
    } else {
      setSelectedTemplates((prev) => [...prev, id]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredTemplates = templates.filter(
    (template) =>
      template.title.includes(searchKeyword) ||
      template.content.includes(searchKeyword)
  );

  // 単体削除
  const showDeleteModal = (templateId: string) => {
    setTemplateToDelete(templateId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setTemplateToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (templateToDelete) {
      await handleDeleteTemplate(templateToDelete);
      closeDeleteModal();
    }
  };

  const handleDeleteTemplate = async (messageTemplateId: string) => {
    try {
      await deleteMessageTemplate(messageTemplateId);
      const updatedTemplates = templates.filter(
        (t) => t.id !== messageTemplateId
      );
      setTemplates({ value: updatedTemplates });
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };

  // 一括削除
  const showBulkDeleteModal = () => {
    setIsBulkDeleteModalOpen(true);
  };

  const closeBulkDeleteModal = () => {
    setIsBulkDeleteModalOpen(false);
  };

  const handleBulkDelete = async () => {
    const remainingTemplates = [...templates];

    for (const id of selectedTemplates) {
      try {
        await handleDeleteTemplate(id);
        const index = remainingTemplates.findIndex((t) => t.id === id);
        if (index !== -1) {
          remainingTemplates.splice(index, 1);
        }
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    }

    setTemplates({ value: remainingTemplates });
    setSelectedTemplates([]);
    closeBulkDeleteModal();
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Typography
          variant="h5"
          component="div"
          sx={{ pb: 2, pt: 2, textAlign: 'left' }}
        >
          テンプレート
        </Typography>
        <Button
          color="primary"
          variant="contained"
          sx={{ marginLeft: '10px', p: '4px 30px', textAlign: 'left' }}
        >
          <Link
            to="/chat/templateList/register"
            style={{
              color: 'inherit',
              fontSize: '1rem',
              textDecoration: 'none',
            }}
          >
            新規登録
          </Link>
        </Button>
      </Grid>
      <Paper elevation={1}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
          }}
        >
          <TextField
            variant="outlined"
            placeholder="キーワードで検索"
            value={searchKeyword}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            color="secondary"
            variant="outlined"
            onClick={showBulkDeleteModal}
            disabled={selectedTemplates.length === 0}
          >
            <span
              style={{
                color:
                  selectedTemplates.length === 0
                    ? theme.customPalette.lightGray
                    : theme.customPalette.alert,
                fontWeight: selectedTemplates.length === 0 ? 300 : 500,
              }}
            >
              一括削除
            </span>
          </Button>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedTemplates.length === allTemplateIds.length}
                  onChange={(event) => {
                    event.stopPropagation();
                    handleSelectAll(event);
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'left' }}>
                テンプレートタイトル
              </TableCell>
              <TableCell sx={{ textAlign: 'left' }}>メッセージ</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{
                    height: '200px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                  }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              filteredTemplates.map((template) => (
                <TableRow
                  key={template.id}
                  onClick={(event) => {
                    const target = event.target as HTMLElement;
                    if (
                      target.closest('input[type="checkbox"]') ||
                      target.closest('button')
                    ) {
                      return;
                    }
                    navigate(`/chat/templateList/edit/${template.id}`);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedTemplates.includes(template.id)}
                      onChange={() => handleSelectOne(template.id)}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '70px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {template.title.replace(/\\n/g, '')}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '300px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                    }}
                  >
                    {truncateString(template.content.replace(/\\n/g, ' '))}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      sx={{
                        border: `1px solid ${theme.customPalette.lightGray}`,
                        borderRadius: '4px',
                        mr: 1,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate(`/chat/templateList/copyedit/${template.id}`);
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        border: `1px solid ${theme.customPalette.lightGray}`,
                        borderRadius: '4px',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        showDeleteModal(template.id);
                      }}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} style={{ textAlign: 'right' }}>
                <TablePagination
                  component="div"
                  sx={{ fontSize: '0.7rem' }}
                  labelRowsPerPage={
                    <label style={{ fontSize: '0.8rem' }}>
                      1ページの表示数
                    </label>
                  }
                  rowsPerPageOptions={[10, 20, 30]}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} of ${count}`
                  }
                  showFirstButton
                  showLastButton
                  count={templates.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        {isDeleteModalOpen && (
          <>
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
              <DialogTitle>{'テンプレートの削除'}</DialogTitle>
              <DialogContent style={{ padding: '24px 40px' }}>
                <DialogContentText>
                  本当にこのテンプレートを削除しますか？
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ pb: 2 }}>
                <Button
                  onClick={closeDeleteModal}
                  color="secondary"
                  variant="outlined"
                >
                  キャンセル
                </Button>
                <Button
                  onClick={handleConfirmDelete}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  削除する
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        {isBulkDeleteModalOpen && (
          <Dialog open={isBulkDeleteModalOpen} onClose={closeBulkDeleteModal}>
            <DialogTitle>{'テンプレートの一括削除'}</DialogTitle>
            <DialogContent style={{ padding: '24px 40px' }}>
              <DialogContentText>
                選択されたテンプレートを全て削除しますか？
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pb: 2 }}>
              <Button
                onClick={closeBulkDeleteModal}
                color="secondary"
                variant="outlined"
              >
                キャンセル
              </Button>
              <Button
                onClick={handleBulkDelete}
                color="primary"
                variant="contained"
                autoFocus
              >
                削除する
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    </>
  );
};
