import { MarkEmailUnread } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';

import { formatTime } from '../../utils/format';

export interface CCalendarItemOrder {
  familyName?: string;
  time?: string;
}

export interface CalendarItemProps {
  date?: string;
  disabled?: boolean;
  isAppointmentMatch?: boolean;
  isProduct?: boolean;
  isToday?: boolean;
  mailCount?: number;
  onClick?: () => void;
  orderList?: CCalendarItemOrder[];
  selected?: boolean;
}

export function CalendarItem({
  date,
  isToday,
  orderList,
  mailCount,
  disabled,
  selected,
  isProduct,
  onClick,
}: CalendarItemProps) {
  const theme = useTheme();

  if (!date) {
    return <Box />;
  }

  return (
    <Box
      sx={{
        ':hover': {
          backgroundColor: theme.palette.action.hover,
        },
        cursor: 'pointer',
        outline: selected
          ? `solid 2px ${theme.palette.primary.main}`
          : undefined,
      }}
      display="flex"
      flexDirection="column"
      rowGap={0.8}
      borderRadius="4px"
      border={
        disabled
          ? `solid 1px ${theme.palette.grey[50]}`
          : `solid 1px ${theme.palette.divider}`
      }
      height="100%"
      padding={0.8}
      width="100%"
      color={disabled ? theme.palette.grey[500] : undefined}
      bgcolor={
        disabled
          ? theme.palette.grey[50]
          : isToday
          ? theme.customPalette.calendarToday
          : undefined
      }
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick?.();
        }
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box fontSize="1.7rem" lineHeight="1.7rem" fontWeight="500">
          {new Date(date).getDate()}
        </Box>
        {mailCount && (
          <Box
            borderRadius={6}
            marginLeft="auto"
            bgcolor={
              disabled ? theme.palette.grey[500] : theme.customPalette.alert
            }
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={0.5}
            color={theme.palette.common.white}
            fontSize="0.8rem"
            lineHeight="0.8rem"
            paddingX={0.6}
            paddingY={0.3}
          >
            <MarkEmailUnread sx={{ fontSize: '0.8rem' }} />
            <Box>{mailCount}</Box>
          </Box>
        )}
      </Box>
      <Box>
        {isProduct && !orderList?.length && !mailCount && (
          <Box
            fontSize="0.8rem"
            marginLeft={0.5}
            color={
              disabled ? theme.palette.grey[500] : theme.customPalette.alert
            }
          >
            応募なし
          </Box>
        )}
        {orderList?.slice(0, 2).map((item, idx) => (
          <Box
            display="flex"
            flexDirection="row"
            fontSize="0.8rem"
            alignItems="center"
            lineHeight="1.1rem"
            key={idx}
          >
            {item.familyName && (
              <Box
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width="2.7rem"
              >
                {item.familyName}
              </Box>
            )}
            {item.time && <Box marginLeft="auto">{formatTime(item.time)}</Box>}
          </Box>
        ))}
        {(orderList?.length ?? 0) > 2 ? (
          <Box fontSize="0.5rem" lineHeight="0.5rem" textAlign="center">
            &#8942;
          </Box>
        ) : undefined}
      </Box>
    </Box>
  );
}
