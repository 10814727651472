import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  Toolbar,
  Snackbar,
  Alert,
} from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import logo from '../../assets/logo.svg';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
  isLoading,
} from '../../domain/app';
import { CopyrightBox } from '../Shared/CopyrightBox';

/**
 * blocks-5f90
 *
 * Default layout for unsigned-in users.
 */
export function DefaultLayout(): ReactElement {
  const isLoadingState = useRecoilValue(isLoading);
  const [shouldOpenSnackbar, setShouldSnackbarOpen] =
    useRecoilState(snackbarOpenState);
  const [snackbarText, setSnackbarText] = useRecoilState(snackbarTextState);
  const [snackbarSeverity, setSnackbarSeverity] = useRecoilState(
    snackbarSeverityState
  );
  useEffect(() => {
    if (shouldOpenSnackbar === false) {
      setSnackbarText('');
      setSnackbarSeverity('error');
    }
  }, [setSnackbarSeverity, setSnackbarText, shouldOpenSnackbar]);

  return (
    <>
      <Snackbar
        open={shouldOpenSnackbar}
        autoHideDuration={4000}
        onClose={() => {
          setShouldSnackbarOpen(false);
        }}
        data-e2e="snackbar"
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        sx={{ mt: 8 }}
      >
        <Alert
          onClose={() => {
            setShouldSnackbarOpen(false);
          }}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarText}
        </Alert>
      </Snackbar>
      <Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
          open={isLoadingState}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <AppBar
          position="fixed"
          className="app-bar"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar sx={{ height: [48, 64], pl: '0 !important' }}>
            <img
              src={logo}
              className="logo"
              alt="TieupLogo"
              style={{ height: 'auto', width: '100px' }}
            />
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Box sx={{ mb: 5, mt: 5 }}>
            <Outlet />
          </Box>
          <CopyrightBox />
        </Box>
      </Box>
    </>
  );
}
