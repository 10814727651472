import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  CardMedia,
  Box,
  SxProps,
  Theme,
  Typography,
  Link,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useCallback, useEffect, useState } from 'react';

import { isPdf, getFileInfo } from '../../../utils/file_info';

import { ExtraExtension, InputFile } from './Input';
import { handleFileFactory } from './Util';

type Props = {
  addImageButtonText?: string;
  deleteImageButtonText?: string;
  dropBoxHeight?: number;
  extraExtensions?: ExtraExtension[];
  fileUrl: string;
  helperText?: string;
  isDragActive?: boolean;
  isError?: boolean;
  isUseIconIfPdf?: boolean;
  setImageUrl: (url: string) => void;
  shouldShowDeleteButton?: boolean;
  sx?: SxProps<Theme>;
};

export function UploadFile(
  props: Props & { setImageUris: (uris: string[]) => void }
) {
  const {
    isDragActive,
    setImageUrl,
    fileUrl,
    dropBoxHeight,
    sx,
    isError,
    helperText,
    extraExtensions,
    addImageButtonText,
    deleteImageButtonText,
    isUseIconIfPdf,
    setImageUris,
  } = props;

  const handleDrag = function (
    e: React.DragEvent<HTMLFormElement | HTMLDivElement>
  ) {
    e.preventDefault();
    e.stopPropagation();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFile = useCallback(
    (function () {
      return handleFileFactory(setImageUrl);
    })(),
    [setImageUrl]
  );

  const handleDrop = async function (e: React.DragEvent<HTMLDivElement>) {
    if (isDragActive) {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        await handleFile(e.dataTransfer.files[0]);
      }
    }
  };
  const [fileType, setFileType] = useState('');
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getFileInfo(fileUrl).then((info) => setFileType(info.extension));
  }, [setFileType, fileUrl]);

  return (
    <Box sx={sx}>
      {fileUrl ? (
        <Box
          padding={3}
          sx={{
            backgroundColor: '#eeeeee',
          }}
        >
          {isPdf(fileType) ? (
            isUseIconIfPdf === true ? (
              <Link
                href={fileUrl ? fileUrl : undefined}
                download={fileUrl ? 'certificate_image.pdf' : undefined}
                target={fileUrl ? '_blank' : undefined}
                variant="body2"
              >
                <PictureAsPdfIcon />
              </Link>
            ) : (
              <object
                data={fileUrl || undefined}
                aria-label="PDF content"
                type="application/pdf"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              />
            )
          ) : (
            <CardMedia
              component="img"
              src={fileUrl || undefined}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </Box>
      ) : isDragActive ? (
        <Box
          sx={{
            backgroundColor: '#eeeeee',
            height: dropBoxHeight || 100,
          }}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <Typography variant="caption" style={{ textAlign: 'center' }}>
            drag and drop file here
          </Typography>
        </Box>
      ) : (
        <div />
      )}

      <Box sx={{ mt: fileUrl || isDragActive ? 2 : 0 }}>
        <InputFile
          fileData={fileUrl}
          setImageUrl={setImageUrl}
          shouldShowDeleteButton={true}
          extraExtensions={extraExtensions}
          setImageUris={setImageUris}
          addImageButtonText={
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <UploadFileIcon sx={{ color: '#828A9B', mr: 5 }} />
              <Box ml={1}>{addImageButtonText}</Box>
            </div>
          }
          deleteImageButtonText={deleteImageButtonText}
        />
        {isError && helperText ? (
          <Typography variant="caption" sx={{ color: red[700] }}>
            {helperText}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
