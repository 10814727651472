import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

export interface OrganizationFormData {
  access: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  assigneeEmail: string;
  assigneeName: string;
  certificateImageUri: string;
  customFields: {
    access: string;
    addressByCity: string;
    addressLine4: string;
    clinicAppealMessage: string;
    clinicUrl: string;
    genre: string[];
    genreOther: string;
    mainImages: string;
    ownerName: string;
    ownerUserId: string;
  };
  description: string;
  descriptionName?: string;
  iconImageUri: string;
  name: string;
  ownerId: string;
  phoneNumber: string;
  postalCode: string;
  privacyPolicy: boolean;
  shouldSkipValidation: boolean;
  userAgreement: boolean;
}
export const ORGANIZATION_FORM_DEFAULT_VALUES: OrganizationFormData = {
  access: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  assigneeEmail: '',
  assigneeName: '',
  certificateImageUri: '',
  customFields: {
    access: '',
    addressByCity: '',
    addressLine4: '',
    clinicAppealMessage: '',
    clinicUrl: '',
    genre: [],
    genreOther: '',
    mainImages: '',
    ownerName: '',
    ownerUserId: '',
  },
  description: '',
  iconImageUri: '',
  name: '',
  ownerId: '0000',
  phoneNumber: '',
  postalCode: '',
  privacyPolicy: false,
  shouldSkipValidation: false,
  userAgreement: false,
};

export enum Gender {
  Female = 'female',
  Male = 'male',
  None = '',
}

export interface UserInfoFormData {
  customFields: {
    familyName: string;
    familyNameKana: string;
    firstName: string;
    firstNameKana: string;
  };
}

// const RegistrationFormDefaultValues = {
//   access: '',
//   addressLine1: '',
//   addressLine2: '',
//   addressLine3: '',
//   assigneeEmail: '',
//   assigneeName: '',
//   certificateImageUri: '',
//   customFields: {
//     access: '',
//     addressLine4: '',
//     clinicAppealMessage: '',
//     clinicUrl: '',
//     genre: [],
//     genreOther: '',
//     mainImages: '',
//     managerEmail: '',
//     managerName: '',
//     ownerName: '',
//     ownerUserId: '',
//   },
//   description: '',
//   genreOther: '',
//   medicalName: '',
//   name: '',
//   ownerId: '0000',
//   ownerName: '',
//   phoneNumber: '',
//   postalCode: '',
//   privacyPolicy: false,
//   userAgreement: false,
// };

const UserInfoDefaultValues: UserInfoFormData = {
  customFields: {
    familyName: '',
    familyNameKana: '',
    firstName: '',
    firstNameKana: '',
  },
};

export const OrganizationFormValidator = z.object({
  addressLine1: z.string().min(1, { message: '都道府県を入力してください' }),
  addressLine2: z.string().min(1, { message: '市町村区を入力してください' }),
  addressLine3: z.string().min(1, { message: '町名・番地を入力してください' }),
  customFields: z.object({
    access: z.string().min(1, { message: 'アクセス情報を入力してください' }),
    addressByCity: z.string(),
    addressLine4: z.string(),
    clinicAppealMessage: z
      .string()
      .min(1, { message: '店舗の一言アピールは必須です' })
      .max(200, {
        message: '店舗の一言アピールは200文字以内で入力してください',
      }),
    clinicUrl: z.string(),
    genre: z.array(z.string()),
    genreOther: z.string(),
    ownerName: z.string().min(1, { message: 'オーナー名を入力してください' }),
  }),
  iconImageUri: z.string().nullable(),
  name: z.string().min(1, { message: '店舗名を入力してください' }),
  ownerId: z.string().min(1, { message: 'ownerIdを入力してください' }),
  phoneNumber: z
    .string()
    .min(1, { message: '連絡先電話番号を入力してください' })
    .regex(/^0[0-9]{9,12}$/, {
      message: '連絡先電話番号はハイフンなしの数字を入力してください',
    }),
  postalCode: z
    .string()
    .regex(/^\d{7}$/, { message: '郵便番号を入力してください' }),
  privacyPolicy: z.boolean().refine((value) => value, {
    message: 'プライバシーポリシーに同意する必要があります',
  }),
  userAgreement: z
    .boolean()
    .refine((value) => value, { message: '利用規約に同意する必要があります' }),
});

const UserInfoFormValidator = z.object({
  customFields: z.object({
    familyName: z.string().min(1, { message: '姓を入力してください' }),
    familyNameKana: z
      .string()
      .min(1, { message: '姓(かな)を入力してください' })
      .refine((value) => /^[ぁ-んー\s]+$/u.test(value), {
        message: '姓(かな)はひらがなのみで入力してください',
      }),
    firstName: z.string().min(1, { message: '名を入力してください' }),
    firstNameKana: z
      .string()
      .min(1, { message: '名(かな)を入力してください' })
      .refine((value) => /^[ぁ-んー\s]+$/u.test(value), {
        message: '名(かな)はひらがなのみで入力してください',
      }),
  }),
});

export const RegistrationForm = {
  defaultValues: ORGANIZATION_FORM_DEFAULT_VALUES,
  resolver: zodResolver(OrganizationFormValidator),
  schema: OrganizationFormValidator,
} as const;

export const UseInfoForm = {
  defaultValues: UserInfoDefaultValues,
  resolver: zodResolver(UserInfoFormValidator),
  schema: UserInfoFormValidator,
} as const;
