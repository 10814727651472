import { ArrowBack as ArrowBackIcon } from '@mui/icons-material/';
import { Button, Paper, PaperProps } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, useCallback, ComponentProps } from 'react';

import { SubPageTitle } from '../Shared/SubPageTitle';

// useNavigate を置き換え
import { useNavigateAdminDelegatable as useNavigate } from '@app/administrator/utils/useNavigateAdminDelegatable';

export interface ProductLayoutProps extends PaperProps {
  children?: ReactNode;
}

export function ProductLayout({ children, ...paperProps }: ProductLayoutProps) {
  const navigate = useNavigate();

  const handleClickToProduct: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    navigate('/product');
  }, [navigate]);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" mb={3} mt={1}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{ height: '40px', mr: 2, width: '40px' }}
          onClick={handleClickToProduct}
          data-e2e="product-detail-back-btn"
        >
          <ArrowBackIcon color="action" />
        </Button>
        <SubPageTitle title="掲載管理" />
      </Box>
      <Paper
        {...paperProps}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minHeight: 660,
            p: 2,
          },
          // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
          ...(Array.isArray(paperProps.sx) ? paperProps.sx : [paperProps.sx]),
        ]}
      >
        {children}
      </Paper>
    </>
  );
}
