import { Box, Grid, Typography, useTheme } from '@mui/material';
import { isSameDay, format, isBefore } from 'date-fns';
import { forwardRef } from 'react';

export interface ProductCalendarProps {
  endDate?: string;
  month: string;
  onChange?: (value: string[]) => void | Promise<void>;
  startDate?: string;
  value?: string[];
}

// MEMO:ReactHookFormのControllerで使用する場合forwardRefでrefを渡せるようにしないとWarningとなる。
export const ProductCalendar = forwardRef(function (
  { month, value, onChange, startDate, endDate }: ProductCalendarProps,
  ref
) {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const monthDate = new Date(month + '/1');
  const monthLastDate = new Date(
    monthDate.getFullYear(),
    monthDate.getMonth() + 1,
    0
  );

  const theme = useTheme();

  /** 日付のリスト */
  const dayList: number[] = [
    ...Array.from(Array(monthDate.getDay()).keys()).map(() => 0), //開始日の曜日まで空日付で埋める
    ...Array.from(Array(monthLastDate.getDate()).keys(), (x) => x + 1), //末日までの日付配列
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="25rem"
      width="25rem"
      bgcolor="white"
      ref={ref}
    >
      <Grid container alignItems="center" columns={7} spacing={0.5}>
        {['日', '月', '火', '水', '木', '金', '土'].map((item) => (
          <Grid
            item
            xs={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="3.5rem"
            key={item}
          >
            <Typography fontSize="1.2rem" color={theme.palette.grey[500]}>
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container alignItems="center" columns={7} spacing={0.5}>
        {dayList.map((day, idx) => {
          const currentDate = new Date(
            monthDate.getFullYear(),
            monthDate.getMonth(),
            day
          );
          const isSelect = !!(
            day && value?.some((d) => isSameDay(currentDate, new Date(d)))
          );
          const isDisabled = !!(
            !isSelect &&
            (!day ||
              (startDate && isBefore(currentDate, new Date(startDate))) ||
              (endDate && isBefore(new Date(endDate), currentDate)))
          );
          return (
            <Grid
              item
              xs={1}
              height="3.5rem"
              key={idx}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: isDisabled ? undefined : 'pointer' }}
              tabIndex={isDisabled ? undefined : 0}
              onClick={
                !isDisabled
                  ? async (e) => {
                      const newValue = isSelect
                        ? value?.filter(
                            (d) => !isSameDay(currentDate, new Date(d))
                          ) ?? []
                        : [...(value ?? []), format(currentDate, 'yyyy/MM/dd')];
                      await onChange?.(
                        newValue.sort((a, b) => {
                          const da = new Date(a);
                          const db = new Date(b);
                          if (isBefore(da, db)) {
                            return -1;
                          }
                          if (isBefore(db, da)) {
                            return 1;
                          }
                          return 0;
                        })
                      );
                    }
                  : undefined
              }
            >
              <Box
                display="flex"
                height="80%"
                width="80%"
                alignItems="center"
                justifyContent="center"
                bgcolor={isSelect ? theme.palette.primary.main : undefined}
                borderRadius="50%"
              >
                <Typography
                  fontSize="1.2rem"
                  color={
                    isDisabled
                      ? theme.palette.grey[500]
                      : isSelect
                      ? theme.palette.common.white
                      : undefined
                  }
                >
                  {day ? day : ''}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
});
