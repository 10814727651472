import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback } from 'react';

interface ConfirmDialogProps {
  okButtonLoading: boolean;
  okButtonText: string;
  onClose: (confirm?: boolean) => void;
  open: boolean;
  title: string;
}

export function ConfirmDialog({
  okButtonLoading,
  okButtonText,
  open,
  onClose,
  title,
}: ConfirmDialogProps): ReactElement {
  const handleClose = useCallback((): void => {
    onClose(false);
  }, [onClose]);

  const handleConfirm = useCallback(async () => {
    onClose(true);
  }, [onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        id: 'e2e-test-confirm-dialog',
      }}
    >
      <DialogContent sx={{ minWidth: 450 }}>
        <Typography
          color="textPrimary"
          fontSize={'1.2rem'}
          sx={{ textAlign: 'left' }}
        >
          {title}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ width: '10rem' }}
          onClick={handleClose}
        >
          キャンセル
        </Button>
        <LoadingButton
          loading={okButtonLoading}
          variant="contained"
          sx={{ width: '10rem' }}
          onClick={handleConfirm}
        >
          {okButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
