/**
 * message
 */
export const MessageCategory = {
  DEMAND: 'demand',
  SUPPLY: 'supply',
} as const;
export type MessageCategoryKey =
  (typeof MessageCategory)[keyof typeof MessageCategory];

export const MessageReadStatus = {
  READ: 'read',
  UNREAD: 'unread',
} as const;
export type MessageReadStatusKey =
  (typeof MessageReadStatus)[keyof typeof MessageReadStatus];

export const MessageType = {
  CHANNEL: 'channel',
} as const;
export type MessageTypeKey = (typeof MessageType)[keyof typeof MessageType];

export const MessageTypeId = {
  CHANNEL: 'channel', //未使用。バグでこのデータが生成されてしまっているため定義しておく
  FILE: 'file',
  IMAGE: 'image',
  MESSAGE: 'message',
} as const;
export type MessageTypeIdKey =
  (typeof MessageTypeId)[keyof typeof MessageTypeId];

export const MessageSenderType = {
  NORMAL_USER: 'normal-user',
} as const;
export type MessageSenderTypeKey =
  (typeof MessageSenderType)[keyof typeof MessageSenderType];

export interface Message {
  category: MessageCategoryKey;
  content: string;
  createdAt: string;
  id: string;
  messageType: MessageTypeKey;
  priority: string;
  readStatus?: MessageReadStatusKey;
  receiptId: string;
  sender: {
    customFields: {
      familyName: string;
      firstName: string;
    };
    id: string;
  };
  senderId: string;
  senderType: MessageSenderTypeKey;
  title: string;
  topicId: string;
  typeId: MessageTypeIdKey;
  updatedAt?: string;
}

export interface MessageCreation {
  category?: string;
  content: string;
  priority?: string;
  senderId?: string;
  senderType?: string;
  title: string;
  topicId: string;
  typeId: string;
}

/**
 * topic
 */
export const TopicApp = {
  USER: 'user',
} as const;
export type TopicAppKey = (typeof TopicApp)[keyof typeof TopicApp];

export const TopicOwnerType = {
  USER: 'user',
} as const;
export type TopicOwnerTypeKey =
  (typeof TopicOwnerType)[keyof typeof TopicOwnerType];

export const TopicTypeId = {
  CHANNEL: 'channel',
} as const;
export type TopicTypeIdKey = (typeof TopicTypeId)[keyof typeof TopicTypeId];

export type TopicList = {
  '@nextLink': string;
  '@previousLink': string;
  count: number;
  total: number;
  value: Message[];
};

export interface Owner {
  avatar: string;
  customFields: CustomFields;
  email: string;
  emailVerified: boolean;
  id: string;
  name: string;
  typeId: string;
}

export interface Topic {
  app: TopicAppKey;
  content: string;
  createdAt: string;
  id: string;
  isPublic: boolean;
  lastMessage?: Message;
  lastMessageAt?: Date;
  lastMessageId?: string;
  messageTypeId?: MessageTypeIdKey;
  name: string;
  ownerId: string;
  ownerType: TopicOwnerTypeKey;
  owners?: Owner[];
  typeId: TopicTypeIdKey;
  unreadCount: number;
  updatedAt: string;
}

/**
 * template
 */
export const TemplatePermission = {
  READ: 'read',
  WRITE: 'write',
} as const;
export type TemplatePermissionKey =
  (typeof TemplatePermission)[keyof typeof TemplatePermission];

export type Template = {
  content: string;
  id: string;
  orgId: string;
  permissions: (TemplatePermissionKey | null)[];
  title: string;
};

export type PaginatedTemplates = {
  '@nextLink': string;
  '@previousLink': string;
  count: number;
  total: number;
  value: Template[];
};

export type CustomFields = {
  birthday: string;
  familyName: string;
  familyNameKana: string;
  firstName: string;
  firstNameKana: string;
  gender: string;
};

export type User = {
  avatar: string;
  customFields: CustomFields;
  email: string;
  id: string;
  name: string;
  typeId: string;
};

/**
 * subscription
 */
export type Subscription = {
  approved: boolean;
  id: string;
  permissions: string[];
  subscriptedAt: string;
  topicId: string;
  user: User;
  userId: string;
};

export type SubscriptionResponse = {
  '@nextLink': string;
  '@previousLink': string;
  count: number;
  total: number;
  value: Subscription[];
};

export type SubscriptionCreationRequest = {
  approved: boolean;
  permissions: string[];
  topicId: string;
  userId: string;
};

export type SubscriptionCreationResponse = {
  approved: boolean;
  id: string;
  permissions: string[];
  subscriptedAt: string;
  topicId: string;
  userId: string;
};

/**
 * attachment
 */
export interface Attachment {
  id?: string;
  ownerId?: string;
  ownerType?: string;
  type?: string;
  url?: string;
}

export interface AttachmentCreation {
  objectId?: string;
  ownerId?: string;
  type?: string;
}
