import { Box, Button } from '@mui/material';
import { isEmpty, uniq } from 'lodash';
import { FormEvent, useCallback } from 'react';

import { handleFileFactory } from './Util';

const acceptTypePdf = 'pdf';
export type ExtraExtension = typeof acceptTypePdf;

function getExtraAccept(extraExtension: ExtraExtension): string {
  switch (extraExtension) {
    case acceptTypePdf:
      return '.pdf';
    default:
      return '';
  }
}

function getExtraAcceptList(extraExtensions: ExtraExtension[]): string[] {
  return uniq(extraExtensions.map(getExtraAccept).filter((v) => !isEmpty(v)));
}

function getAccept(extraAcceptList: string[]) {
  const extraAccepts = extraAcceptList.join(',');
  return extraAccepts && extraAccepts.length > 0
    ? 'image/*,' + extraAccepts
    : 'image/*';
}

type Props = {
  addImageButtonText?: string | React.ReactNode;
  buttonStyle?: React.CSSProperties;
  deleteImageButtonText?: string;
  extraExtensions?: ExtraExtension[];
  fileData: string;
  setImageUrl: (data: string) => void;
  shouldShowDeleteButton?: boolean;
};

export function InputFile(
  props: Props & { setImageUris: (uris: string[]) => void }
) {
  const {
    fileData,
    setImageUrl,
    setImageUris,
    addImageButtonText,
    deleteImageButtonText,
    shouldShowDeleteButton,
    extraExtensions,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFile = useCallback(
    (function () {
      return handleFileFactory(setImageUrl);
    })(),
    [setImageUrl]
  );

  const handleChange = async function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      await handleFile(e.target.files[0]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDelete = useCallback(
    (e: FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setImageUrl('');
      setImageUris([]);
    },
    [setImageUrl, setImageUris]
  );

  const extraAcceptList = extraExtensions
    ? getExtraAcceptList(extraExtensions)
    : [];
  const accept = getAccept(extraAcceptList);
  return (
    <Box>
      {fileData && shouldShowDeleteButton ? (
        <Button
          fullWidth
          variant="outlined"
          onClick={handleDelete}
          type="button"
          style={{
            borderColor: '#B0B7C3',
            color: '#2F2F2F',
            fontWeight: 400,
          }}
        >
          {deleteImageButtonText || '画像を削除'}
        </Button>
      ) : (
        <Button
          fullWidth
          variant="outlined"
          component="label"
          sx={{
            borderColor: '#B0B7C3',
            color: '#2F2F2F',
            fontWeight: 400,
            justifyContent: 'flex-start',
          }}
        >
          {addImageButtonText || '画像を追加'}
          <input
            hidden
            accept={accept}
            multiple
            type="file"
            onChange={handleChange}
          />
        </Button>
      )}
    </Box>
  );
}
