import { formatDate, formatTime, formatWeek } from './format';

import { Product, ScheduleType } from '@app/types/catalog';

/**
 * "YYYY年MM月DD日(曜)"形式または"月曜・火曜・水曜"形式のプランタイトルを取得する。
 * @param product プラン
 * @returns タイトル
 */
export function getProductDayWeekTitle(product: Product | undefined) {
  const days = product?.customFields?.days;
  if (Array.isArray(days) && days.length > 0) {
    return product?.customFields?.scheduleType === ScheduleType.DAY
      ? days.map((day) => formatDate(day)).join(', ')
      : product?.customFields?.scheduleType === ScheduleType.WEEK
      ? formatWeek(product.customFields?.repeatWeek)
      : '';
  }
  return '';
}

export function getProductWeekTitle(product: Product | undefined) {
  const formatRepeatWeek = (repeatWeek: number[] | undefined) => {
    const weekdays = [
      '日曜日',
      '月曜日',
      '火曜日',
      '水曜日',
      '木曜日',
      '金曜日',
      '土曜日',
    ];
    if (Array.isArray(repeatWeek)) {
      // 重複を取り除く
      const uniqueDays = Array.from(new Set(repeatWeek));
      return uniqueDays.map((dayIndex) => weekdays[dayIndex]).join(', ');
    }
    return '';
  };

  const repeatWeek = product?.customFields?.repeatWeek;
  return formatRepeatWeek(repeatWeek);
}

/**
 * "YYYY年MM月DD日(曜) hh:mm~hh:mm"形式または"月曜・火曜・水曜 hh:mm~hh:mm"形式のプランタイトルを取得する。
 * @param product プラン
 * @returns タイトル
 */
export function getProductDayWeekTimeTitle(product: Product | undefined) {
  return `${getProductDayWeekTitle(product)} ${formatTime(
    product?.customFields?.startTime
  )}~${formatTime(product?.customFields?.endTime)}`;
}

export function getCalculatedHourlyPay(
  startTime: string,
  endTime: string,
  breakTime: number | null,
  variantsPriceAmount: number | null
) {
  /** 来店開始時間 */
  const startMinutes = startTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 来店終了時間 */
  const endMinutes = endTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 来店時間（終了時間-開始時間-休憩時間） */
  const workHour = Math.round(
    (endMinutes - startMinutes - (breakTime ?? 0)) / 60
  );
  /** 想定時給 */
  const calculatedHourlyPay = variantsPriceAmount
    ? Math.round(variantsPriceAmount / workHour)
    : 0; // 日給の場合は報酬金額÷来店時間
  return calculatedHourlyPay;
}

export function getCalculatedDairyPay(
  startTime: string,
  endTime: string,
  breakTime: number | null,
  variantsPriceAmount: number | null
) {
  /** 来店開始時間 */
  const startMinutes = startTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 来店終了時間 */
  const endMinutes = endTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 来店時間（終了時間-開始時間-休憩時間） */
  const workHour = Math.round(
    (endMinutes - startMinutes - (breakTime ?? 0)) / 60
  );
  /** 想定日給 */
  const calculatedDairyPay = variantsPriceAmount
    ? variantsPriceAmount * workHour
    : 0; // 時給の場合は報酬金額×来店時間
  return calculatedDairyPay;
}

// 指定された日付の配列から、現在から2ヶ月以上前の日付を除外する
export const filterRecentDates = (dates: string[]): string[] => {
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

  return dates.filter((date) => {
    const dateObj = new Date(date);
    return dateObj >= twoMonthsAgo;
  });
};

/**
 * 重複した日付を削除する関数
 */
export const removeDuplicates = (dates?: string[]): string[] => {
  return dates ? [...new Set(dates)] : [];
};
