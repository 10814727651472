import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import {
  snackbarOpenState,
  snackbarTextState,
  snackbarSeverityState,
} from '../../domain/app';
import { generatePasswordPattern } from '../../utils/pattern';

type ResetPasswordProps = {
  onSubmitCallBack: (newPassword: string) => Promise<void>;
  token?: string;
};

interface ResetPasswordSchema {
  passwords: {
    confirmPassword: string;
    password: string;
  };
}

export function ResetPassword({ token, onSubmitCallBack }: ResetPasswordProps) {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ResetPasswordSchema>({
    defaultValues: { passwords: { confirmPassword: '', password: '' } },
    mode: 'onChange',
    resolver: zodResolver(
      z.object({
        passwords: z
          .object({
            confirmPassword: z
              .string()
              .min(
                8,
                'パスワードは記号を除く、英数字含む8文字以上を入力してください'
              )
              .max(24, '24文字以下のパスワードを入力してください')
              .regex(generatePasswordPattern(), {
                message: '小文字英字と数字を組み合わせてください',
              }),
            password: z
              .string()
              .min(
                8,
                'パスワードは記号を除く、英数字含む8文字以上を入力してください'
              )
              .max(24, '24文字以下のパスワードを入力してください')
              .regex(generatePasswordPattern(), {
                message: '小文字英字と数字を組み合わせてください',
              }),
          })
          .refine(
            ({ password, confirmPassword }) => password === confirmPassword,
            {
              message: 'パスワードが一致しません',
              path: ['confirmPassword'],
            }
          ),
      })
    ),
  });
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarSeverityState = useSetRecoilState(snackbarSeverityState);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const theme = useTheme();

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmit = useCallback(
    async ({ passwords }: ResetPasswordSchema) => {
      try {
        await onSubmitCallBack(passwords.password);
        setSnackbarText('パスワードを再設定しました');
        setSnackbarSeverityState('success');
        setSnackbarOpen(true);
        navigate('/login');
      } catch (error) {
        console.log(error);
      }
    },
    [
      onSubmitCallBack,
      navigate,
      setSnackbarOpen,
      setSnackbarSeverityState,
      setSnackbarText,
    ]
  );

  return token ? (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} lg={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                mb: 10,
                minWidth: '450px',
                p: 3,
              }}
            >
              <CardContent>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      fontSize: 24,
                      fontWeight: 600,
                      lineHeight: '33.6px',
                      mb: 4,
                    }}
                  >
                    パスワード再設定
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    新しいパスワードを入力してください。
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pt: 3 }}>
                  <FormControl fullWidth variant="outlined">
                    <FormLabel>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                        fontWeight={600}
                      >
                        新しいパスワード
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="passwords.password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          type={isPasswordVisible ? 'text' : 'password'}
                          error={error ? true : false}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="パスワードを入力"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlePasswordVisibility}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {isPasswordVisible ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <Typography
                      sx={{
                        color: theme.customPalette.lightGray,
                        textAlign: 'left',
                      }}
                    >
                      8文字以上、英数字含む（記号を除く）
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <FormControl fullWidth variant="outlined">
                    <FormLabel>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                        fontWeight={600}
                      >
                        新しいパスワードを再入力
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="passwords.confirmPassword"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          type={isPasswordVisible ? 'text' : 'password'}
                          error={error ? true : false}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="確認のため同じパスワードを入力"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlePasswordVisibility}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {isPasswordVisible ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ mt: 5 }}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!isValid}
                  >
                    送信
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Grid>
      </Grid>
    </>
  ) : null;
}
