import { AxiosPromise, AxiosResponse } from 'axios';

import {
  Attachment,
  AttachmentCreation,
  Message,
  MessageCategory,
  MessageCreation,
  MessageSenderType,
  MessageType,
  PaginatedTemplates,
  SubscriptionCreationRequest,
  SubscriptionCreationResponse,
  SubscriptionResponse,
  Template,
  Topic,
  TopicList,
  TopicOwnerType,
} from '../types/chats';
import { Paginated } from '../types/common';

import { axiosInstance, CATALOG_SERVICE, CHAT_SERVICE } from './axios';

import { getUploadedFileUrl } from '@app/utils/file_upload';

export function getMessageListWithFilter(
  userId: string
): AxiosPromise<Paginated<Message>> {
  const urlParams = [['$orderBy', 'createdAt desc']];

  return axiosInstance.get(
    `${CATALOG_SERVICE}/users/${userId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

// サブスクリプションしているTopic取得
export function getUserChatTopicListWithFilter(
  userId: string,
  options?: {
    expandUser?: boolean;
    pageNumber?: number;
    pageSize?: number;
  }
): AxiosPromise<Paginated<Topic>> {
  const urlParams = [['$orderBy', 'createdAt desc']];

  if (options?.expandUser) {
    urlParams.push(['$expand', 'user']);
  }

  if (options?.pageSize !== undefined) {
    urlParams.push(['$top', options.pageSize.toString()]);
  }

  if (options?.pageNumber !== undefined && options?.pageSize !== undefined) {
    urlParams.push([
      '$skip',
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }

  return axiosInstance.get(
    `${CHAT_SERVICE}/users/${userId}/topics?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export async function createNewTopic(
  userId: string,
  demandUserId: string,
  name: string
): Promise<AxiosResponse<Topic>> {
  const ownerIds = [userId, demandUserId];
  const requestBody = {
    app: 'user',
    isPublic: false,
    name,
    ownerIds,
    ownerType: TopicOwnerType.USER,
    typeId: 'channel',
  };

  return axiosInstance.post(`${CHAT_SERVICE}/topics`, requestBody);
}

// Topicのサブスクリプション取得
export const getTopicSubscriptions = async (
  topicId: string
): Promise<SubscriptionResponse> => {
  try {
    const response = await axiosInstance.get(
      `${CHAT_SERVICE}/topics/${topicId}/subscriptions?$expand=user`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching topic subscriptions:', error);
    throw error;
  }
};

export const createSubscription = async (
  requestData: SubscriptionCreationRequest
): Promise<SubscriptionCreationResponse> => {
  try {
    const url = `${CHAT_SERVICE}/subscriptions`;
    const response = await axiosInstance.post<SubscriptionCreationResponse>(
      url,
      requestData
    );
    return response.data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

export function getTopicMessageList(
  topicId: string
): AxiosPromise<Paginated<Message>> {
  const urlParams = [['$orderBy', 'createdAt desc']];

  return axiosInstance.get(
    `${CHAT_SERVICE}/topics/${topicId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function postChatMessage(userId: string, option: MessageCreation) {
  const payload = {
    ...option,
    category: MessageCategory.SUPPLY,
    messageType: MessageType.CHANNEL,
    senderId: userId,
    senderType: MessageSenderType.NORMAL_USER,
  };
  return axiosInstance.post(`${CHAT_SERVICE}/messages`, payload);
}

function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export async function postFile(userId: string, topicId: string, content: File) {
  const base64Content = await convertFileToBase64(content);
  let typeId = 'filedata';
  if (content.type.startsWith('image/')) {
    typeId = 'imagedata';
  }
  const data = {
    category: 'supply',
    content: base64Content,
    messageType: 'channel',
    senderId: userId,
    senderType: 'normal-user',
    title: content.name,
    topicId,
    typeId,
  };
  const response = await axiosInstance.post(`${CHAT_SERVICE}/messages`, data);
  return response;
}

export function getAllMessagesToOneTopic(
  topicId: string,
  options?: {
    expand?: string;
    limit?: number;
    nextLink?: string;
    orderBy?: string;
  }
): AxiosPromise<TopicList> {
  if (options?.nextLink) {
    return axiosInstance.get(options?.nextLink);
  }
  const urlParams = [['$top', String(options?.limit || 5)]];
  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return axiosInstance.get(
    `${CHAT_SERVICE}/topics/${topicId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function getAllMessagesToOneUser(
  userId: string
): AxiosPromise<TopicList> {
  const urlParams = [
    ['$orderBy', 'createdAt desc'],
    ['$top', '50'],
  ];
  return axiosInstance.get(
    `${CHAT_SERVICE}/users/${userId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function deleteMessage(
  messageId: string,
  updatedContent: string
): AxiosPromise<unknown> {
  return axiosInstance.patch(`${CHAT_SERVICE}/messages/${messageId}`, {
    content: updatedContent,
  });
}

export function markMessageAsRead(
  messageId: string,
  userId: string
): AxiosPromise<unknown> {
  const requestData = {
    messageId,
    userId,
  };
  return axiosInstance.post(`${CHAT_SERVICE}/messages:mark-read`, requestData);
}

export function getMessageTemplatesByOrgId(
  orgId: string
): AxiosPromise<PaginatedTemplates> {
  return axiosInstance.get(
    `${CHAT_SERVICE}/organizations/${orgId}/message-templates`
  );
}

export function getMessageTemplatesById(
  messageTemplateId: string
): AxiosPromise<Template> {
  return axiosInstance.get(
    `${CHAT_SERVICE}/message-templates/${messageTemplateId}`
  );
}

export function postMessageTemplates(
  title: string,
  content: string,
  orgId: string
): AxiosPromise<PaginatedTemplates> {
  return axiosInstance.post(`${CHAT_SERVICE}/message-templates`, {
    content,
    orgId,
    permissions: [],
    title,
  });
}

export function updateMessageTemplates(
  messageTemplateId: string,
  title: string,
  content: string
): AxiosPromise<PaginatedTemplates> {
  return axiosInstance.patch(
    `${CHAT_SERVICE}/message-templates/${messageTemplateId}`,
    {
      content,
      title,
    }
  );
}

export function deleteMessageTemplate(
  messageTemplateId: string
): AxiosPromise<PaginatedTemplates> {
  return axiosInstance.delete(
    `${CHAT_SERVICE}/message-templates/${messageTemplateId}`
  );
}

/**
 * attachment
 */
export function postAttachment(
  topicId: string,
  payload: AttachmentCreation
): AxiosPromise<{ id: string; url: string }> {
  return axiosInstance.post(
    `${CHAT_SERVICE}/topics/${topicId}/attachments`,
    payload
  );
}

export function deleteAttachment(
  topicId: string,
  id: string
): AxiosPromise<Paginated<Attachment[]>> {
  return axiosInstance
    .delete(`${CHAT_SERVICE}/topics/${topicId}/attachments/${id}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function uploadBlob(
  topicId: string,
  file: Blob
): Promise<Attachment> {
  if (!file) {
    throw new Error('blob is not defined');
  }
  const signedUrl = await getTopicUploadSignedUrl(topicId, file);
  const uploadedUrl = await getUploadedFileUrl(file, signedUrl);

  const objectId = new URL(uploadedUrl).pathname
    .split('?')[0]
    .replace(/^\/[^/]+\//, '');
  if (!objectId) {
    throw new Error('objectId is not undefined, upload may got error');
  }

  const objectSplits = objectId?.split('.');
  let extension = 'jpg';
  if (objectSplits && objectSplits.length > 1) {
    extension = objectSplits[objectSplits.length - 1];
  }
  const response = await postAttachment(topicId, {
    objectId,
    type: extension,
  });
  return response.data;
}

export function getTopicUploadSignedUrl(
  topicId: string,
  blob: Blob
): Promise<string> {
  return axiosInstance
    .get(
      `${CHAT_SERVICE}/topics/${topicId}/attachment-upload-url?contentLength=${blob.size}&contentType=${blob.type}`
    )
    .then((response) => response.data);
}
