import { Box, Button } from '@mui/material';
import {
  startOfMonth,
  endOfMonth,
  startOfDay,
  format as dateFnsFormat,
} from 'date-fns';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getCatalog } from '../adapter/catalog-service';
import { getOrdersListWithFilter } from '../adapter/order-service';
import { Calendar } from '../components/Home/Calendar';
import { CalendarItemProps } from '../components/Home/CalendarItem';
import { OrderInfo, OrderInfoGroup } from '../components/Home/OrderInfo';
import { MonthSelect } from '../components/Shared/MonthSelect';
import { snackbarOpenState, snackbarTextState } from '../domain/app';
import { organizationSelector } from '../domain/organization';
import { Product, ProductPublicationStatus } from '../types/catalog';
import { Order } from '../types/order';
import { isError } from '../utils/error';

import { formatDate } from '@app/utils/format';
import { unescapeHtml } from '@app/utils/pattern';

export function Home() {
  const today = new Date();
  const startMonth = dateFnsFormat(startOfMonth(today), 'yyyy/MM');
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const organizationState = useRecoilValue(organizationSelector);
  const [monthProductList, setMonthProductList] = useState<
    Product[] | undefined
  >(undefined);
  const [monthOrderList, setMonthOrderList] = useState<Order[] | undefined>(
    undefined
  );
  const [selectMonth, setSelectMonth] = useState(startMonth);
  const [dayList, setDayList] = useState<CalendarItemProps[]>([]);
  const [selectDate, setSelectDate] = useState<string>(
    dateFnsFormat(today, 'yyyy/MM/dd')
  );
  const [orderInfoList, setOrderInfoList] = useState<OrderInfoGroup[]>([]);
  const [orderInfoDate, setOrderInfoDate] = useState('');
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false);
  const [calendarErrorMessage, setCalendarErrorMessage] = useState('');
  // データ取得に使用するタイマー（無駄な検索が走らないための対策用）
  const loadTimerRef = useRef({
    ms: 0,
    timeoutId: undefined as NodeJS.Timeout | undefined,
  });

  const loadMonthProductList = useCallback(
    async (month: string): Promise<[Product[], string]> => {
      try {
        setMonthProductList(undefined);
        // NOTE:300件までを上限に取得
        const startDate = new Date(month + '/1');
        const endDate = endOfMonth(startDate);
        const list: Product[] = [];
        const pageSize = 100;
        for (let pageNumber = 0; pageNumber < 3; pageNumber++) {
          const result = await getCatalog(organizationState.id, {
            dateRange: {
              end: endDate.toISOString(),
              start: startDate.toISOString(),
            },
            order: 'customFields.days,customFields.startTime,',
            pageNumber,
            pageSize,
            statuses: [
              ProductPublicationStatus.ACTIVE,
              ProductPublicationStatus.ARCHIVED,
            ],
          });
          if (result.status !== 200) {
            throw new Error(`${result.status} ${result.statusText}`);
          }
          if (result.data.total > 300) {
            return [[], 'プラン件数が300件を超えているため表示できません'];
          }
          result.data.value.forEach((v) => list.push(v));
          if (result.data.total <= (pageNumber + 1) * pageSize) {
            break;
          }
        }
        setMonthProductList(list);
        return [list, ''];
      } catch (error: unknown) {
        throw new Error(
          `プランの取得に失敗しました` + (isError(error) ? error.message : '')
        );
      }
    },
    [organizationState.id]
  );

  const loadMonthOrderList = useCallback(
    async (month: string): Promise<[Order[], string]> => {
      try {
        setMonthOrderList(undefined);
        // NOTE:300件までを上限に取得
        const startDate = new Date(month + '/1');
        const endDate = endOfMonth(startDate);
        const list: Order[] = [];
        const pageSize = 100;
        for (let pageNumber = 0; pageNumber < 3; pageNumber++) {
          const result = await getOrdersListWithFilter(organizationState.id, {
            dateRange: {
              end: endDate.toISOString(),
              start: startDate.toISOString(),
            },
            order: 'customFields.productDay,createdAt',
            pageNumber,
            pageSize,
          });
          if (result.status !== 200) {
            throw new Error(`${result.status} ${result.statusText}`);
          }
          if (result.data.total > 300) {
            return [[], '応募件数が300件を超えているため表示できません'];
          }
          result.data.value.forEach((v) => list.push(v));
          if (result.data.total <= (pageNumber + 1) * pageSize) {
            break;
          }
        }
        setMonthOrderList(list);
        return [list, ''];
      } catch (error: unknown) {
        throw new Error(
          `応募の取得に失敗しました` + (isError(error) ? error.message : '')
        );
      }
    },
    [organizationState.id]
  );

  const loadCalendar = useCallback(
    async (month: string) => {
      try {
        setIsLoadingCalendar(true);
        setCalendarErrorMessage('');
        setDayList([]);
        const [productList, productError] = await loadMonthProductList(month);
        if (productError) {
          setCalendarErrorMessage(productError);
          return;
        }
        const [orderList, orderError] = await loadMonthOrderList(month);
        if (orderError) {
          setCalendarErrorMessage(orderError);
          return;
        }
        const list: CalendarItemProps[] = [];
        // AIPで取得したデータをCalendarコンポーネントに渡せる形に成型する
        productList.forEach((product) => {
          if (
            !product.customFields.days ||
            product.customFields.days.length === 0
          ) {
            return;
          }
          product.customFields.days.forEach((day) => {
            const date = new Date(day);
            const item = list.find(
              (e) =>
                e.date &&
                startOfDay(new Date(e.date)).getTime() ===
                  startOfDay(date).getTime()
            );
            if (item) {
              return;
            }
            list.push({
              date: dateFnsFormat(date, 'yyyy/MM/dd'),
              isAppointmentMatch: false,
              isProduct: true,
            });
          });
        });

        orderList.forEach((order) => {
          const product = productList.find(
            (p) => p.id === order.lineItems[0]?.product
          );
          // 予約日とカレンダーの日付が一致するか
          const appointmentDate = order.customFields?.appointmentDate
            ? new Date(order.customFields.appointmentDate)
            : null;

          list.forEach((item) => {
            const itemDate = item.date ? new Date(item.date) : null;

            if (
              itemDate &&
              appointmentDate &&
              startOfDay(itemDate).getTime() ===
                startOfDay(appointmentDate).getTime()
            ) {
              item.isAppointmentMatch = true;
            }
          });
          if (
            !product ||
            !product.customFields.days ||
            product.customFields.days.length === 0
          ) {
            return;
          }
          const productDate = order.customFields?.appointmentDate
            ? new Date(order.customFields.appointmentDate)
            : null;

          if (productDate) {
            const item = list.find(
              (e) =>
                e.date &&
                startOfDay(new Date(e.date)).getTime() ===
                  startOfDay(productDate).getTime()
            );
            if (!item) {
              return;
            }
            if (order.status === 'PENDING' && item.isAppointmentMatch) {
              item.mailCount = (item.mailCount ?? 0) + 1;
              return;
            }
            if (
              (order.status === 'ACCEPTED' ||
                order.status === 'PROCESSING' ||
                order.status === 'WAITING' ||
                order.status === 'CLOSED') &&
              item.isAppointmentMatch
            ) {
              if (!item.orderList) {
                item.orderList = [];
              }
              item.orderList?.push({
                familyName: order.customer.user.customFields?.familyName,
                time: order.customFields?.appointmentTime,
              });
            }
          }
        });
        setDayList(list);
      } catch (error: unknown) {
        if (isError(error)) {
          setSnackbarText(error.message);
        } else {
          setSnackbarText(`取得が失敗しました`);
        }
        setSnackbarOpen(true);
      } finally {
        setIsLoadingCalendar(false);
      }
    },
    [setSnackbarText, setSnackbarOpen, loadMonthProductList, loadMonthOrderList]
  );

  const loadOrderInfo = useCallback(
    async (date: string) => {
      // NOTE:別の月が選択された時クリアされないよう日付が読み込み済みのものと変わらなければ何もしない
      if (date === orderInfoDate) {
        return;
      }
      setOrderInfoList([]);
      if (!date || !monthProductList || !monthOrderList) {
        return;
      }
      const list: OrderInfoGroup[] = [];
      // AIPで取得したデータをOrderInfoコンポーネントに渡せる形に成型する
      monthProductList.forEach((product) => {
        if (
          !product.customFields.days ||
          product.customFields.days.length === 0
        ) {
          return;
        }
        product.customFields.days.forEach((day) => {
          const productDate = new Date(day);
          if (
            startOfDay(productDate).getTime() !==
            startOfDay(new Date(date)).getTime()
          ) {
            return;
          }
          const existingItem = list.find((e) => e.productId === product.id);
          if (!existingItem) {
            list.push({
              orderList: [
                {
                  endTime: product.customFields.endTime,
                  startTime: product.customFields.startTime,
                },
              ],
              productId: product.id,
              productName: unescapeHtml(product.name),
            });
          }
        });
      });

      monthOrderList.forEach((order) => {
        order.lineItems.forEach((lineItem) => {
          const product = monthProductList.find(
            (p) => p.id === lineItem.product
          );
          if (!product) {
            return;
          }
          product.customFields.days.forEach((day) => {
            const productDate = new Date(day);
            if (
              startOfDay(productDate).getTime() !==
              startOfDay(new Date(date)).getTime()
            ) {
              return;
            }
            const item = list.find((e) => e.productId === product.id);
            if (!item) {
              return;
            }
            if (order.status === 'CANCELED') {
              return;
            }

            const formattedAppointmentDate = order.customFields?.appointmentDate
              ? formatDate(order.customFields.appointmentDate)
              : '';
            const formattedSelectedDate = formatDate(date);
            if (formattedAppointmentDate === formattedSelectedDate) {
              const orderInfo = {
                appointmentDate: order.customFields?.appointmentDate,
                appointmentTime: order.customFields?.appointmentTime,
                birthday: order.customer.user.customFields?.birthday,
                endTime: order.customFields?.appointmentTime,
                familyName: order.customer.user.customFields?.familyName,
                firstName: order.customer.user.customFields?.firstName,
                gender: order.customer.user.customFields?.gender,
                isOrderMatchDate: true,
                orderId: order.id,
                startTime: product.customFields.startTime,
                status: order.status,
              };

              if (!item.orderList) {
                item.orderList = [];
              }
              if (
                !item.orderList.some((o) => o.orderId === orderInfo.orderId)
              ) {
                item.orderList.push(orderInfo);
              }
            }
          });
        });
      });

      // MEMO:応募なしが表示されるので複数ある場合はappointmentDateがないorderを削除
      list.forEach((item) => {
        if (item.orderList && item.orderList.length > 1) {
          item.orderList = item.orderList.filter(
            (order) => order.appointmentDate
          );
        }
      });

      setOrderInfoList(list);
      setOrderInfoDate(date);
    },
    [monthProductList, monthOrderList, orderInfoDate]
  );

  const handleMonthSelectChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadTimerRef.current = { ...loadTimerRef.current, ms: 300 }; //NOTE:無駄な検索防止に一定時間検索待機
    setSelectMonth(value);
  }, []);

  const handleCalendarClick: ComponentProps<typeof Calendar>['onClick'] =
    useCallback(async (value) => {
      setSelectDate(value.date ?? '');
    }, []);

  useEffect(() => {
    // NOTE:無駄な検索防止に一定時間検索待機
    clearTimeout(loadTimerRef.current.timeoutId);
    const timeoutId = setTimeout(() => {
      void loadCalendar(selectMonth);
    }, loadTimerRef.current.ms);
    loadTimerRef.current = { ms: 0, timeoutId };
  }, [loadCalendar, selectMonth]);

  useEffect(() => {
    void loadOrderInfo(selectDate);
  }, [loadOrderInfo, selectDate]);

  return (
    <Box display="flex" flexDirection="column" gap={2} width="fit-content">
      <Box display="inline-flex" gap={2} alignItems="center" width="100%">
        <MonthSelect
          isBack
          isForward
          value={selectMonth}
          onChange={handleMonthSelectChange}
        />
        <Button
          sx={{
            marginLeft: 'auto',
            width: '12rem',
          }}
          size="small"
          variant="contained"
          component={Link}
          to="/product/create"
        >
          プランの新規登録
        </Button>
      </Box>
      <Box display="inline-flex" gap={1}>
        <Calendar
          loading={isLoadingCalendar}
          month={selectMonth}
          selectDate={selectDate}
          dayValueList={dayList}
          errorMessage={calendarErrorMessage}
          onClick={handleCalendarClick}
        />
        <OrderInfo date={selectDate} orderGroupList={orderInfoList} />
      </Box>
    </Box>
  );
}
