import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  ListSubheader,
} from '@mui/material';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { fetchCategories } from '../../adapter/catalog-service';
import {
  batchRestoreOrder,
  getOrdersListWithFilter,
} from '../../adapter/order-service';
import { snackbarOpenState, snackbarTextState } from '../../domain/app';
import { categoryList, categoryState } from '../../domain/catalog';
import { deletedOrdersLocalized, orderState } from '../../domain/order';
import { organizationSelector } from '../../domain/organization';
import { Category } from '../../types/catalog';
import { OrderLocalized, OrderStatus } from '../../types/order';
import { isError } from '../../utils/error';
import { PageTitle } from '../Shared/PageTitle';

/**
 * @todo Should be sortable.
 */
export function DeletedOrders(): ReactElement {
  const organizationState = useRecoilValue(organizationSelector);
  const setCategoryState = useSetRecoilState(categoryState);
  const categoryStateList = useRecoilValue(categoryList);
  const orders = useRecoilValue(orderState);
  const setOrderState = useSetRecoilState(orderState);
  const deletedOrderLocalizedList = useRecoilValue(deletedOrdersLocalized);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  // Changed number to string since Ids are string in the data
  const [filters, setFilters] = useState({
    categoryIdFilter: 'UNSET',
    contentIdFilter: '-1',
    keywordFilter: '',
  });

  const getCategories = useCallback(async () => {
    const result = await (await fetchCategories()).data;
    setCategoryState(result);
    //NOTE: UNDO COMMENT IF ENDPOINT IS READY
    // if (result.status === 200) {
    //   setCategoryState(result.data);
    // } else {
    //   console.warn(result);
    //   //TODO show snackbar error
    // }
  }, [setCategoryState]);

  const renderCategoryMenuItems = (category: Category) => {
    const items = category.children?.map((subCategory) => {
      return (
        <MenuItem value={subCategory.id} key={subCategory.id}>
          {subCategory.name}
        </MenuItem>
      );
    });

    return [
      <ListSubheader key={category.id}>{category.name}</ListSubheader>,
      items,
    ];
  };

  const handleCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { target } = event;
    const value = target.value;
    setSelectedIDs((ids) =>
      target.checked ? [...ids, value] : ids.filter((id) => id !== value)
    );
  }, []);

  const getOrders = useCallback(async () => {
    const { categoryIdFilter, keywordFilter } = filters;

    const options = {
      order: 'createdAt',
      pageNumber: currentPage,
      pageSize: 10,
      statuses: [OrderStatus.CANCELED],
      ...(keywordFilter ? { keyword: keywordFilter } : {}),
      ...(categoryIdFilter === 'UNSET'
        ? { categoryIds: [] }
        : { categoryIds: [categoryIdFilter] }),
    };
    try {
      const result = await getOrdersListWithFilter(
        organizationState.id,
        options
      );
      if (result.status === 200) {
        setOrderState(result.data);
      } else {
        setSnackbarText(`問い合わせの取得が失敗しました`);
        setSnackbarOpen(true);
      }
    } catch (error: unknown) {
      if (isError(error)) {
        setSnackbarText(`問い合わせの取得が失敗しました, ${error.message}`);
      } else {
        setSnackbarText(`問い合わせの取得が失敗しました`);
      }
      setSnackbarOpen(true);
    }
  }, [
    filters,
    currentPage,
    organizationState.id,
    setOrderState,
    setSnackbarOpen,
    setSnackbarText,
  ]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setCurrentPage(value);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(() => {}, []);

  const handleUndoButtonClick = useCallback(async () => {
    const restoredIds = await batchRestoreOrder(
      organizationState.id,
      selectedIDs
    );
    if (restoredIds.length > 0) {
      await getOrders();
      setSelectedIDs([]);
    } else {
      //TODO show snackbar error
    }
  }, [organizationState.id, selectedIDs, getOrders]);

  const handleChangeCategoryIdFilter = useCallback(
    ({ target }: SelectChangeEvent) => {
      const value = target.value;
      setFilters((filters) => ({ ...filters, categoryIdFilter: value }));
    },
    []
  );

  // TODO: debounce
  const handleChangeKeywordFilter = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setFilters((filters) => ({ ...filters, keywordFilter: target.value }));
    },
    []
  );

  // TODO: slow
  const filter = (data: OrderLocalized[]) => {
    const { categoryIdFilter, contentIdFilter, keywordFilter } = filters;
    let filtered = [...data];
    if (categoryIdFilter !== 'UNSET')
      filtered = filtered.filter(
        (item) => item.lineItem.product === categoryIdFilter
      );
    if (contentIdFilter !== '-1')
      filtered = filtered.filter(
        (item) => item.lineItem.variantTitle === contentIdFilter
      );
    if (keywordFilter)
      filtered = filtered.filter((item) => {
        return (
          item.lineItem.product.match(keywordFilter) ||
          item.lineItem.variantTitle.match(keywordFilter) ||
          item.customer.phone.match(keywordFilter) ||
          item.customer.email.match(keywordFilter) ||
          item.customer.name.match(keywordFilter) ||
          item.customer.prefecture.match(keywordFilter)
        );
      });
    return filtered;
  };

  useEffect(() => {
    if (!organizationState) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getOrders();
  }, [organizationState, filters, getOrders]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getCategories();
  }, [getCategories]);

  return (
    <>
      <Box sx={{ mb: 3, mt: 1 }}>
        <Grid container spacing={1}>
          <PageTitle title="削除済みアイテム" />
        </Grid>
      </Box>
      <Paper sx={{ pb: 0, pt: 2 }}>
        <Toolbar sx={{ mb: 1, px: '1rem !important' }}>
          <Grid container spacing={1}>
            <Grid item xs={2.5}>
              <TextField
                fullWidth
                size="small"
                placeholder="キーワードで検索"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChangeKeywordFilter}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                fullWidth
                value={String(filters.categoryIdFilter)}
                size="small"
                onChange={handleChangeCategoryIdFilter}
              >
                <MenuItem value="UNSET">プラン</MenuItem>
                {categoryStateList.map((category) =>
                  renderCategoryMenuItems(category)
                )}
              </Select>
            </Grid>
            {/* NOTE: NOT NEEDED IN ALPHA
            <Grid item xs={2.5}>
              <Select
                fullWidth
                value={String(filters.contentIdFilter)}
                size="small"
                onChange={handleChangeContentIdFilter}
              >
                <MenuItem value="-1">相談内容</MenuItem>
                <MenuItem value="1">浮気現場の尾行、証拠写真の撮影</MenuItem>
                <MenuItem value="2">子供の非行防止</MenuItem>
              </Select>
            </Grid> */}
            {selectedIDs.length > 0 ? (
              <Grid item xs={4} textAlign="right">
                <Button
                  color="secondary"
                  size="small"
                  variant="outlined"
                  sx={{ mr: 1 }}
                  onClick={handleUndoButtonClick}
                >
                  元に戻す
                </Button>
              </Grid>
            ) : undefined}
          </Grid>
        </Toolbar>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>問い合わせ番号</TableCell>
                <TableCell>プラン</TableCell>
                <TableCell>相談内容</TableCell>
                <TableCell>依頼者氏名</TableCell>
                <TableCell>連絡先</TableCell>
                <TableCell>依頼者居住地</TableCell>
                <TableCell>問い合わせ日</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filter(deletedOrderLocalizedList).map((datum) => (
                <TableRow
                  key={datum.id}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  data-e2e="deleted-order-table-row"
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIDs.includes(datum.id)}
                      value={datum.id}
                      onChange={handleCheck}
                    />
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {datum.id}
                  </TableCell>
                  <TableCell padding="none">
                    {datum.lineItem.productName}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {datum.lineItem.variantTitle}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {datum.customer.name}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <div>{datum.customer.phone}</div>
                    {datum.customer.email}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {datum.customer.prefecture}
                  </TableCell>
                  <TableCell align="center">{datum.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={orders.total}
          labelRowsPerPage="1ページの表示数"
          rowsPerPage={10}
          page={currentPage}
          showFirstButton
          showLastButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
