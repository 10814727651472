import AddIcon from '@mui/icons-material/Add';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getMessageTemplatesByOrgId } from '../../adapter/chat-service';
import { getUserInvolvedOrganizations } from '../../adapter/organization-service';
import { loggedInUserState } from '../../domain/app';
import { templatesState } from '../../domain/chat';
import { User } from '../../types/user';

type TemplateModalProps = {
  insertTemplate: (template: string) => void;
  isOpen: boolean;
  onClose: () => void;
};

export const TemplateModal: React.FC<TemplateModalProps> = ({
  isOpen,
  onClose,
  insertTemplate,
}) => {
  const theme = useTheme();
  const [selectedTemplate, setSelectedTemplate] = React.useState('');
  const templates = useRecoilValue(templatesState).value || [];
  const setTemplates = useSetRecoilState(templatesState);
  const loggedInUser = useRecoilValue(loggedInUserState) as User;

  useEffect(() => {
    let firstOrgId: string | undefined;

    getUserInvolvedOrganizations(loggedInUser.id)
      .then((response) => {
        const organizations = response.data.value;

        if (organizations.length > 0) {
          firstOrgId = organizations[0].id;
        }

        if (firstOrgId) {
          return getMessageTemplatesByOrgId(firstOrgId);
        }
      })
      .then((response) => {
        if (response) {
          setTemplates(response.data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [loggedInUser.id, setTemplates]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTemplate((event.target as HTMLInputElement).value);
  };

  const handleInsertTemplate = () => {
    insertTemplate(selectedTemplate);
    setSelectedTemplate('');
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        sx={{
          backgroundColor: theme.customPalette.white,
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          maxWidth: '800px',
          padding: '30px',
          width: '80%',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, pb: 4 }}>
          テンプレートを選択
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="template"
            name="template"
            value={selectedTemplate}
            onChange={handleChange}
          >
            {templates.map((template) => {
              return (
                <FormControlLabel
                  key={template.id}
                  value={template.content}
                  control={<Radio />}
                  label={template.title}
                />
              );
            })}
          </RadioGroup>

          <Grid sx={{ alignItems: 'center', display: 'flex', mt: 2 }}>
            <Button sx={{ color: theme.palette.text.primary }}>
              <AddIcon sx={{ color: theme.customPalette.lightGray, ml: -1 }} />
              <Link
                to="/chat/templateList/register"
                style={{
                  color: theme.customPalette.gray,
                  fontSize: '1rem',
                  textDecoration: 'none',
                }}
              >
                <Typography sx={{ pl: 1 }}>新規テンプレート</Typography>
              </Link>
            </Button>
          </Grid>
        </FormControl>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{ fontSize: '0.9rem', marginRight: '10px' }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleInsertTemplate}
            sx={{ fontSize: '0.9rem', pl: 5, pr: 5 }}
          >
            次へ
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
