import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Button,
  Divider,
  Avatar,
  Typography,
  CircularProgress,
  useTheme,
  Checkbox,
  BoxProps,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getSingleOrder, updateOrderStatus } from '../adapter/order-service';
import {
  loggedInUserState,
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '../domain/app';
import { organizationSelector } from '../domain/organization';
import { OrderLocalized, SupportedOrderStatus } from '../types/order';

import {
  getSingleProduct,
  setProductsStatus,
} from '@app/adapter/catalog-service';
import { OrderLayout } from '@app/components/Orders/OrderLayout';
import { ReviewDialog } from '@app/components/Review/ReviewDialog';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import { FollowButton } from '@app/components/Shared/FollowButton';
import { UserProfile } from '@app/components/Users/UserProfile';
import { UserReviews } from '@app/components/Users/UserReviews';
import { useProductsAdd } from '@app/hooks/catalog';
import { useReviewsAdd } from '@app/hooks/review';
import { Product } from '@app/types/catalog';
import { convertOrderLocalized } from '@app/utils/order';
import { unescapeHtml } from '@app/utils/pattern';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function ItemBox({
  children,
  title,
  sx,
  ...boxProps
}: { title: ReactNode } & Omit<BoxProps, 'title'>) {
  return (
    <RowBox
      alignItems="center"
      {...boxProps}
      sx={[...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
      gap={1}
    >
      <ColBox width="12rem" fontSize="0.9rem">
        {title}
      </ColBox>
      {children}
    </RowBox>
  );
}

export function OrderDetails() {
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const organizationState = useRecoilValue(organizationSelector);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [order, setOrder] = useState(undefined as OrderLocalized | undefined);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [isLoadData, setIsLoadData] = useState(false);
  const theme = useTheme();
  const reviewsAddCount = 10;
  const [reviewsDisplayCount, setReviewsDisplayCount] =
    useState(reviewsAddCount);
  /** 評価リスト、評価件数 */
  const { reviews, reviewsTotal, setReviews, setReviewsTotal } = useReviewsAdd(
    order?.customer?.user?.id,
    reviewsDisplayCount,
    100
  );
  /** 評価に割りついたプランリスト */
  const reviewProducts = useProductsAdd(
    reviews?.map((r) => r.customFields?.productId)
  );
  const orderId = pageLocation.pathname.split('/')[2] ?? '';
  const [isOpenReviewDialog, setIsOpenReviewDialog] = useState(false);
  const [isOpenAcceptedDialog, setIsOpenAcceptedDialog] = useState(false);
  const [isOpenWaitingDialog, setIsOpenWaitingDialog] = useState(false);
  const [isOpenCanceledDialog, setIsOpenCanceledDialog] = useState(false);
  const [isOpenProcessingDialog, setIsOpenProcessingDialog] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isProductArchived, setIsProductArchived] = useState(false);
  const userName = `${order?.customer?.user?.customFields?.familyName} ${order?.customer?.user?.customFields?.firstName}`;

  const handleClickReviewsButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenReviewDialog(true);
    }, []);

  const handleClickChatButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      navigate('/chat');
    }, [navigate]);

  const loadOrder = useCallback(async () => {
    try {
      if (!orderId) {
        return;
      }
      const result = await getSingleOrder(organizationState.id, orderId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setOrder(convertOrderLocalized(result.data));
    } catch (error) {
      setSnackbarText(
        `応募の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadData(true);
    }
  }, [organizationState.id, orderId, setSnackbarOpen, setSnackbarText]);

  const statusChange = useCallback(
    async (status: SupportedOrderStatus) => {
      try {
        if (!order) {
          return;
        }
        const result = await updateOrderStatus(
          organizationState.id,
          order.id,
          status as SupportedOrderStatus
        );
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        setOrder({ ...order, status });
      } catch (error) {
        setSnackbarText(
          `応募の更新に失敗しました, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      }
    },
    [organizationState.id, order, setSnackbarOpen, setSnackbarText]
  );

  const updateProductArchived = useCallback(async () => {
    if (!product?.id) {
      return;
    }
    try {
      const result = await setProductsStatus(
        organizationState.id,
        [product.id],
        'ARCHIVED'
      );
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
    } catch (error) {
      setSnackbarText(
        `プランの更新に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [product, organizationState, setSnackbarText, setSnackbarOpen]);

  const handleClickAcceptedButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenAcceptedDialog(true);
    }, []);

  const handleClickWaitingButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenWaitingDialog(true);
    }, []);

  const handleClickProcessingButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenProcessingDialog(true);
    }, []);

  const handleChangeProductArchived: NonNullable<
    ComponentProps<typeof Checkbox>['onChange']
  > = useCallback((e, checked) => {
    setIsProductArchived(checked);
  }, []);

  const handleClickNextUserReview: ComponentProps<
    typeof UserReviews
  >['onClickNext'] = useCallback(() => {
    setReviewsDisplayCount((prev) => prev + reviewsAddCount);
  }, [setReviewsDisplayCount]);

  const handleChangeUserReview: NonNullable<
    ComponentProps<typeof UserReviews>['onChange']
  > = useCallback(
    (reviews) => {
      setReviews(reviews);
      setSnackbarText(`${userName}さんを評価しました。`);
      setSnackbarState('info');
      setSnackbarOpen(true);
      void loadOrder();
    },
    [
      setReviews,
      userName,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
      loadOrder,
    ]
  );

  const handleClosReviewDialog: ComponentProps<typeof ReviewDialog>['onClose'] =
    useCallback(
      (result, review) => {
        setIsOpenReviewDialog(false);
        if (result === 'confirm' && review) {
          setReviewsTotal((prev) => (prev ?? 0) + 1);
          setReviews((prev) => [review, ...(prev ?? [])]);
        }
        if (result === 'confirm' || result === 'decline') {
          setOrder((prev) => (prev ? { ...prev, status: 'CLOSED' } : prev));
          setSnackbarText(
            result === 'confirm'
              ? `${userName}さんを評価しました。`
              : `${userName}さんの評価を辞退しました。`
          );
          setSnackbarState('info');
          setSnackbarOpen(true);
          void loadOrder();
        }
      },
      [
        setReviewsTotal,
        setReviews,
        userName,
        setSnackbarText,
        setSnackbarState,
        setSnackbarOpen,
        loadOrder,
      ]
    );

  const handleCloseWaitingDialog = useCallback(
    async (confirm?: boolean) => {
      if (!confirm) {
        setIsOpenWaitingDialog(false);
        return;
      }
      setIsLoadingDialog(true);
      await statusChange('CLOSED');
      if (isProductArchived) {
        await updateProductArchived();
      }
      setIsLoadingDialog(false);
      setIsOpenWaitingDialog(false);
      setSnackbarText(`${userName}さんの投稿確認が完了しました。`);
      setSnackbarState('info');
      setSnackbarOpen(true);
    },
    [
      statusChange,
      isProductArchived,
      updateProductArchived,
      userName,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
    ]
  );

  const handleCloseProcessingDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm) {
        setIsOpenProcessingDialog(false);
        return;
      }
      setIsLoadingDialog(true);
      await statusChange('PROCESSING');
      setIsLoadingDialog(false);
      setIsOpenProcessingDialog(false);
      setSnackbarText(`${userName}さんの来店報告しました。`);
      setSnackbarState('info');
      setSnackbarOpen(true);
    },
    [
      statusChange,
      userName,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
      setIsOpenProcessingDialog,
    ]
  );

  const handleCloseAcceptedDialog = useCallback(
    async (confirm?: boolean) => {
      if (!confirm) {
        setIsOpenAcceptedDialog(false);
        return;
      }
      setIsLoadingDialog(true);
      await statusChange('ACCEPTED');
      if (isProductArchived) {
        await updateProductArchived();
      }
      // TODO:予約承認メールを送信する
      setIsLoadingDialog(false);
      setIsOpenAcceptedDialog(false);
      setSnackbarText(`${userName}さんを予約承認しました。`);
      setSnackbarState('info');
      setSnackbarOpen(true);
    },
    [
      statusChange,
      isProductArchived,
      updateProductArchived,
      userName,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
    ]
  );

  const handleClickCanceledButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenCanceledDialog(true);
    }, []);

  const handleCloseCanceledDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm) {
        setIsOpenCanceledDialog(false);
        return;
      }
      setIsLoadingDialog(true);
      await statusChange('CANCELED');
      // TODO:予約否認メールを送信する
      setIsLoadingDialog(false);
      setIsOpenCanceledDialog(false);
      setSnackbarText(`${userName}さんを予約否認しました。`);
      setSnackbarState('info');
      setSnackbarOpen(true);
    },
    [
      statusChange,
      userName,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
      setIsOpenCanceledDialog,
    ]
  );

  const loadProduct = useCallback(async () => {
    try {
      if (!order?.lineItem?.product) {
        return;
      }
      const result = await getSingleProduct(order.lineItem.product);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const product = result.data;
      setProduct(product);
    } catch (error) {
      setSnackbarText(
        `プランの取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [order, setSnackbarText, setSnackbarOpen]);

  useEffect(() => {
    void loadOrder();
  }, [loadOrder]);

  useEffect(() => {
    void loadProduct();
  }, [loadProduct]);

  if (!isLoadData) {
    return (
      <OrderLayout>
        <CircularProgress sx={{ m: 'auto' }} />
      </OrderLayout>
    );
  }

  if (!order) {
    return (
      <OrderLayout>
        <Typography sx={{ m: 'auto' }}>
          ID:{orderId}の応募を取得できませんでした
        </Typography>
      </OrderLayout>
    );
  }

  const isDeleteUser = !!order.customer.user.customFields.deleted_at;

  return (
    <OrderLayout>
      {product && (
        <RowBox gap={1}>
          <ColBox>
            <ColBox color={theme.palette.grey[600]}>
              <Typography>{product.id}</Typography>
            </ColBox>
            <RowBox alignItems="baseline" gap={2} sx={{ mb: 3 }}>
              <ColBox>
                <Typography fontSize={'1.8rem'}>
                  {unescapeHtml(product.name)}
                </Typography>
              </ColBox>
            </RowBox>

            <RowBox alignItems="baseline" gap={2}>
              <ColBox>
                <Typography fontWeight={600}>予約日：</Typography>
              </ColBox>
              <ColBox>
                <Typography fontWeight={600}>
                  {/* 表示形式を年月日(曜日)に */}
                  {order.customFields?.appointmentDate
                    ? new Date(
                        order.customFields.appointmentDate
                      ).toLocaleDateString('ja-JP', {
                        day: 'numeric',
                        month: 'numeric',
                        weekday: 'short',
                        year: 'numeric',
                      })
                    : '未定'}
                </Typography>
              </ColBox>
            </RowBox>
            <RowBox alignItems="baseline" gap={2}>
              <ColBox>
                <Typography fontWeight={600}>予約時間：</Typography>
              </ColBox>
              <ColBox>
                <Typography fontWeight={600}>
                  {order.customFields?.appointmentTime}
                </Typography>
              </ColBox>
            </RowBox>
            <RowBox alignItems="baseline" gap={2}>
              <ColBox>
                <Typography fontWeight={600}>予約人数：</Typography>
              </ColBox>
              <ColBox>
                <Typography fontWeight={600}>
                  {order.customFields?.peopleCount}名
                </Typography>
              </ColBox>
            </RowBox>
          </ColBox>
          <RowBox gap={2} ml="auto" py={1}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '8rem' }}
              onClick={() => {
                navigate('/product/view', { state: { id: product.id } });
              }}
            >
              プラン詳細
            </Button>
          </RowBox>
        </RowBox>
      )}
      <Divider sx={{ my: 3 }} />
      <ColBox gap={2}>
        <RowBox gap={2}>
          <Typography fontSize="1.8rem">応募者</Typography>
          <RowBox gap={2} ml="auto">
            <FollowButton
              variant="outlined"
              color="secondary"
              size="small"
              sx={{
                width: '7rem',
              }}
              userId={loggedInUser?.id ?? ''}
              followUserId={order?.customer?.user?.id ?? ''}
            />
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '7rem' }}
              disabled={
                order.status !== 'PROCESSING' && order.status !== 'CLOSED'
              }
              onClick={handleClickReviewsButton}
            >
              評価を書く
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{
                display: 'none', // TODO:正式実装されたら削除する
                width: '7rem',
              }}
              onClick={handleClickChatButton}
            >
              メッセージ
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '7rem' }}
              disabled={order.status !== 'PENDING'}
              onClick={handleClickAcceptedButton}
            >
              予約承認
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: order.status !== 'ACCEPTED' ? '7rem' : '10rem' }}
              disabled={
                order.status !== 'PENDING' && order.status !== 'ACCEPTED'
              }
              onClick={handleClickCanceledButton}
            >
              {order.status !== 'ACCEPTED' ? '予約否認' : '予約をキャンセル'}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '8rem' }}
              disabled={order.status !== 'ACCEPTED'}
              onClick={handleClickProcessingButton}
            >
              来店報告
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '7rem' }}
              disabled={order.status !== 'WAITING'}
              onClick={handleClickWaitingButton}
            >
              投稿確認
            </Button>
          </RowBox>
        </RowBox>
        <RowBox gap={2} alignItems="center">
          <Avatar
            sx={{ backgroundColor: '#53BAC8', height: 32, width: 32 }}
            src={order.customer?.user?.avatar}
          >
            <PersonIcon />
          </Avatar>
          {isDeleteUser && <Typography>（退会済ユーザー）</Typography>}
          <Typography>
            {order.customer?.user.customFields?.familyName}
          </Typography>
          <Typography>
            {order.customer?.user.customFields?.firstName}
          </Typography>
          <Typography>/</Typography>
          <Typography>
            {order.customer?.user.customFields?.familyNameKana}
          </Typography>
          <Typography>
            {order.customer?.user.customFields?.firstNameKana}
          </Typography>
        </RowBox>
      </ColBox>

      <Divider sx={{ mt: 2 }} />

      <ItemBox title="ステータス" my={4}>
        <Typography>
          {order.status === 'PENDING'
            ? '未対応'
            : order.status === 'ACCEPTED'
            ? '予約承認済み'
            : order.status === 'PROCESSING'
            ? '投稿待ち'
            : order.status === 'WAITING'
            ? '投稿確認中'
            : order.status === 'CLOSED'
            ? '評価済み'
            : order.status === 'CANCELED'
            ? '予約否認・キャンセル'
            : ''}
        </Typography>
      </ItemBox>

      {(order.status === 'WAITING' || order.status === 'CLOSED') && (
        <ItemBox title="投稿したSNSのURL" my={3}>
          {order.customFields?.snsUrl && (
            <Link
              to={order.customFields.snsUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {order.customFields.snsUrl}
            </Link>
          )}
        </ItemBox>
      )}

      <UserProfile user={order.customer?.user} orderStatus={order.status} />

      <UserReviews
        reviewProducts={reviewProducts}
        reviews={reviews}
        reviewsDisplayCount={reviewsDisplayCount}
        reviewsTotal={reviewsTotal}
        loading={reviewsTotal === undefined}
        onClickNext={handleClickNextUserReview}
        onChange={handleChangeUserReview}
      />

      <ReviewDialog
        open={isOpenReviewDialog}
        userId={order.customer.user.id}
        productId={product?.id}
        orderId={order.id}
        onClose={handleClosReviewDialog}
      />

      <ConfirmDialog
        title={`${userName}さんが来店しましたか？`}
        okButtonText="来店しました"
        okButtonLoading={isLoadingDialog}
        open={isOpenProcessingDialog}
        onClose={handleCloseProcessingDialog}
      >
        <ColBox width="24rem" gap={2}>
          <Typography color={theme.palette.grey[500]}>
            ※来店報告をすると元のステータスには戻せません。
          </Typography>
        </ColBox>
      </ConfirmDialog>
      <ConfirmDialog
        title={`${userName}さんを予約承認しますか？`}
        okButtonText="予約承認する"
        okButtonLoading={isLoadingDialog}
        open={isOpenAcceptedDialog}
        onClose={handleCloseAcceptedDialog}
      >
        <ColBox width="24rem" gap={2}>
          <Typography>
            「予約承認する」を押すと、{userName}さんに予約承認通知が送られます。
          </Typography>
          <Typography color={theme.palette.grey[500]}>
            ※予約承認後のキャンセルはご遠慮ください。
          </Typography>
          <ColBox p={2}>
            <FormControlLabel
              sx={{ width: 'fit-content' }}
              control={
                <Checkbox
                  checked={isProductArchived}
                  onChange={handleChangeProductArchived}
                />
              }
              label="対象のプランについては応募を締め切る"
            />
          </ColBox>
        </ColBox>
      </ConfirmDialog>
      <ConfirmDialog
        title={`${userName}さんを予約否認しますか？`}
        okButtonText="予約否認する"
        okButtonLoading={isLoadingDialog}
        open={isOpenCanceledDialog}
        onClose={handleCloseCanceledDialog}
      >
        <ColBox width="24rem" gap={2}>
          <Typography>予約否認のメッセージを送ります。</Typography>
        </ColBox>
      </ConfirmDialog>
      <ConfirmDialog
        title={`${userName}さんの投稿を確認しましたか？`}
        okButtonText="投稿確認しました"
        okButtonLoading={isLoadingDialog}
        open={isOpenWaitingDialog}
        onClose={handleCloseWaitingDialog}
      ></ConfirmDialog>
    </OrderLayout>
  );
}
