import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Paper, Typography, Grid, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageTitle } from '../Shared/PageTitle';

interface AccountSettingHeaderProps {
  title: string;
}

export const AccountSettingHeader: React.FC<AccountSettingHeaderProps> = ({
  title,
}) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ mb: 3, mt: 1 }}>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Grid container alignItems="center">
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: 'white',
                border: '1px solid #DBDFE5',
                borderRadius: '2px',
                marginRight: '10px',
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" fontWeight={300}>
              アカウント設定
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

interface AccountSettingPaperProps {
  children: ReactNode;
  title: string;
}

export const AccountSettingPaper: React.FC<AccountSettingPaperProps> = ({
  title,
  children,
}) => {
  return (
    <Paper sx={{ p: 4 }}>
      <Grid>
        <PageTitle title={title} />
      </Grid>
      <Divider sx={{ my: 3 }} />
      {children}
    </Paper>
  );
};
