/*
   共通 data grid Styling
 */
import { useTheme } from '@mui/material';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

const PAGE_SIZE_OPTIONS = [10, 20, 50];
export const INITIAL_PAGINATE_MODEL = {
  page: 0,
  pageSize: PAGE_SIZE_OPTIONS[0],
};
const COLUMN_HEADER_HEIGHT = 45;
const DEFAULT_ROW_HEIGHT = 50;
export const StandardStyleDataGrid = (props: DataGridProps) => {
  const theme = useTheme();

  return (
    <DataGrid
      sx={{
        '& .row-select': {
          background: theme.customPalette.selection,
        },
        ',MuiDataGrid-columnHeaderTitleContainer': {
          py: 0,
        },
        '.MuiDataGrid-cell': {
          ':focus-within': { outline: 'none' },
          p: 0.5,
        },
        '.MuiDataGrid-columnHeader': {
          ':focus-within': { outline: 'none' },
          px: 0.5,
        },
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: theme.palette.grey[200],
          borderRadius: 0,
        },
        border: 'none',
      }}
      checkboxSelection={false}
      initialState={{
        pagination: { paginationModel: INITIAL_PAGINATE_MODEL },
      }}
      columnHeaderHeight={COLUMN_HEADER_HEIGHT}
      rowHeight={DEFAULT_ROW_HEIGHT}
      autoHeight={true}
      paginationMode="server"
      localeText={{
        noResultsOverlayLabel: 'データがありません',
        noRowsLabel: 'データがありません',
      }}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      slotProps={{
        pagination: {
          labelRowsPerPage: (
            <label style={{ fontSize: '0.8rem' }}>1ページの表示数</label>
          ),
          showFirstButton: true,
          showLastButton: true,
        },
      }}
      {
        /*上書き*/
        ...props
      }
    />
  );
};
