export const getBase64 = async (
  url: string
): Promise<string | ArrayBuffer | null> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export function filterSyntaxGen(list: string[]) {
  const formattedList = list.map((p) => `'${p}'`);
  return `[${formattedList.join(',')}]`;
}

export function convertNewLine(
  str: string | undefined,
  separator: string | RegExp = /\\r\\n|\\r|\\n/g
) {
  if (!str) {
    return '';
  }
  return str.replaceAll(separator, '\n');
}

export function getAge(birthday?: string) {
  if (!birthday) {
    return undefined;
  }
  const today = new Date();
  const birth = new Date(birthday);
  const age =
    today.getFullYear() -
    birth.getFullYear() +
    (today <
    new Date(today.getFullYear(), birth.getMonth() - 1, birth.getDate())
      ? -1
      : 0);
  return age;
}
