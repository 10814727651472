// Copyright 2022 Co-Lift Inc.

// FIXME: not ready, please ignore this file when reviewing. I will create another PR for accept invitation
// Cause accept invitation need some backend changes
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Button,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { acceptInvitation } from '../adapter/user-service';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '../domain/app';
import { getInvitationId, getVerifyToken } from '../domain/network-actions';
import { generatePasswordPattern } from '../utils/pattern';

interface AcceptInvitationSchema {
  email: string;
  name: string;
  passwords: {
    confirmPassword: string;
    password: string;
  };
}

export function AcceptInvitation() {
  const navigate = useNavigate();
  const token = getVerifyToken();
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const invitationId = getInvitationId();
  const theme = useTheme();
  const { control, handleSubmit } = useForm<AcceptInvitationSchema>({
    defaultValues: {
      name: '',
      passwords: { confirmPassword: '', password: '' },
    },
    mode: 'all',
    resolver: zodResolver(
      z.object({
        name: z.string().min(1),
        passwords: z
          .object({
            confirmPassword: z
              .string()
              .min(
                8,
                'パスワードは記号を除く、英数字含む8文字以上を入力してください'
              )
              .max(24, '24文字以下のパスワードを入力してください')
              .regex(generatePasswordPattern(), {
                message: '小文字英字と数字を組み合わせてください',
              }),
            password: z
              .string()
              .min(
                8,
                'パスワードは記号を除く、英数字含む8文字以上を入力してください'
              )
              .max(24, '24文字以下のパスワードを入力してください')
              .regex(generatePasswordPattern(), {
                message: '小文字英字と数字を組み合わせてください',
              }),
          })
          .refine(
            ({ password, confirmPassword }) => password === confirmPassword,
            {
              message: 'パスワードが一致しません',
              path: ['confirmPassword'],
            }
          ),
      })
    ),
  });
  const onSubmit = useCallback(
    async ({ name, passwords }: AcceptInvitationSchema) => {
      try {
        const response = await acceptInvitation(
          invitationId,
          { name, password: passwords.password },
          token
        );
        if (response.status === 200) {
          setSnackbarText(`ユーザー作成しました`);
          setSnackbarState('info');
          setSnackbarOpen(true);
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        } else {
          setSnackbarText(`ユーザー作成失敗しました, ${response.data.message}`);
          setSnackbarOpen(true);
        }
      } catch (e: unknown) {
        setSnackbarText(
          `ユーザー作成失敗しました, ${e instanceof Error ? e.message : ''}`
        );
        setSnackbarOpen(true);
      }
    },
    [
      invitationId,
      navigate,
      setSnackbarOpen,
      setSnackbarState,
      setSnackbarText,
      token,
    ]
  );

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2">
                  招待登録
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={600}
                    >
                      名前
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="text"
                        error={error ? true : false}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="名前を入力"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={600}
                    >
                      パスワード
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="passwords.password"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="password"
                        error={error ? true : false}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="パスワードを入力"
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      color: theme.customPalette.lightGray,
                      textAlign: 'left',
                    }}
                  >
                    8文字以上、英数字含む（記号を除く）
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={600}
                    >
                      パスワードを再入力
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="passwords.confirmPassword"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="password"
                        error={error ? true : false}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="パスワードを再入力"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  登録
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
