import {
  format as dateFnsFormat,
  addMonths,
  addDays,
  endOfDay,
  startOfDay,
} from 'date-fns';
import { ja as dateFnsJa } from 'date-fns/locale';
import { z } from 'zod';
export const productFormSchema = z
  .object({
    access: z.string(),
    additionalInformation: z.string(),
    canBookToday: z.boolean(),
    categoryEnum: z.enum(['spot', 'regularPartTime']),
    days: z.string().array().optional(),
    description: z.string().nonempty('プラン内容を入力してください'),
    endPeriod: z.string().refine((d) => {
      return true;
    }),
    endPeriodDayCount: z.number().nullable(),
    endPeriodStatus: z.enum(['prev', 'daysAgo', 'day']),
    endTime: z.string().nonempty(),
    imageIds: z.string().array(),
    inputMode: z.enum(['create', 'copy', 'edit']),
    maxBookingPeople: z
      .number()
      .nullable()
      .refine((v) => v && v > 0, '最大予約人数を入力してください'),
    maxReservations: z
      .number()
      .nullable()
      .refine((v) => v && v > 0, '予約上限数を入力してください'),
    minBookingPeople: z
      .number()
      .nullable()
      .refine((v) => v && v > 0, '最低予約人数を入力してください'),
    name: z.string().nonempty('プラン名を入力してください'),
    prRules: z.string().nonempty('PR条件を入力してください'),
    price: z.string(),
    repeatWeek: z.number().array(),
    reservationApproval: z.boolean(),
    reservationNotes: z.string(),
    scheduleType: z.enum(['day', 'week']),
    startPeriod: z.string().nonempty('開始日を入力してください'),
    startTime: z.string().nonempty(),
    status: z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT']),
    tags: z
      .string()
      .array()
      .min(1, { message: 'キーワードを入力してください' }),

    variantsId: z.string().optional(),
    variantsPriceAmount: z
      .number()
      .nullable()
      .refine((v) => v && v > 0, '金額を入力してください'),
    variantsSku: z.enum(['hour', 'day']),
    workAddress1: z.string(),
    workAddress2: z.string(),
    workCity: z.string(),
    workLocationOther: z.boolean(),
    workPostalCode: z.string(),
    workPrefecture: z.string(),
  })
  .refine(
    (arg) =>
      arg.inputMode === 'edit' ||
      new Date(arg.startPeriod).getTime() >= startOfDay(new Date()).getTime(),
    {
      message: '開始日は現在以降の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine((arg) => !arg.workLocationOther || arg.workPostalCode, {
    message: '郵便番号を入力してください',
    path: ['workPostalCode'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workPrefecture, {
    message: '都道府県を選択してください',
    path: ['workPrefecture'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workCity, {
    message: '市町村を選択してください',
    path: ['workCity'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workAddress1, {
    message: '町名・番地を入力してください',
    path: ['workAddress1'],
  })
  .refine((arg) => !arg.workLocationOther || arg.access, {
    message: 'アクセス情報を入力してください',
    path: ['access'],
  })
  .refine(
    (arg) =>
      new Date(arg.startPeriod).getTime() <=
      endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '開始日は半年以前の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine((arg) => !(arg.endPeriodStatus === 'day') || arg.endPeriod, {
    message: '終了日を入力してください',
    path: ['endPeriod'],
  })
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'day') ||
      new Date(arg.startPeriod).getTime() <= new Date(arg.endPeriod).getTime(),
    {
      message: '終了日は開始日以降の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'day') ||
      new Date(arg.endPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '終了日は半年以前の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'daysAgo') ||
      (arg.endPeriodDayCount && arg.endPeriodDayCount > 0),
    {
      message: '来店日X日前を入力してください',
      path: ['endPeriodDayCount'],
    }
  )
  .refine((arg) => !(arg.scheduleType === 'day') || arg.days?.length, {
    message: '募集する日にちを選択してください',
    path: ['days'],
  })
  .refine(
    (arg) => {
      return !(arg.scheduleType === 'week') || arg.repeatWeek.length;
    },
    {
      message: '募集曜日を選択してください',
      path: ['repeatWeek'],
    }
  )
  .refine((arg) => arg.startTime < arg.endTime, {
    message: '終了時間は開始時間より後の時間を入力してください',
    path: ['endTime'],
  });

export type ProductForm = z.infer<typeof productFormSchema>;

export const defaultProductForm: ProductForm = {
  access: '',
  additionalInformation: '',
  canBookToday: false,
  categoryEnum: 'spot',
  days: [],
  description: '',
  endPeriod: dateFnsFormat(addDays(addMonths(new Date(), 6), -1), 'yyyy/MM/dd'),
  endPeriodDayCount: null,
  endPeriodStatus: 'prev',
  endTime: '18:00',
  imageIds: [],
  inputMode: 'create',
  maxBookingPeople: 10,
  maxReservations: 1,
  minBookingPeople: 1,
  name: '',
  prRules: '',
  price: '',
  repeatWeek: [],
  reservationApproval: false,
  reservationNotes: '',
  scheduleType: 'day',
  startPeriod: dateFnsFormat(new Date(), 'yyyy/MM/dd'),
  startTime: '08:00',
  status: 'ACTIVE',
  tags: [],
  variantsId: undefined,
  variantsPriceAmount: 1000,
  variantsSku: 'day',
  workAddress1: '',
  workAddress2: '',
  workCity: '',
  workLocationOther: false,
  workPostalCode: '',
  workPrefecture: '',
};

export const productBulkFormSchema = z
  .object({
    access: z.string(),
    additionalInformation: z.string(),
    checkAdditionalInformation: z.boolean(),
    checkAll: z.undefined(), // NOTE:全体チェック用に定義
    checkAttributesClinical: z.boolean(),
    checkAttributesJob: z.boolean(),
    checkBreakTime: z.boolean(),
    checkCommuting: z.boolean(),
    checkCount: z.boolean(),
    checkDescription: z.boolean(),
    checkOrderConditions: z.boolean(),
    checkPeriod: z.boolean(),
    checkPrice: z.boolean(),
    checkSelection: z.boolean(),
    checkStatus: z.boolean(),
    checkTime: z.boolean(),
    checkVariantsDescription: z.boolean(),
    checkWorkLocation: z.boolean(),
    day: z.date().optional(), // NOTE:チェック用
    description: z.string(),
    endPeriod: z.string(),
    endTime: z.string(),
    maxReservations: z.number().nullable(),
    minBookingPeople: z.number().nullable(),
    prRules: z.string(),
    reservationNotes: z.string(),
    startPeriod: z.string(),
    startTime: z.string(),
    status: z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT', '']),
    variantsPriceAmount: z.number().nullable(),
    variantsSku: z.enum(['hour', 'day', '']),
    workAddress1: z.string(),
    workAddress2: z.string(),
    workCity: z.string(),
    workLocationOther: z.boolean(),
    workPostalCode: z.string(),
    workPrefecture: z.string(),
  })
  .refine(
    (arg) =>
      arg.checkAdditionalInformation ||
      arg.checkAttributesClinical ||
      arg.checkAttributesJob ||
      arg.checkBreakTime ||
      arg.checkCount ||
      arg.checkDescription ||
      arg.checkOrderConditions ||
      arg.checkPeriod ||
      arg.checkPrice ||
      arg.checkSelection ||
      arg.checkStatus ||
      arg.checkTime ||
      arg.checkVariantsDescription ||
      arg.checkWorkLocation,
    {
      message: 'いずれかの項目にチェックを入れてください',
      path: ['checkAll'],
    }
  )
  .refine((arg) => !arg.checkStatus || arg.status, {
    message: '掲載ステータスを入力してください',
    path: ['status'],
  })
  .refine((arg) => !arg.checkPeriod || arg.startPeriod, {
    message: '開始日を入力してください',
    path: ['startPeriod'],
  })
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() >= startOfDay(new Date()).getTime(),
    {
      message: '開始日は現在以降の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '開始日は半年以前の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine(
    (arg) => {
      return (
        !arg.checkPeriod ||
        !arg.day ||
        new Date(arg.startPeriod).getTime() <= arg.day.getTime()
      );
    },
    (arg) => {
      return {
        message: `開始日は${
          arg.day
            ? dateFnsFormat(arg.day, 'yyyy年MM月dd日', { locale: dateFnsJa })
            : ''
        }以前の日付を入力してください`,
        path: ['startPeriod'],
      };
    }
  )
  .refine((arg) => !arg.checkPeriod || arg.endPeriod, {
    message: '終了日を入力してください',
    path: ['endPeriod'],
  })
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.endPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '終了日は半年以前の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() <= new Date(arg.endPeriod).getTime(),
    {
      message: '終了日は開始日以降の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workPostalCode,
    {
      message: '郵便番号を入力してください',
      path: ['workPostalCode'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workPrefecture,
    {
      message: '都道府県を選択してください',
      path: ['workPrefecture'],
    }
  )
  .refine(
    (arg) => !arg.checkWorkLocation || !arg.workLocationOther || arg.workCity,
    {
      message: '市町村を選択してください',
      path: ['workCity'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workAddress1,
    {
      message: '町名・番地を入力してください',
      path: ['workAddress1'],
    }
  )
  .refine(
    (arg) => !arg.checkWorkLocation || !arg.workLocationOther || arg.access,
    {
      message: 'アクセス情報を入力してください',
      path: ['access'],
    }
  )
  .refine((arg) => !arg.checkDescription || arg.description, {
    message: 'プラン内容を入力してください',
    path: ['description'],
  })
  .refine((arg) => !arg.checkTime || arg.startTime, {
    message: '開始時間を入力してください',
    path: ['startTime'],
  })
  .refine((arg) => !arg.checkTime || arg.endTime, {
    message: '終了時間を入力してください',
    path: ['endTime'],
  })
  .refine((arg) => !arg.checkTime || arg.startTime < arg.endTime, {
    message: '終了時間は開始時間より後の時間を入力してください',
    path: ['endTime'],
  })
  .refine((arg) => !arg.checkPrice || arg.variantsSku, {
    message: '給与を選択してください',
    path: ['variantsSku'],
  })
  .refine((arg) => !arg.checkPrice || arg.variantsPriceAmount, {
    message: '給与を入力してください',
    path: ['variantsPriceAmount'],
  })
  .refine((arg) => !arg.checkCount || arg.maxReservations, {
    message: '募集人数を選択してください',
    path: ['count'],
  });

export type ProductBulkForm = z.infer<typeof productBulkFormSchema>;

export const defaultProductBulkForm: ProductBulkForm = {
  access: '',
  additionalInformation: '',
  checkAdditionalInformation: false,
  checkAttributesClinical: false,
  checkAttributesJob: false,
  checkBreakTime: false,
  checkCommuting: false,
  checkCount: false,
  checkDescription: false,
  checkOrderConditions: false,
  checkPeriod: false,
  checkPrice: false,
  checkSelection: false,
  checkStatus: false,
  checkTime: false,
  checkVariantsDescription: false,
  checkWorkLocation: false,
  description: '',
  endPeriod: dateFnsFormat(addDays(addMonths(new Date(), 6), -1), 'yyyy/MM/dd'),
  endTime: '18:00',
  maxReservations: 1,
  minBookingPeople: 1,
  prRules: '',
  reservationNotes: '',
  startPeriod: dateFnsFormat(new Date(), 'yyyy/MM/dd'),
  startTime: '00:00',
  status: '',
  variantsPriceAmount: null,
  variantsSku: 'day',
  workAddress1: '',
  workAddress2: '',
  workCity: '',
  workLocationOther: false,
  workPostalCode: '',
  workPrefecture: '',
};
