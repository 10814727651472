import { get } from 'lodash';

import { urlToBase64 } from './base64';

type FileInfo = {
  extension: string;
  fileName: string;
  isUrl: boolean;
};

const urlMatcher = new RegExp('^https?://');
/**
 * check url
 * @param url
 * @returns bool of isUrl
 */
function isUrl(url: string): boolean {
  return urlMatcher.test(url);
}

/**
 * get fileInfo from url
 * @param url url
 * @returns FileInfo
 */
function getFileInfoFromUrl(url: string): FileInfo {
  const withOutQueryParams = url.split('?')[0];
  const fileFullName = withOutQueryParams.substring(
    withOutQueryParams.lastIndexOf('/') + 1
  );
  const infos = fileFullName.split('.');
  return {
    extension: infos.length > 1 ? infos[1] : '',
    fileName: infos[0],
    isUrl: true,
  };
}

const mimeTypeExtensionMap = {
  'application/pdf': 'pdf',
  'image/gif': 'gif',
  'image/jpeg': 'jpeg',
  'image/png': 'png',
};

/**
 * get extension from mime type
 * @param mimeType mimeType
 * @returns extension
 */
function getExtensionByMimeType(mimeType: string): string {
  return get(mimeTypeExtensionMap, mimeType, '');
}

const mimeTypeMatcher = /[^:]\w+\/[\w-+\d.]+(?=;|,)/;
/**
 * get file info from blob url
 * @param base64
 * @returns FileInfo
 */
async function getFileInfoFromBlobUrl(blobUrl: string): Promise<FileInfo> {
  const data = await urlToBase64(blobUrl);
  const mimeMatches = data.match(mimeTypeMatcher);
  const mimeType: string =
    mimeMatches && mimeMatches.length > 0 ? mimeMatches[0] : '';
  return {
    extension: mimeType ? getExtensionByMimeType(mimeType) : '',
    fileName: '',
    isUrl: false,
  };
}

/**
 * get file info from base64 data or url
 * @param fileUrl
 * @returns FileInfo
 */
export async function getFileInfo(fileUrl: string): Promise<FileInfo> {
  if (!fileUrl) {
    return {
      extension: '',
      fileName: '',
      isUrl: false,
    };
  }
  return isUrl(fileUrl)
    ? getFileInfoFromUrl(fileUrl)
    : getFileInfoFromBlobUrl(fileUrl);
}

/**
 * check is pdf
 * @param extension
 * @returns bool
 */
export function isPdf(extension: string) {
  return extension === 'pdf';
}

/**
 * check is image
 * @param extension
 * @returns bool
 */
export function isImage(extension: string) {
  return (
    extension === 'jpeg' ||
    extension === 'jpg' ||
    extension === 'png' ||
    extension === 'gif'
  );
}
