import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  TextField,
  Box,
  InputAdornment,
  Grid,
  Badge,
  Checkbox,
  useTheme,
  TablePagination,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { parseISO, format } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  getAllMessagesToOneTopic,
  getUserChatTopicListWithFilter,
} from '../adapter/chat-service';
import {
  userAuthInfoSelector,
  snackbarSeverityState,
  snackbarTextState,
} from '../domain/app';
import { chatTopicsState } from '../domain/chat';
import { isError } from '../utils/error';

import { Topic, MessageTypeId } from '@app/types/chats';
import { getAge } from '@app/utils';
import {
  unescapeHtml,
  unescapeHtmlAndConvertNewLinesToHtml,
} from '@app/utils/pattern';

export function ChatList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [chatTopics, setChatTopics] = useRecoilState(chatTopicsState);
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(snackbarSeverityState);
  const setErrorSnackbarText = useSetRecoilState(snackbarTextState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOnlyUnread, setIsOnlyUnread] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [totalRowCount, setTotalRowCount] = useState(0);

  // 指定されたトピックに関連するメッセージ情報を取得してトピックを更新
  const updateTopicWithMessages = async (topic: Topic) => {
    const messagesResponse = await getAllMessagesToOneTopic(topic.id, {
      limit: 1,
      orderBy: 'createdAt desc',
    });
    const messages = messagesResponse.data.value;

    if (messages && messages.length > 0) {
      const unreadMessagesCount = messages.filter(
        (message) =>
          message.readStatus === 'unread' && message.category === 'demand'
      ).length;

      const lastMessageContent = messages[0].content;
      const lastMessageCreatedAt = messages[0].createdAt;
      const lastMessageTypeId = messages[0].typeId;

      return {
        ...topic,
        content: lastMessageContent,
        messageTypeId: lastMessageTypeId,
        unreadCount: unreadMessagesCount,
        updatedAt: lastMessageCreatedAt,
      };
    }
    return topic;
  };

  // 指定されたトピックIDに関連するメッセージ情報を取得して、全体のトピックリストの中でそのトピックを更新
  const fetchMessagesAndUpdateTopic = useCallback(
    async (topicId: string) => {
      try {
        const topicToUpdate = chatTopics.value.find((t) => t.id === topicId);
        if (!topicToUpdate) return;

        const updatedTopic = await updateTopicWithMessages(topicToUpdate);

        setChatTopics((prevTopics) => {
          const updatedTopics = prevTopics.value.map((topic) => {
            if (topic.id === topicId) {
              return updatedTopic;
            }
            return topic;
          });

          return { ...prevTopics, value: updatedTopics };
        });
      } catch (error) {
        console.error('Error fetching and marking messages:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setChatTopics]
  );

  const getChatTopics = useCallback(async () => {
    setIsLoading(true);
    if (!userAuthInfoState) {
      setIsLoading(false);
      return;
    }
    try {
      const result = await getUserChatTopicListWithFilter(
        userAuthInfoState.id,
        {
          expandUser: true,
          pageNumber: page,
          pageSize: rowsPerPage,
        }
      );

      // TopicNameをChat相手の名前に上書き
      const updatedTopics = await Promise.all(
        result.data.value.map(async (topic) => {
          const targetUser = topic.owners?.find(
            (owner) => owner.typeId === '000'
          );
          if (targetUser) {
            topic.name =
              targetUser.customFields.familyName +
              targetUser.customFields.firstName +
              ' / ' +
              `${getAge(targetUser.customFields.birthday)}歳` +
              '・' +
              targetUser.customFields.gender;
          } else {
            topic.name = '無名';
          }

          return updateTopicWithMessages(topic);
        })
      );

      // TopicをupdatedAtの新しい時系列順にソート
      const sortedTopics = updatedTopics.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

      setChatTopics({ ...result.data, value: sortedTopics });

      setTotalRowCount(result.data.total);
    } catch (error) {
      if (isError(error)) {
        setErrorSnackbarText(`チャットの取得に失敗しました, ${error.message}`);
      } else {
        setErrorSnackbarText(`チャットの取得に失敗しました`);
      }
      setErrorSnackbarOpen('error');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setChatTopics, fetchMessagesAndUpdateTopic, page, rowsPerPage]);

  useEffect(() => {
    if (!userAuthInfoState) {
      navigate('/login');
      return;
    }
    void getChatTopics();
  }, [getChatTopics, navigate, userAuthInfoState]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChangeUnread = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsOnlyUnread(event.target.checked);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const displayedChatTopics = chatTopics?.value?.filter((topic) => {
    if (isOnlyUnread && (!topic.unreadCount || topic.unreadCount <= 0))
      return false;
    const includesSearchKeyword = (value: string | undefined | null) => {
      return value ? value.includes(searchKeyword) : false;
    };

    if (
      searchKeyword &&
      !includesSearchKeyword(topic.content) &&
      !includesSearchKeyword(topic.name)
    ) {
      return false;
    }
    return true;
  });

  function formatDate(updatedAt: string) {
    const date = parseISO(updatedAt);
    const differenceInMilliseconds = new Date().getTime() - date.getTime();
    const differenceInHours = differenceInMilliseconds / (60 * 60 * 1000);
    const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000);
    const differenceInWeeks =
      differenceInMilliseconds / (7 * 24 * 60 * 60 * 1000);

    if (differenceInHours < 24) {
      return format(date, 'HH:mm');
    } else if (differenceInDays < 8) {
      return `${Math.floor(differenceInDays)}日前`;
    } else if (differenceInDays < 30) {
      return `${Math.floor(differenceInWeeks)}週間前`;
    }
    const differenceInMonths = differenceInDays / 30;
    return `${Math.floor(differenceInMonths)}ヶ月前`;
  }

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Typography
          variant="h5"
          component="div"
          sx={{ pb: 2, pt: 2, textAlign: 'left' }}
        >
          メッセージ
        </Typography>
        <Typography
          variant="h5"
          component="div"
          sx={{ pb: 2, pt: 2, textAlign: 'left' }}
        >
          <Link
            to="/chat/templateList"
            style={{ color: 'inherit', fontSize: '1rem' }}
          >
            テンプレート管理
          </Link>
        </Typography>
      </Grid>

      <Paper elevation={1}>
        <Box sx={{ display: 'flex', padding: '20px' }}>
          <TextField
            variant="outlined"
            placeholder="キーワードで検索"
            value={searchKeyword}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Grid sx={{ alignItems: 'center', display: 'flex', ml: 2 }}>
            <Checkbox
              checked={isOnlyUnread}
              onChange={handleCheckboxChangeUnread}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            <Typography variant="body1">未読のみで絞り込み</Typography>
          </Grid>
        </Box>
        {isLoading ? (
          <Grid style={{ padding: '20px', textAlign: 'center' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <List sx={{ pb: 5 }}>
              {displayedChatTopics.map((chatTopic) => (
                <Grid key={chatTopic.id}>
                  <Divider />
                  <ListItem
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/chats/${chatTopic.id}`);
                    }}
                  >
                    <Checkbox
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                    <ListItemAvatar>
                      <Grid
                        sx={{
                          alignItems: 'center',
                          backgroundColor: 'lightblue',
                          borderRadius: '50%',
                          color: theme.customPalette.white,
                          display: 'flex',
                          justifyContent: 'center',
                          mr: 2,
                          p: '10px 0px ',
                        }}
                      >
                        <PersonOutlineIcon />
                      </Grid>
                    </ListItemAvatar>
                    <Grid>
                      <ListItemText
                        primary={
                          <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            {unescapeHtml(chatTopic.name)}
                          </Typography>
                        }
                        secondary={''}
                      />
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 300, pb: 1, pt: 1 }}
                          >
                            {chatTopic.messageTypeId === MessageTypeId.IMAGE
                              ? '画像'
                              : chatTopic.messageTypeId === MessageTypeId.FILE
                              ? 'ファイル'
                              : unescapeHtmlAndConvertNewLinesToHtml(
                                  chatTopic.content || ''
                                )}
                          </Typography>
                        }
                        secondary={''}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Grid
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {chatTopic.unreadCount > 0 && (
                          <Badge
                            badgeContent={chatTopic.unreadCount}
                            sx={{
                              '.MuiBadge-badge': {
                                backgroundColor: theme.customPalette.alert,
                                borderRadius: '50%',
                                color: theme.customPalette.white,
                                fontSize: '1.0rem',
                                height: 'auto',
                                mr: 4,
                                p: '10px 9px',
                                width: 'auto',
                              },
                            }}
                          />
                        )}
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                color: theme.customPalette.gray,
                                fontSize: '0.8rem',
                                fontWeight: 300,
                                mb: 2,
                                pt: 2,
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {formatDate(chatTopic.updatedAt)}
                            </Typography>
                          }
                          secondary={''}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </Grid>
              ))}
              <TablePagination
                sx={{
                  bottom: 0,
                  fontSize: '0.7rem',
                  position: 'absolute',
                  width: '100%',
                }}
                labelRowsPerPage={
                  <label style={{ fontSize: '0.8rem' }}>1ページの表示数</label>
                }
                rowsPerPageOptions={[5, 10, 20, 30]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} of ${count}`
                }
                showFirstButton
                showLastButton
                count={totalRowCount}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                data-e2e="home-pagination"
              />
            </List>
          </>
        )}
      </Paper>
    </>
  );
}
