import { Button, ButtonProps } from '@mui/material';
import { ComponentProps, useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { followUser, unFollowUser } from '@app/adapter/user-service';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';

export interface FollowButtonProps extends ButtonProps {
  followUserId: string;
  following?: boolean;
  onChangeFollow?: (isFollowing: boolean) => void;
  userId: string;
}

export function FollowButton({
  userId,
  followUserId,
  following,
  onChangeFollow,
  onClick,
  ...buttonProps
}: FollowButtonProps) {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [isFollowing, setIsFollowing] = useState(false);
  const isFollowingMerge = following ?? isFollowing;

  const handleFollow = useCallback(async () => {
    if (!followUserId || isFollowingMerge || !userId) {
      return;
    }
    try {
      await followUser(followUserId, userId, 'user');
      setIsFollowing(true);
      onChangeFollow?.(true);
    } catch (error) {
      setSnackbarText(
        `フォローに失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [
    isFollowingMerge,
    userId,
    followUserId,
    onChangeFollow,
    setSnackbarText,
    setSnackbarOpen,
  ]);

  const handleUnFollow = useCallback(async () => {
    if (!followUserId || !isFollowingMerge || !userId) {
      return;
    }
    try {
      await unFollowUser(followUserId, userId, 'user');
      setIsFollowing(false);
      onChangeFollow?.(false);
    } catch (error) {
      setSnackbarText(
        `フォローに失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [
    isFollowingMerge,
    userId,
    followUserId,
    onChangeFollow,
    setSnackbarText,
    setSnackbarOpen,
  ]);

  const handleClickFollowButton: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(
    async (e) => {
      onClick?.(e);
      if (isFollowingMerge) {
        await handleUnFollow();
      } else {
        await handleFollow();
      }
    },
    [onClick, isFollowingMerge, handleUnFollow, handleFollow]
  );

  // TODO：正式実装するまでコメントアウト
  // const loadFollowing = useCallback(async () => {
  //   if (!followUserId || !userId) {
  //     return;
  //   }
  //   try {
  //     const result = await getUserFollowList(userId, 'user', {
  //       followIds: [followUserId],
  //     });
  //     const isUserFollowing = result.value.some(
  //       (follow) => follow.followId === followUserId
  //     );
  //     if (isFollowingMerge !== isUserFollowing) {
  //       setIsFollowing(isUserFollowing);
  //       onChangeFollow?.(isUserFollowing);
  //     }
  //   } catch (error) {
  //     setSnackbarText(
  //       `フォローの取得に失敗しました, ${
  //         error instanceof Error ? error.message : error
  //       }`
  //     );
  //     setSnackbarOpen(true);
  //   }
  // }, [
  //   followUserId,
  //   userId,
  //   isFollowingMerge,
  //   onChangeFollow,
  //   setSnackbarOpen,
  //   setSnackbarText,
  // ]);

  // const isLoad = following === undefined;

  // useEffect(() => {
  //   // フォローの初期状態が設定されていれば自動で読み込まない
  //   if (!isLoad) {
  //     return;
  //   }
  //   void loadFollowing();
  // }, [isLoad, loadFollowing]);

  return (
    <Button
      variant="outlined"
      color="secondary"
      {...buttonProps}
      onClick={handleClickFollowButton}
    >
      {isFollowingMerge ? 'フォロー中' : 'フォロー'}
    </Button>
  );
}
