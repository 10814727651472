import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Paper,
  Typography,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  Toolbar,
  useTheme,
} from '@mui/material';
import {
  addMonths,
  startOfMonth,
  endOfMonth,
  format as dateFnsFormat,
  subMonths,
} from 'date-fns';
import {
  useCallback,
  useEffect,
  useState,
  ComponentProps,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import {
  getCatalog,
  deleteProducts,
  getAttributes,
  fetchCategories,
} from '../adapter/catalog-service';
import { getOrderStats } from '../adapter/order-service';
import { ProductBulkEditState } from '../components/Catalog/ProductBulkEdit';
import { ProductCreationState } from '../components/Catalog/ProductCreation';
import { ProductDetailState } from '../components/Catalog/ProductDetail';
import {
  ProductTable,
  ProductTableRow,
} from '../components/Catalog/ProductTable';
import { ConfirmDialog } from '../components/Shared/ConfirmDialog';
import { MonthSelect } from '../components/Shared/MonthSelect';
import { PageTitle } from '../components/Shared/PageTitle';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '../domain/app';
import { organizationSelector } from '../domain/organization';
import {
  Attribute,
  Category,
  SupportedProductPublicationStatus,
} from '../types/catalog';
import { getMenuItems } from '../utils/components';
import { productStatusList } from '../utils/constants';
import { isError } from '../utils/error';

import { unescapeHtml } from '@app/utils/pattern';

export function ProductManagement() {
  const navigate = useNavigate();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);

  const ALL = 'ALL';
  const [tableRows, setTableRows] = useState<ProductTableRow[]>([]);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [clickID, setClickID] = useState<string>('');
  const [selectionIds, setSelectionIds] = useState<string[]>([]);
  const [selectionCategoryName, setSelectionCategoryName] = useState('');
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // 案件区分の状態
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  // プラン種別リストの状態
  const [jobTypeList, setJobTypeList] = useState<Attribute[]>([]);
  // プランデータ取得に使用するタイマー（無駄な検索が走らないための対策用）
  const loadProductTimerRef = useRef<{
    ms: number;
    timeoutId: NodeJS.Timeout | undefined;
  }>({ ms: 0, timeoutId: undefined });
  // プランデータの検索条件
  const [filter, setFilter] = useState({
    endPeriod: endOfMonth(addMonths(startOfMonth(new Date()), 1)),
    jobType: ALL,
    keyword: '',
    page: 0,
    pageSize: 10,
    startPeriod: startOfMonth(new Date()),
    status: ALL as SupportedProductPublicationStatus | typeof ALL,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);

  const theme = useTheme();

  /** プラン区分の読み込み */
  const loadCategories = useCallback(async () => {
    try {
      const result = await fetchCategories();
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const category =
        result.data.value.find((c) => c.name === '案件区分')?.children ?? [];
      setCategoryList(category);
      return category;
    } catch (error) {
      setSnackbarText(
        `プラン区分の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
      return [];
    }
  }, [setSnackbarText, setSnackbarOpen]);

  /** ジャンルまたはプラン種別の読み込み */
  const loadAttributes = useCallback(async () => {
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['genre'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const clinicalList = result.data.value;

      const resultJob = await getAttributes({
        filter: {
          groupName: ['genre'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (resultJob.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const jobList = resultJob.data.value;
      setJobTypeList(jobList);

      return [clinicalList, jobList];
    } catch (error) {
      setSnackbarText(
        `ジャンルまたはプラン種別の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
      return [[], []];
    }
  }, [setSnackbarText, setSnackbarOpen]);

  /** プラン一覧の読み込み */
  const loadProduct = useCallback(async () => {
    try {
      setIsLoadingTable(true);
      if (
        !filter.startPeriod ||
        !filter.endPeriod ||
        !categoryList.length ||
        // !clinicalDepartmentList.length ||
        !jobTypeList.length
      ) {
        setTableRows([]);
        return;
      }
      const result = await getCatalog(organizationState.id, {
        // attributesClinicalDepartment:
        //   filter.clinicalDepartment === ALL ? [] : [filter.clinicalDepartment],
        attributesJobType: filter.jobType === ALL ? [] : [filter.jobType],
        dateRange: {
          end: filter.endPeriod.toISOString(),
          isEmpty: true,
          start: filter.startPeriod.toISOString(),
        },
        keyword: filter.keyword,
        order:
          'categoryId desc,customFields.days,customFields.startTime,customFields.endTime,createdAt desc',
        pageNumber: filter.page,
        pageSize: filter.pageSize,
        statuses: filter.status === ALL ? [] : [filter.status],
      });
      if (result.status !== 200) {
        throw new Error(`${result.status}`);
      }

      const productIds = result.data.value.map((p) => p.id);

      // プランごとの応募件数を取得
      const resultOrderStats = productIds.length
        ? await getOrderStats(organizationState.id, productIds)
        : undefined;
      if (resultOrderStats && resultOrderStats.status !== 200) {
        throw new Error(`応募件数取得 ${result.status}`);
      }

      // AIPで取得したデータをProductTableコンポーネントに渡せる形に成型
      const rows: ProductTableRow[] = result.data.value.map((product) => {
        return {
          attributesJob: product.attributes
            .filter((a) => jobTypeList.some((i) => i.id === a.attributeId))
            .map((a) => ({
              id: a.attributeId,
              name: a.value,
            })),
          categoryName: categoryList.find((c) => c.id === product.categoryId)
            ?.name,
          days: product.customFields.days,
          description: product.description,
          endTime: product.customFields.endTime,
          id: product.id,
          inquiry:
            resultOrderStats?.data.find((o) => o.productId === product.id)
              ?.count ?? 0,
          name: unescapeHtml(product.name),
          price: product.customFields.price,
          publicationSince: product.publication.since,
          publicationUntil: product.publication.until,
          scheduleType: product.customFields.scheduleType,
          searchConditionWeek: product.customFields.repeatWeek,
          startTime: product.customFields.startTime,
          status: product.publication.status,
          tags: product.tags,
        } as ProductTableRow;
      });
      setTableRows(rows);
      setTotalRowCount(result.data.total);
    } catch (error) {
      setSnackbarText(
        `プラン一覧の取得に失敗しました。, ${
          isError(error) ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadingTable(false);
    }
  }, [
    filter,
    categoryList,
    jobTypeList,
    organizationState.id,
    setSnackbarText,
    setSnackbarOpen,
  ]);

  const handleStartPeriodChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    const [year, month] = value.split('/');
    const correctedDate = `${year}-${month.padStart(2, '0')}-01`;

    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 500 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      startPeriod: startOfMonth(new Date(correctedDate)),
    }));
  }, []);

  const handleEndPeriodChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    const [year, month] = value.split('/');
    const correctedDate = `${year}-${month.padStart(2, '0')}-01`;

    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 500 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      endPeriod: endOfMonth(new Date(correctedDate)),
    }));
  }, []);

  const handleCreateButtonClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    navigate('create');
  }, [navigate]);

  const handleBulkCancelButtonClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    setSelectionIds([]);
  }, [setSelectionIds]);

  const handleBulkEditButtonClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    navigate('bulk', {
      state: { ids: selectionIds } as ProductBulkEditState,
    });
  }, [navigate, selectionIds]);

  const handleBulkDeleteButtonClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    setShouldShowDeleteDialog((current) => (current ? current : true));
  }, [setShouldShowDeleteDialog]);

  const handleKeywordChange: NonNullable<
    ComponentProps<typeof TextField>['onChange']
  > = useCallback((e) => {
    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 1000 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      keyword: e.target.value,
    }));
  }, []);

  const handleStatusChange: NonNullable<
    ComponentProps<typeof Select<string>>['onChange']
  > = useCallback((e) => {
    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 500 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      status: e.target.value as SupportedProductPublicationStatus | typeof ALL,
    }));
  }, []);

  const handleRowSelectionChange: ComponentProps<
    typeof ProductTable
  >['onRowSelectionChange'] = useCallback(
    (ids) => {
      setSelectionIds(ids);
      setSelectionCategoryName(
        ids.length
          ? tableRows.find((r) => ids.some((id) => id === r.id))
              ?.categoryName ?? ''
          : ''
      );
    },
    [setSelectionIds, setSelectionCategoryName, tableRows]
  );

  const handlePaginationModelChange: NonNullable<
    ComponentProps<typeof ProductTable>['onPaginationModelChange']
  > = useCallback((model) => {
    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 0 }; // NOTE:ページ変更なので即時検索
    setFilter((prevState) => ({
      ...prevState,
      page: model.page,
      pageSize: model.pageSize,
    }));
  }, []);

  const handleRowClick: ComponentProps<typeof ProductTable>['onRowClick'] =
    useCallback(
      async (row) => {
        navigate('view', {
          state: { id: row.id } as ProductDetailState,
        });
      },
      [navigate]
    );

  const handleRowClickCopy: ComponentProps<
    typeof ProductTable
  >['onRowClickCopy'] = useCallback(
    (row: ProductTableRow) => {
      navigate('create', {
        state: { id: row.id, mode: 'copy' } as ProductCreationState,
      });
    },
    [navigate]
  );

  const handleRowClickDelete: ComponentProps<
    typeof ProductTable
  >['onRowClickDelete'] = useCallback(
    (row: ProductTableRow) => {
      setClickID(row.id);
      setShouldShowDeleteDialog((current) => (current ? current : true));
    },
    [setClickID, setShouldShowDeleteDialog]
  );

  const handleCloseDeleteDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      const ids = clickID ? [clickID] : selectionIds;
      if (!confirm) {
        setClickID('');
        setShouldShowDeleteDialog(false);
        return;
      }
      setIsDeleting(true);
      try {
        await deleteProducts(organizationState.id, ids);
        setSnackbarText(`${ids.join('、')}のプランを削除しました。`);
        setSnackbarState('info');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarText(
          `プランの削除に失敗しました。, ${
            isError(error) ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setClickID('');
        setSelectionIds([]);
        setIsDeleting(false);
        setShouldShowDeleteDialog(false);
        await loadProduct();
      }
    },
    [
      loadProduct,
      clickID,
      selectionIds,
      setClickID,
      setSelectionIds,
      setSnackbarOpen,
      setSnackbarState,
      setSnackbarText,
      organizationState.id,
    ]
  );

  useEffect(() => {
    void (async () => {
      await loadCategories();
      await loadAttributes();
    })();
  }, [loadCategories, loadAttributes]);

  useEffect(() => {
    // NOTE:無駄な検索防止に一定時間検索待機
    clearTimeout(loadProductTimerRef.current.timeoutId);
    const timeoutId = setTimeout(() => {
      void loadProduct();
    }, loadProductTimerRef.current.ms);
    loadProductTimerRef.current = { ms: 0, timeoutId };
  }, [loadProduct]);

  return (
    <>
      <Box sx={{ mb: 3, mt: 1 }}>
        <Box alignItems="baseline" display="flex" gap={5}>
          <PageTitle title="掲載管理" data-e2e="product-title" />
          <Box
            alignItems="baseline"
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={{ mr: 1 }}>期間</Typography>
            <MonthSelect
              value={dateFnsFormat(new Date(filter.startPeriod), 'yyyy/MM/dd')}
              startMonth={dateFnsFormat(
                subMonths(new Date(), 36),
                'yyyy/MM/dd'
              )}
              monthCount={72}
              onChange={handleStartPeriodChange}
            />
            <Typography sx={{ pl: 1, pr: 1 }}>〜</Typography>
            <MonthSelect
              value={dateFnsFormat(new Date(filter.endPeriod), 'yyyy/MM/dd')}
              startMonth={dateFnsFormat(
                subMonths(new Date(), 36),
                'yyyy/MM/dd'
              )}
              monthCount={72}
              onChange={handleEndPeriodChange}
            />
          </Box>
          <Button
            sx={{ minWidth: 160, ml: 'auto' }}
            color="primary"
            variant="contained"
            size="small"
            onClick={handleCreateButtonClick}
          >
            新規登録
          </Button>
        </Box>
      </Box>
      <Paper sx={{ minHeight: 660, pb: 0, pt: 1 }}>
        {selectionIds.length > 0 && (
          <Toolbar
            sx={{
              columnGap: 1,
              flexWrap: 'wrap',
              px: '1rem !important',
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '8rem' }}
              onClick={handleBulkCancelButtonClick}
            >
              キャンセル
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ marginLeft: 'auto', width: '8rem' }}
              onClick={handleBulkEditButtonClick}
            >
              一括編集
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{
                color: theme.customPalette.alert,
                width: '8rem',
              }}
              onClick={handleBulkDeleteButtonClick}
            >
              一括削除
            </Button>
          </Toolbar>
        )}
        <Toolbar
          sx={{
            columnGap: 3,
            flexWrap: 'wrap',
            mb: 1,
            px: '1rem !important',
            rowGap: 1,
          }}
        >
          <TextField
            sx={{ minWidth: '15rem' }}
            size="small"
            placeholder="キーワードで検索"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleKeywordChange}
            data-e2e="product-keyword-text"
          />
          <Box
            alignItems="center"
            display="flex"
            gap={1}
            minWidth="fit-content"
          >
            <Typography>ステータス</Typography>
            <Select
              sx={{ textAlign: 'left', width: '8rem' }}
              size="small"
              value={filter.status}
              onChange={handleStatusChange}
              data-e2e="product-status-select"
            >
              <MenuItem value={ALL}>すべて</MenuItem>
              {getMenuItems(productStatusList)}
            </Select>
          </Box>
        </Toolbar>
        <ProductTable
          rows={tableRows}
          rowCount={totalRowCount}
          loading={isLoadingTable}
          onRowClick={handleRowClick}
          onRowClickCopy={handleRowClickCopy}
          onRowClickDelete={handleRowClickDelete}
          rowSelectionIds={selectionIds}
          onRowSelectionChange={handleRowSelectionChange}
          paginationModel={filter}
          onPaginationModelChange={handlePaginationModelChange}
          selectionCategoryName={selectionCategoryName}
        />
      </Paper>
      <ConfirmDialog
        title={`${
          clickID ? clickID : selectionIds.join('、')
        }のプランを本当に削除しますか？`}
        okButtonText="削除"
        okButtonLoading={isDeleting}
        open={shouldShowDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </>
  );
}
