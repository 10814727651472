import { AxiosPromise } from 'axios';
import { format as dateFnsFormat } from 'date-fns';
import _ from 'lodash';

import {
  Order,
  OrderResponse,
  OrderStatus,
  OrderStats,
  SupportedOrderStatus,
  OrderLocalized,
} from '../types/order';
import { filterSyntaxGen } from '../utils';

import { ORDER_SERVICE, axiosInstance } from './axios';

export function getOrderList(
  organizationId: string
): AxiosPromise<OrderResponse> {
  return axiosInstance.get(`${ORDER_SERVICE}/orgs/${organizationId}/orders`);
}

export function getOrdersListWithFilter(
  organizationId?: string,
  options?: {
    categoryIds?: string[];
    /** プランの来店日で範囲検索 */
    dateRange?: {
      end: string;
      isEmpty?: boolean;
      start: string;
    };
    ids?: string[];
    keyword?: string;
    order?: 'createdAt' | string;
    organizationExpand?: boolean;
    pageNumber?: number | 0;
    pageSize?: number | 10;
    productId?: string;
    statuses?: string[];
    userIds?: string[];
  }
): AxiosPromise<OrderResponse> {
  const expandParam = [];
  const filterParam = [];
  const urlParams = [['$orderBy', String(options?.order)]];

  if (
    !_.isUndefined(
      _.get(options, 'pageSize', '') &&
        !_.isUndefined(_.get(options, 'pageNumber', ''))
    )
  ) {
    urlParams.push([`$top`, String(_.get(options, 'pageSize', 10))]);
    urlParams.push([
      `$skip`,
      String(_.get(options, 'pageNumber', 0) * _.get(options, 'pageSize', 10)),
    ]);
  }

  if (!_.isEmpty(_.get(options, 'statuses', []))) {
    filterParam.push(
      `status in ${filterSyntaxGen(_.get(options, 'statuses', []))}`
    );
  }

  if (options?.ids) {
    filterParam.push(`id in ${filterSyntaxGen(options?.ids)}`);
  }

  if (options?.productId) {
    filterParam.push(`lineItems.productId eq '${options.productId}'`);
  }

  if (options?.userIds) {
    filterParam.push(`customer.userId in ${filterSyntaxGen(options?.userIds)}`);
  }

  if (options?.dateRange?.start && options?.dateRange?.end) {
    const param = `(customFields.productDay ge '${dateFnsFormat(
      new Date(options.dateRange.start),
      'yyyy-MM-dd'
    )}' and customFields.productDay le '${dateFnsFormat(
      new Date(options.dateRange.end),
      'yyyy-MM-dd'
    )}')`;
    if (options?.dateRange.isEmpty) {
      filterParam.push(`(${param} or customFields.productDay eq '')`);
    } else {
      filterParam.push(param);
    }
  }

  if (!_.isEmpty(_.get(options, 'categoryIds', []))) {
    expandParam.push(
      `products($filter=category.id in ${filterSyntaxGen(
        _.get(options, 'categoryIds', [])
      )})`
    );
  }
  if (options?.organizationExpand) {
    // expand organization always because its used by card
    expandParam.push('organization');
  }

  if (!_.isEmpty(_.get(options, 'keyword', ''))) {
    const keyword = _.get(options, 'keyword', '');

    const keywordFilters = [
      `lineItems.productName co '${keyword}'`,
      `lineItems.productId co '${keyword}'`,
      `lineItems.product co '${keyword}'`,
      `lineItems.product.id co '${keyword}'`,
      `lineItems.product.name co '${keyword}'`,
      `lineItems.product.categoryId co '${keyword}'`,
      `lineItems.product.description co '${keyword}'`,
      `lineItems.product.customFields.days co '${keyword}'`,
      `lineItems.product.customFields.startTime co '${keyword}'`,
      `lineItems.product.customFields.endTime co '${keyword}'`,
      `lineItems.product.customFields.orderConditions co '${keyword}'`,
      `lineItems.product.customFields.selection co '${keyword}'`,
      `lineItems.product.customFields.workLocation co '${keyword}'`,
      `lineItems.product.customFields.workPostalCode co '${keyword}'`,
      `lineItems.product.customFields.workPrefecture co '${keyword}'`,
      `lineItems.product.customFields.workCity co '${keyword}'`,
      `lineItems.product.customFields.workAddress1 co '${keyword}'`,
      `lineItems.product.customFields.workAddress2 co '${keyword}'`,
      `lineItems.product.customFields.access co '${keyword}'`,
      `customer.name co '${keyword}'`,
      `customer.phone co '${keyword}'`,
      `customer.addressLine1 co '${keyword}'`,
      `customer.addressLine2 co '${keyword}'`,
      `customer.addressLine3 co '${keyword}'`,
      `customer.addressLine4 co '${keyword}'`,
      `customer.nameKana co '${keyword}'`,
      `customer.userId co '${keyword}'`,
      `customer.user.id co '${keyword}'`,
      `customer.user.email co '${keyword}'`,
      `customer.user.gender co '${keyword}'`,
      `customer.user.genderSelf co '${keyword}'`,
      `customer.user.birthday co '${keyword}'`,
      `customer.user.clinicalDepartments co '${keyword}'`,
      `customer.user.firstClinicalDepartment co '${keyword}'`,
      `customer.user.medicalLicenseYear co '${keyword}'`,
      `customer.user.medicalRegisterNumber co '${keyword}'`,
      `customer.user.universityName co '${keyword}'`,
      `customer.user.currentHospital co '${keyword}'`,
      `customer.user.currentDepartment co '${keyword}'`,
      `customer.user.confidences co '${keyword}'`,
    ];
    filterParam.push(`(${keywordFilters.join(' or ')})`);
  }

  //NOTE: appends $expand
  expandParam.push('customer.user');
  if (expandParam.length > 0) {
    urlParams.push(['$expand', expandParam.join(',')]);
  }
  //NOTE: appends $filter
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (!organizationId) {
    const finalUrl = `${ORDER_SERVICE}/orders?${new URLSearchParams(
      urlParams
    ).toString()}`;
    return axiosInstance.get(finalUrl);
  }

  const finalUrl = `${ORDER_SERVICE}/orgs/${organizationId}/orders?${new URLSearchParams(
    urlParams
  ).toString()}`;
  return axiosInstance.get(finalUrl);
}

export async function getDateFilter(
  organizationId: string,
  options: {
    dateRange: {
      end: string;
      start: string;
    };
  }
): Promise<OrderLocalized[] | void> {
  const { dateRange } = options;
  const urlParams: [string, string][] = [];

  if (dateRange && dateRange.start && dateRange.end) {
    urlParams.push([
      '$filter',
      `(lineItems.product.customFields.days ge '${dateRange.start}' and lineItems.product.customFields.days le '${dateRange.end}')`,
    ]);
  }

  urlParams.push(['$expand', 'lineItems.product']);

  const url = organizationId
    ? `${ORDER_SERVICE}/orgs/${organizationId}/orders`
    : `${ORDER_SERVICE}/orders`;
  const finalUrl = `${url}?${new URLSearchParams(urlParams).toString()}`;

  const response = await axiosInstance.get(finalUrl);
  console.log('API Response:', response.data);
  if (response.data && Array.isArray(response.data.value)) {
    return response.data.value;
  }
  throw new Error('Received data is not an array');
}

export function getSingleOrder(
  organizationId: string,
  orderId: string
): AxiosPromise<Order> {
  const urlParams: string[][] = [];
  urlParams.push(['$expand', 'customer.user']);
  return axiosInstance
    .get(
      `${ORDER_SERVICE}/orgs/${organizationId}/orders/${orderId}?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteOrder(
  organizationId: string,
  selectedId: string
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${selectedId}`, {
      status: OrderStatus.CANCELED,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function restoreOrder(
  organizationId: string,
  selectedId: string
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${selectedId}`, {
      status: OrderStatus.PENDING,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateOrderStatus(
  organizationId: string,
  selectedId: string,
  status: SupportedOrderStatus
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${selectedId}`, {
      status,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function batchDeleteOrder(
  organizationId: string,
  selectedId: string[]
) {
  const chunks = _.chunk(selectedId, 10);
  const deletedIds: string[] = [];
  return Promise.all(
    chunks.map(async (chunk) => {
      //TODO: Confirm solution with other Senior Engineers
      return Promise.all(
        chunk.map((id) => {
          return deleteOrder(organizationId, id).then(() => {
            deletedIds.push(id);
          });
        })
      );
    })
  )
    .then(() => {
      return deletedIds;
    })
    .catch((error) => {
      return deletedIds;
    });
}

export async function batchRestoreOrder(
  organizationId: string,
  selectedId: string[]
) {
  const chunks = _.chunk(selectedId, 10);
  const restoredIds: string[] = [];

  return Promise.all(
    chunks.map((chunk) => {
      //TODO: Confirm solution with other Senior Engineers
      return Promise.all(
        chunk.map((id) => {
          return restoreOrder(organizationId, id).then(() => {
            restoredIds.push(id);
          });
        })
      );
    })
  )
    .then(() => {
      return restoredIds;
    })
    .catch((error) => {
      return restoredIds;
    });
}

export async function getOrderStats(
  organizationId: string,
  productIds: string[]
): Promise<AxiosPromise<OrderStats[]>> {
  const params = new URLSearchParams();

  if (productIds && productIds.length > 0) {
    params.append('productIds', productIds.join(','));
  }

  return await axiosInstance.get(
    `${ORDER_SERVICE}/orgs/${organizationId}/products/stats/orders?${params}`
  );
}
