import { ContentCopy, DeleteOutline } from '@mui/icons-material';
import { Box, Button, useTheme, Checkbox, Radio } from '@mui/material';
import { DataGrid, GridColDef, DataGridProps } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';

import { SupportedProductPublicationStatus } from '../../types/catalog';
import { formatDates, formatTime } from '../../utils/format';

import { updateOrganizationInfo } from '@app/adapter/organization-service';
import { organization } from '@app/domain/organization';
import { unescapeAndConvertNewLines, unescapeHtml } from '@app/utils/pattern';

export interface ProductTableProps {
  loading: boolean;
  onPaginationModelChange: DataGridProps['onPaginationModelChange'];
  onRowClick: (row: ProductTableRow) => void | Promise<void>;
  onRowClickCopy?: null | ((row: ProductTableRow) => void | Promise<void>);
  onRowClickDelete?: null | ((row: ProductTableRow) => void | Promise<void>);
  onRowSelectionChange: (ids: string[]) => void | Promise<void>;
  paginationModel: DataGridProps['paginationModel'];
  rowCount: number;
  rowSelectionIds: string[];
  rows: ProductTableRow[];
  selectionCategoryName: string;
}

export interface ProductTableAttribute {
  id: string;
  name: string;
}

export interface ProductTableRow {
  attributesJob?: ProductTableAttribute[];
  calculatedDairyPay?: number;
  categoryName?: string;
  days?: string[];
  description?: string;
  endTime?: string;
  id: string;
  inquiry?: number;
  name?: string;
  price: string;
  publicationSince?: string;
  publicationUntil?: string;
  scheduleType?: string;
  searchConditionWeek?: number[];
  startTime?: string;
  status?: SupportedProductPublicationStatus;
  tags?: string[];
}

export function ProductTable({
  rows,
  rowCount,
  loading,
  rowSelectionIds,
  onRowClick,
  onRowClickCopy,
  onRowClickDelete,
  onRowSelectionChange,
  paginationModel,
  onPaginationModelChange,
  selectionCategoryName,
}: ProductTableProps) {
  const [organizationState, setOrganizationState] =
    useRecoilState(organization);

  const handleBookmark = async (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: string
  ): Promise<void> => {
    const organizationId = organizationState.id;
    try {
      await updateOrganizationInfo(organizationId, {
        customFields: { bookMarkedPlan: rowId },
      });
      setOrganizationState((prevState) => ({
        ...prevState,
        customFields: { ...prevState.customFields, bookMarkedPlan: rowId },
      }));
    } catch (error) {
      console.error('ブックマークの更新に失敗しました:', error);
    }
  };

  const isSelectionMode = !!rowSelectionIds.length;

  const columns: GridColDef<ProductTableRow>[] = [
    {
      field: 'check',
      headerAlign: 'center',
      headerName: '',
      renderCell: (params) => (
        <Checkbox
          checked={rowSelectionIds.some((v) => v === params.row.id.toString())}
          disabled={
            (isSelectionMode &&
              selectionCategoryName !== params.row.categoryName) ||
            !!params.row.inquiry
          }
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            const newSelectionIds = !rowSelectionIds.some(
              (v) => v === params.row.id.toString()
            )
              ? [...rowSelectionIds, params.row.id.toString()].sort()
              : rowSelectionIds.filter((v) => v !== params.row.id.toString());
            await onRowSelectionChange(newSelectionIds);
          }}
        />
      ),
      width: 50,
    },
    {
      field: 'id',
      headerAlign: 'center',
      headerName: 'プランID/プラン名',
      renderCell: (params) => (
        <Box whiteSpace="pre-wrap">
          <Box fontSize="0.8rem" color={theme.palette.grey[500]}>
            {params.row.id}
          </Box>
          <Box sx={{ WebkitLineClamp: 2 }}>
            {unescapeHtml(params.row.name ?? '')}
          </Box>
        </Box>
      ),
      renderHeader: () => (
        <Box textAlign="left" lineHeight="1rem">
          <Box>プランID/プラン名</Box>
        </Box>
      ),
      width: 190,
    },
    {
      field: 'dateTime',
      headerAlign: 'center',
      headerName: '曜日・時間',
      renderCell: (params) => {
        return (
          <Box
            whiteSpace="pre-wrap"
            sx={{
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.row.scheduleType === 'day' ? (
              <Box>{params.row.days && formatDates(params.row.days)}</Box>
            ) : (
              <Box>
                {!!params.row.searchConditionWeek?.length && (
                  <Box display="inline-block">
                    {params.row.searchConditionWeek
                      .map((w) => {
                        const week = [
                          '日曜',
                          '月曜',
                          '火曜',
                          '水曜',
                          '木曜',
                          '金曜',
                          '土曜',
                        ];
                        return week[w];
                      })
                      .join('・') + ' '}
                  </Box>
                )}
                <Box display="inline-block">
                  {formatTime(params.row.startTime)}〜
                  {formatTime(params.row.endTime)}
                </Box>
              </Box>
            )}
          </Box>
        );
      },
      valueGetter: (params) => {
        return `${formatDates(params.row.days)} ${
          params.row.searchConditionWeek?.join().padStart(7, '0') ?? '0000000'
        } ${formatTime(params.row.startTime)} ${formatTime(
          params.row.endTime
        )}`;
      }, // NOTE:ソート用に設定
      width: 147,
    },
    {
      field: 'price',
      headerAlign: 'center',
      headerName: '金額',
      renderCell: (params) => (
        <Box whiteSpace="pre-wrap" width="100%">
          <Box>
            {params.row.price != null
              ? unescapeHtml(`${params.row.price}円/人`)
              : unescapeHtml('-円/人')}
          </Box>
        </Box>
      ),
      renderHeader: () => (
        <Box textAlign="left" lineHeight="1rem">
          <Box>金額</Box>
        </Box>
      ),
    },
    {
      field: 'detail',
      headerAlign: 'center',
      headerName: 'プラン内容',
      renderCell: (params) => (
        <Box
          whiteSpace="normal"
          width="100%"
          sx={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Box>{unescapeAndConvertNewLines(params.row.description)}</Box>
        </Box>
      ),
      renderHeader: () => (
        <Box textAlign="left" lineHeight="1rem">
          <Box>プラン内容</Box>
        </Box>
      ),
      width: 200,
    },
    {
      field: 'status',
      headerAlign: 'center',
      headerName: 'ステータス',
      renderCell: (params) => (
        <Box textAlign="center" width="100%">
          {params.row.status === 'ACTIVE' ? (
            <Box color={theme.customPalette.orange}>掲載中</Box>
          ) : params.row.status === 'ARCHIVED' ? (
            <Box color={theme.customPalette.alert}>終了</Box>
          ) : params.row.status === 'DRAFT' ? (
            <Box>下書き</Box>
          ) : undefined}
        </Box>
      ),
      width: 110,
    },
    {
      field: 'inquiry',
      headerAlign: 'center',
      headerName: '応募',
      renderCell: (params) => (
        <Box textAlign="center" width="100%">
          {params.row.inquiry}件
        </Box>
      ),
      width: 110,
    },
    {
      field: 'bookmark',
      headerAlign: 'center',
      headerName: 'トップ表示プラン',
      renderCell: (params) => {
        const isBookmarked =
          organizationState.customFields.bookMarkedPlan === params.row.id;
        const isArchivedOrDraft =
          params.row.status === 'ARCHIVED' || params.row.status === 'DRAFT';
        return (
          <Box textAlign="center" width="100%">
            <Radio
              checked={isBookmarked}
              disabled={isArchivedOrDraft}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event) => handleBookmark(event, params.row.id)}
              size="small"
              color="primary"
            />
          </Box>
        );
      },
      width: 130,
    },
    {
      field: 'action',
      headerAlign: 'center',
      headerName: '',
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" width="100%" gap={1}>
          {onRowClickCopy && (
            <Button
              title="複製"
              variant="outlined"
              color="secondary"
              size="small"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await onRowClickCopy?.(params.row);
              }}
              disabled={isSelectionMode}
            >
              <ContentCopy color="action" />
            </Button>
          )}
          {onRowClickDelete && (
            <Button
              title="削除"
              variant="outlined"
              color="secondary"
              size="small"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await onRowClickDelete?.(params.row);
              }}
              disabled={isSelectionMode || !!params.row.inquiry}
            >
              <DeleteOutline
                color={
                  isSelectionMode || !!params.row.inquiry
                    ? 'disabled'
                    : 'action'
                }
              />
            </Button>
          )}
        </Box>
      ),
      sortable: false,
      width: 120,
    },
  ];

  const theme = useTheme();

  return (
    <DataGrid
      sx={{
        '& .row-select': {
          background: theme.customPalette.selection,
        },
        ',MuiDataGrid-columnHeaderTitleContainer': {
          py: 0,
        },
        '.MuiDataGrid-cell': {
          ':focus-within': { outline: 'none' },
          cursor: isSelectionMode ? undefined : 'pointer',
          p: 0.5,
        },
        '.MuiDataGrid-columnHeader': {
          ':focus-within': { outline: 'none' },
          px: 0.5,
        },
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: theme.palette.grey[200],
          borderRadius: 0,
        },
        border: 'none',
      }}
      columnHeaderHeight={45}
      disableColumnMenu
      autoHeight
      getRowHeight={() => 'auto'}
      pagination
      pageSizeOptions={[10, 20, 50]}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      paginationMode="server"
      rowCount={rowCount}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      localeText={{ noRowsLabel: 'データがありません' }}
      slotProps={{
        pagination: {
          labelRowsPerPage: (
            <label style={{ fontSize: '0.8rem' }}>1ページの表示数</label>
          ),
          showFirstButton: true,
          showLastButton: true,
        },
      }}
      getRowClassName={(params) => {
        if (rowSelectionIds.some((v) => v === params.row.id.toString())) {
          return 'row-select';
        }
        return '';
      }}
      columns={columns}
      rows={rows}
      loading={loading}
      onRowClick={(params) => {
        if (isSelectionMode) {
          return;
        }
        void onRowClick?.(params.row);
      }}
    />
  );
}
