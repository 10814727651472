import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Typography,
  FormHelperText,
  Select,
  Divider,
  useTheme,
  styled,
  CircularProgress,
  Grid,
} from '@mui/material';
import { format as dateFnsFormat, addDays } from 'date-fns';
import {
  useCallback,
  useEffect,
  useState,
  ReactNode,
  ComponentProps,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { z } from 'zod';

import {
  getSingleProduct,
  updateProduct,
  deleteProduct,
} from '../../adapter/catalog-service';
import {
  snackbarOpenState,
  snackbarTextState,
  snackbarSeverityState,
} from '../../domain/app';
import {
  Product,
  ProductUpdate,
  ScheduleType,
  SupportedProductPublicationStatus,
} from '../../types/catalog';
import { getMenuItems } from '../../utils/components';
import {
  productStatusList,
  productEndPeriodStatusList,
  scheduleTypeList,
} from '../../utils/constants';
import { ConfirmDialog } from '../Shared/ConfirmDialog';
import { DateField } from '../Shared/DateField';
import { NumberField } from '../Shared/NumberField';

import { ProductDateSetting } from './ProductDateSetting';
import { ProductDetailState } from './ProductDetail';
import { ProductDetailComponent } from './ProductDetailComponent';
import { ProductLayout } from './ProductLayout';
import { ProductRepeatWeek } from './ProductRepeatWeek';

import { useNavigateAdminDelegatable as useNavigate } from '@app/administrator/utils/useNavigateAdminDelegatable';
import { orderListStateAtom } from '@app/domain/order';
import {
  useAttributesClinicalDepartment,
  useAttributesJobType,
} from '@app/hooks/catalog';
import { filterRecentDates, removeDuplicates } from '@app/utils/catalog';
import { formatDatesForRepeatWeek } from '@app/utils/format';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function ItemLabel(props: {
  message?: ReactNode;
  required?: boolean;
  title: string;
}) {
  const theme = useTheme();
  return (
    <RowBox alignItems="center" gap={2}>
      <RowBox alignItems="center" gap={0.5}>
        <Typography>{props.title}</Typography>
        {props.required && (
          <Typography color={theme.customPalette.alert}>*</Typography>
        )}
      </RowBox>
      {props.message}
    </RowBox>
  );
}

function FormControlItem(props: {
  children: ReactNode;
  message?: ReactNode;
  required?: boolean;
  title: string;
}) {
  return (
    <ColBox gap={1}>
      <ItemLabel
        title={props.title}
        required={props.required}
        message={props.message}
      />
      <ColBox>{props.children}</ColBox>
    </ColBox>
  );
}

export const productFormSchema = z.object({
  days: z.string().array().optional(),
  endPeriod: z.string().refine((d) => {
    return true;
  }),
  endPeriodDayCount: z.number().nullable(),
  endPeriodStatus: z.enum(['prev', 'daysAgo', 'day']),
  repeatWeek: z.number().array(),
  scheduleType: z.enum(['day', 'week']),
  startPeriod: z.string().nonempty('開始日を入力してください'),
  status: z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT']),
});

export type ProductForm = z.infer<typeof productFormSchema>;

export interface ProductEditStatusState {
  id?: string;
}

export function ProductEditStatus() {
  const navigate = useNavigate();
  const location = useLocation();
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const orders = useRecoilValue(orderListStateAtom);
  const attributeClinicalDepartmentList = useAttributesClinicalDepartment();
  const attributeJobTypeList = useAttributesJobType();
  // データ読み込み済みかどうかの状態
  const [isLoadData, setIsLoadData] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);
  const theme = useTheme();

  const [, setScheduleType] = useState<'day' | 'week'>('day');

  const productState = location.state as ProductEditStatusState | null;

  const { control, handleSubmit, reset, watch } = useForm<ProductForm>({
    defaultValues: {
      days: [],
      endPeriod: '',
      endPeriodDayCount: null,
      repeatWeek: [],
      scheduleType: 'day',
      startPeriod: '',
      status: 'ACTIVE',
    },
    mode: 'onChange',
    resolver: zodResolver(productFormSchema),
  });

  /** 削除ボタンクリック処理 */
  const handleDeleteClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(async () => {
    setShouldShowDeleteDialog(() => true);
  }, []);

  /** プレビューボタンクリック処理 */
  const handleClickPreview: Parameters<typeof handleSubmit>[0] =
    useCallback(() => {
      console.log('');
    }, []);

  /** APIに渡すためのプランデータに変換 */
  const convertProduct = useCallback(
    (
      formValue: ProductForm,
      status: SupportedProductPublicationStatus,
      days?: string[]
    ): ProductUpdate => {
      const startPeriod = new Date(formValue.startPeriod);

      // 掲載終了日の指定
      let endPeriod;
      if (formValue.scheduleType === 'week') {
        endPeriod = new Date(formValue.endPeriod);
      } else if (formValue.endPeriodStatus === 'prev' && days) {
        endPeriod = addDays(new Date(days[days.length - 1]), -1);
      } else if (formValue.endPeriodStatus === 'daysAgo' && days) {
        endPeriod = addDays(
          new Date(days[days.length - 1]),
          (formValue.endPeriodDayCount ?? 0) * -1
        );
      } else if (formValue.endPeriod) {
        endPeriod = new Date(formValue.endPeriod);
      } else {
        endPeriod = undefined;
      }

      // MEMO:予約済みの日付がdaysに存在しない場合は自動追加
      orders.forEach((order) => {
        const appointmentDate = order.customFields?.appointmentDate;
        if (appointmentDate && days && !days.includes(appointmentDate)) {
          days.push(appointmentDate);
        }
      });

      const uniqueDays = removeDuplicates(days);

      const filteredDays = uniqueDays ? filterRecentDates(uniqueDays) : [];
      // scheduleType === 'day'の場合はそのまま日付を使用。'week'の場合はformValueとformValue.endPeriodの日付から該当日付を計算して格納。
      let selectedDays: string[] = [];
      if (formValue.scheduleType === 'week' && endPeriod) {
        selectedDays = formatDatesForRepeatWeek(
          startPeriod,
          formValue.endPeriod,
          formValue.repeatWeek
        );
      } else if (filteredDays.length > 0) {
        selectedDays = filteredDays.map((day) =>
          dateFnsFormat(new Date(day), 'yyyy-MM-dd')
        );
      }

      // days 配列を使用して repeatWeek 配列を生成
      const repeatWeek =
        days && days.length > 0
          ? Array.from(new Set(days.map((day) => new Date(day).getDay())))
          : [];
      const data: ProductUpdate = {
        customFields: {
          days: selectedDays,
          repeatWeek:
            formValue.scheduleType === 'day'
              ? repeatWeek
              : formValue.repeatWeek,
          scheduleType:
            formValue.scheduleType === 'day'
              ? ScheduleType.DAY
              : ScheduleType.WEEK,
        },
        publication: {
          since: startPeriod.toISOString(),
          status,
          until:
            startPeriod.getTime() <= (endPeriod?.getTime() ?? 0)
              ? endPeriod?.toISOString() ?? ''
              : startPeriod.toISOString(), // NOTE：終了日の指定方法によっては開始日より前の日になる可能性があるため整合性をとるためその場合は開始日を設定
        },
      };
      return data;
    },
    [orders]
  );

  /** 登録ボタンクリック処理 */
  const handleClickRegister: Parameters<typeof handleSubmit>[0] = useCallback(
    async (formValue) => {
      if (!productState?.id) {
        return;
      }
      try {
        setIsLoadingRegister(true);

        const updateData = convertProduct(
          formValue,
          formValue.status,
          formValue.days
        );

        const result = await updateProduct(updateData, productState.id);
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        navigate('/product/view', {
          state: { id: productState.id } as ProductDetailState,
        });
      } catch (error) {
        setSnackbarText(
          `プランの更新に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsLoadingRegister(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSnackbarText, setSnackbarOpen, productState, navigate, product]
  );

  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleCloseDeleteDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm || !productState?.id) {
        setShouldShowDeleteDialog(false);
        return;
      }
      try {
        setIsDeleting(true);
        await deleteProduct(productState.id);
        setSnackbarText(`${productState.id}のプランを削除しました。`);
        setSnackbarState('info');
        setSnackbarOpen(true);
        navigate('/product');
      } catch (error) {
        setSnackbarText(
          `プランの削除に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsDeleting(false);
        setShouldShowDeleteDialog(false);
      }
    },
    [productState, navigate, setSnackbarOpen, setSnackbarState, setSnackbarText]
  );

  /** プランデータの読み込み */
  const loadProduct = useCallback(async () => {
    if (!productState?.id) {
      return;
    }
    try {
      const result = await getSingleProduct(productState.id);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const product = result.data;

      const uniqueDays = removeDuplicates(
        product.customFields?.days?.map((day) =>
          dateFnsFormat(new Date(day), 'yyyy/MM/dd')
        )
      );

      const formValues: ProductForm = {
        days: uniqueDays,
        endPeriod: product.publication?.until
          ? dateFnsFormat(new Date(product.publication?.until), 'yyyy/MM/dd')
          : '',
        endPeriodDayCount: null,
        endPeriodStatus: 'day',
        repeatWeek: product.customFields?.repeatWeek,
        scheduleType: product.customFields.scheduleType,
        startPeriod: product.publication?.since
          ? dateFnsFormat(new Date(product.publication?.since), 'yyyy/MM/dd')
          : '',
        status:
          (product.publication.status as ProductForm['status']) ?? 'ACTIVE',
      };
      reset(formValues);
      setProduct(product);
    } catch (error) {
      setSnackbarText(
        `プランの取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadData(true);
    }
  }, [reset, setSnackbarText, setSnackbarOpen, productState, setIsLoadData]);

  useEffect(() => {
    void loadProduct();
  }, [loadProduct]);

  // 編集、複製の場合、プランデータが読み込めるまで内容を表示しない
  if (!isLoadData) {
    return (
      <ProductLayout
        sx={{
          minWidth: 1000,
          px: 0,
        }}
      >
        <Typography variant="h5">{productState?.id}を編集</Typography>
        <CircularProgress sx={{ m: 'auto' }} />
      </ProductLayout>
    );
  }

  if (!product) {
    return (
      <ProductLayout
        sx={{
          minWidth: 1000,
          px: 0,
        }}
      >
        <Typography variant="h5">{productState?.id}を編集</Typography>
        <Typography sx={{ m: 'auto' }}>
          ID:{productState?.id}のプランを取得できませんでした
        </Typography>
      </ProductLayout>
    );
  }

  return (
    <form>
      <ProductLayout sx={{ minWidth: 1000, px: 0 }}>
        <RowBox alignItems="center" gap={1} px={2}>
          <Typography variant="h5">{productState?.id}を編集</Typography>

          <RowBox alignItems="center" gap={2} ml="auto">
            <Button
              variant="outlined"
              size="small"
              sx={{
                bgcolor: theme.palette.common.white,
                border: `solid 1px ${theme.customPalette.alert}`,
                color: theme.customPalette.alert,
                width: '5rem',
              }}
              onClick={handleDeleteClick}
              disabled={true}
            >
              削除
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{
                display: 'none', // TODO:プレビュー機能が実装されたら削除する
                width: '8rem',
              }}
              onClick={handleSubmit(handleClickPreview)}
            >
              プレビュー
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              sx={{ width: '8rem' }}
              onClick={handleSubmit(handleClickRegister)}
              loading={isLoadingRegister}
            >
              保存
            </LoadingButton>
          </RowBox>
        </RowBox>

        <Divider />
        <ColBox p={2}>
          <Grid item display="flex" gap={2}>
            <FormControlItem title="ステータス" required>
              <Controller
                name="status"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Select
                      {...field}
                      error={!!error}
                      sx={{
                        bgcolor: theme.palette.common.white,
                        width: '20rem',
                      }}
                      displayEmpty
                    >
                      {getMenuItems(productStatusList)}
                    </Select>
                    {error ? (
                      <FormHelperText error sx={{ ml: 2 }}>
                        {error.message}
                      </FormHelperText>
                    ) : undefined}
                  </>
                )}
              />
            </FormControlItem>

            <Grid item xs={6.5} display="flex" gap={2}>
              <FormControlItem title="掲載期間" required>
                <RowBox alignItems="baseline" gap={2}>
                  <RowBox alignItems="baseline" gap={1}>
                    <Controller
                      name="startPeriod"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateField
                          {...field}
                          ref={undefined}
                          inputRef={field.ref}
                          format="yyyy/MM/dd"
                          error={!!error}
                          sx={{ width: '10rem' }}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Typography>～</Typography>
                    {watch('scheduleType') === 'day' ? (
                      <Controller
                        name="endPeriodStatus"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            sx={{ width: '9rem' }}
                            displayEmpty
                          >
                            {getMenuItems(productEndPeriodStatusList)}
                          </Select>
                        )}
                      />
                    ) : (
                      <Select value={'day'} sx={{ width: '9rem' }} disabled>
                        {getMenuItems(productEndPeriodStatusList)}
                      </Select>
                    )}
                  </RowBox>
                  {(watch('scheduleType') === 'week' ||
                    watch('endPeriodStatus') === 'day') && (
                    <RowBox alignItems="baseline" gap={1}>
                      <Typography>終了日</Typography>
                      <Controller
                        name="endPeriod"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DateField
                            {...field}
                            ref={undefined}
                            inputRef={field.ref}
                            format="yyyy/MM/dd"
                            error={!!error}
                            sx={{ width: '10rem' }}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </RowBox>
                  )}
                  {watch('scheduleType') === 'day' &&
                    watch('endPeriodStatus') === 'daysAgo' && (
                      <RowBox alignItems="baseline" gap={1}>
                        <Typography>来店日</Typography>
                        <Controller
                          name="endPeriodDayCount"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <NumberField
                              {...field}
                              ref={undefined}
                              inputRef={field.ref}
                              error={!!error}
                              sx={{ width: '5rem' }}
                              helperText={error?.message}
                            />
                          )}
                        />
                        <Typography>日前</Typography>
                      </RowBox>
                    )}
                </RowBox>
              </FormControlItem>
            </Grid>
          </Grid>

          <Grid sx={{ mt: 2 }}>
            <FormControlItem title="プラン作成タイプ" required>
              <Controller
                name="scheduleType"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Select
                      {...field}
                      error={!!error}
                      sx={{ width: '20rem' }}
                      onChange={(e) => {
                        field.onChange(e);
                        setScheduleType(e.target.value as 'day' | 'week');
                      }}
                    >
                      {getMenuItems(scheduleTypeList)}
                    </Select>
                    {error ? (
                      <FormHelperText error sx={{ ml: 2 }}>
                        {error.message}
                      </FormHelperText>
                    ) : undefined}
                  </>
                )}
              />
            </FormControlItem>
          </Grid>

          <Grid sx={{ mt: 2 }}>
            {watch('scheduleType') === 'day' ? (
              <FormControlItem
                title="募集する来店日"
                required
                message={
                  <Typography color={theme.palette.grey[500]}>
                    半年先までのプランを一括で登録可能です。
                  </Typography>
                }
              >
                <Controller
                  name="days"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <ProductDateSetting
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      startDate={watch('startPeriod') || undefined}
                    />
                  )}
                />
              </FormControlItem>
            ) : (
              <FormControlItem title="募集曜日" required>
                <Controller
                  name="repeatWeek"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <ProductRepeatWeek
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControlItem>
            )}
          </Grid>
        </ColBox>

        <ColBox gap={2} pb={4} px={2}>
          <Divider />
          <RowBox alignItems="center" gap={0.5} mb={6}>
            <Typography color="red">*</Typography>
            <Typography>は必須事項です</Typography>
          </RowBox>
          <ProductDetailComponent
            product={product}
            attributeClinicalDepartmentList={attributeClinicalDepartmentList}
            attributeJobTypeList={attributeJobTypeList}
          />
        </ColBox>
      </ProductLayout>
      <ConfirmDialog
        title="本当に削除しますか？"
        okButtonText="削除"
        okButtonLoading={isDeleting}
        open={shouldShowDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </form>
  );
}
