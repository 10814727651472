import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography, Button, Grid } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ProgressStepper } from '@app/components/Registration/Confirmation/ProgressStepper';
import {
  userState,
  registerState,
  selectedPrefectureDataAtom,
  selectedCityDataAtom,
} from '@app/domain/register';
import {
  OrganizationFormData,
  UserInfoFormData,
} from '@app/schemas/registration';
import { unescapeHtml } from '@app/utils/pattern';

export interface CombinedFormData {
  organizationData: OrganizationFormData;
  userData: UserInfoFormData;
}

interface RegistrationFormProps {
  onBack: () => void;
  onSubmit: (formData: CombinedFormData) => void | Promise<void>;
}

export function InReview({
  onBack,
  onSubmit,
}: RegistrationFormProps): ReactElement {
  const userData = useRecoilValue(userState);
  const registerData = useRecoilValue(registerState);
  const selectedPrefectureData = useRecoilValue(selectedPrefectureDataAtom);
  const selectedCityData = useRecoilValue(selectedCityDataAtom);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  return (
    <Stack spacing={5}>
      <Grid>
        <Box>
          <ProgressStepper activeStep={2} />
          <Typography
            variant="h6"
            fontWeight={700}
            textAlign="center"
            sx={{ mb: 5 }}
          >
            ユーザー情報
          </Typography>
          <Typography
            variant="body2"
            textAlign="left"
            gutterBottom
            sx={{ mb: 5 }}
          >
            入力内容を確認の上、送信ボタンを押してください。
          </Typography>

          <Typography variant="body1" fontWeight={700}>
            お名前
          </Typography>
          <Box sx={{ display: 'flex', mb: 3, mt: 1 }}>
            <Typography>
              {userData.customFields.familyName}{' '}
              {userData.customFields.firstName}
            </Typography>
            <Typography sx={{ pl: 2 }}>
              {userData.customFields.familyNameKana}{' '}
              {userData.customFields.firstNameKana}
            </Typography>
          </Box>
        </Box>
        <Box>
          <ProgressStepper activeStep={2} />
          <Typography
            variant="h6"
            fontWeight={700}
            textAlign="center"
            sx={{ mb: 5 }}
          >
            店舗の登録
          </Typography>
          <Typography
            variant="body2"
            textAlign="left"
            gutterBottom
            sx={{ mb: 5 }}
          >
            入力内容を確認の上、送信ボタンを押してください。
          </Typography>

          <Typography variant="body1" fontWeight={700}>
            法人名
          </Typography>
          <Box sx={{ display: 'flex', mb: 3, mt: 1 }}>
            <Typography>{registerData.descriptionName}</Typography>
          </Box>
          <Typography variant="body1" fontWeight={700}>
            店舗名
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {unescapeHtml(registerData.name)}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            連絡先電話番号
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.phoneNumber}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            住所
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {selectedPrefectureData?.name}
            {selectedCityData?.name}
            {registerData.addressLine3}
            {unescapeHtml(registerData.customFields.addressLine4)}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            アクセス情報
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {unescapeHtml(registerData.customFields.access)}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            店舗のWebページ
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.customFields.clinicUrl}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            店長名
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.customFields.ownerName}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            ジャンル
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {registerData.customFields.genre.map((genre, index) => (
              <Typography key={index} sx={{ mb: 3, mr: 2, mt: 1 }}>
                {genre}
              </Typography>
            ))}
          </Box>
          <Typography variant="body1" fontWeight={700}>
            ジャンル（その他）
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {unescapeHtml(registerData.customFields.genreOther)}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            写真
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.iconImageUri && (
              <img
                src={registerData.iconImageUri}
                alt="Main shop view"
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            )}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            店舗の一言アピール(200文字まで)
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {unescapeHtml(registerData.customFields.clinicAppealMessage)}
          </Typography>
        </Box>
        <Grid item xs={12} sx={{ px: 3 }}>
          <Grid container item xs={12} sx={{ pt: 6 }} spacing={2}>
            <Grid item xs={6}>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                onClick={onBack}
              >
                戻る
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                onClick={async () => {
                  setIsButtonLoading(true);
                  await onSubmit({
                    organizationData: registerData,
                    userData,
                  });
                  setIsButtonLoading(false);
                }}
                loading={isButtonLoading}
              >
                送信
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}
