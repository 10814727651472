import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import { addMonths, format as dateFnsFormat } from 'date-fns';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  ComponentProps,
  useRef,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getOrdersListWithFilter } from '../adapter/order-service';
import {
  OrderItem,
  OrderTableComponent,
} from '../components/Orders/OrderTableComponent';
import { MonthSelect } from '../components/Shared/MonthSelect';
import { PageTitle } from '../components/Shared/PageTitle';
import {
  loggedInUserState,
  snackbarOpenState,
  snackbarTextState,
} from '../domain/app';
import { organizationSelector } from '../domain/organization';
import { convertOrderLocalized } from '../utils/order';

import { getUserFollowList } from '@app/adapter/user-service';
import { Order } from '@app/types/order';

export function Users() {
  const [allOrders, setAllOrders] = useState<OrderItem[]>([]);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [startMonth, setStartMonth] = useState(
    dateFnsFormat(new Date(), 'yyyy/MM')
  );
  const [endMonth, setEndMonth] = useState(
    dateFnsFormat(addMonths(new Date(), 1), 'yyyy/MM')
  );
  const [keywordFilter, setKeywordFilter] = useState('');
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [allCount, setAllCount] = useState(0);
  const [followUserIds, setFollowUserIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  });
  // データ取得に使用するタイマー（無駄な検索が走らないための対策用）
  const loadTimerRef = useRef({
    ms: 0,
    timeoutId: undefined as NodeJS.Timeout | undefined,
  });

  const loadFollowing = useCallback(async () => {
    if (!loggedInUser?.id) {
      return;
    }
    try {
      const result = await getUserFollowList(loggedInUser.id, 'user', {
        pageNumber: pagination.page,
        pageSize: pagination.pageSize,
      }); // TODO:APIが期間やキーワード検索可能になればそれに対応する
      setFollowUserIds(result.value.map((f) => f.followId));
      setAllCount(result.total);
    } catch (error) {
      setSnackbarText(
        `フォローの取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [loggedInUser, pagination, setSnackbarOpen, setSnackbarText]);

  const getOrders = useCallback(async () => {
    if (!followUserIds.length) {
      return;
    }
    try {
      setIsLoadingOrder(true);
      const orders = [] as Order[];
      // 300件を上限にフォローユーザーの応募を取得する
      for (let i = 0; i < 3; i++) {
        const userIds = followUserIds.filter(
          (id) => !orders.some((o) => o.customer.user.id === id)
        );
        if (!userIds.length) {
          break;
        }
        const result = await getOrdersListWithFilter(organizationState.id, {
          order: 'customFields.productDay desc',
          pageNumber: 0,
          pageSize: 100,
          userIds,
        });
        result.data.value.forEach((o) => orders.push(o));
      }
      const orderList = followUserIds.map((userId) => {
        const order = orders.find(
          (o) =>
            o.customer.user.id === userId &&
            ['ACCEPTED', 'CLOSED', 'PROCESSING'].includes(o.status)
        );
        if (order) {
          return convertOrderLocalized(order);
        }
        const order2 = orders.find((o) => o.customer.user.id === userId);
        if (order2) {
          return convertOrderLocalized(order2);
        }
        return { customer: { user: { id: userId } } } as OrderItem;
      });
      setAllOrders(orderList);
    } catch (error: unknown) {
      setSnackbarText(
        `応募の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadingOrder(false);
    }
  }, [followUserIds, organizationState, setSnackbarOpen, setSnackbarText]);

  useEffect(() => {
    // NOTE:無駄な検索防止に一定時間検索待機
    clearTimeout(loadTimerRef.current.timeoutId);
    const timeoutId = setTimeout(() => {
      void loadFollowing();
    }, loadTimerRef.current.ms);
    loadTimerRef.current = { ms: 0, timeoutId };
  }, [loadFollowing]);

  useEffect(() => {
    void getOrders();
  }, [getOrders]);

  const handleChangeKeywordFilter = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      loadTimerRef.current = { ...loadTimerRef.current, ms: 1000 }; //NOTE:無駄な検索防止に一定時間検索待機
      setKeywordFilter(target.value);
    },
    []
  );

  const handleStartDateChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadTimerRef.current = { ...loadTimerRef.current, ms: 500 }; //NOTE:無駄な検索防止に一定時間検索待機
    setStartMonth(value);
  }, []);

  const handleEndDateChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadTimerRef.current = { ...loadTimerRef.current, ms: 500 }; //NOTE:無駄な検索防止に一定時間検索待機
    setEndMonth(value);
  }, []);

  return (
    <>
      <Box sx={{ mb: 3, mt: 1 }}>
        <Box alignItems="baseline" display="flex" gap={5}>
          <PageTitle title="フォロー一覧" data-e2e="product-title" />
          <Box
            alignItems="baseline"
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={{ mr: 1 }}>期間</Typography>
            <MonthSelect value={startMonth} onChange={handleStartDateChange} />
            <Typography sx={{ pl: 1, pr: 1 }}>〜</Typography>
            <MonthSelect value={endMonth} onChange={handleEndDateChange} />
          </Box>
        </Box>
      </Box>

      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={0}>
                <Tab label="フォロー中" value={0} />
              </Tabs>
            </Box>
          </Grid>
        </Grid>

        <Toolbar sx={{ px: '1rem !important' }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                placeholder="キーワード検索"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={keywordFilter}
                onChange={handleChangeKeywordFilter}
              />
            </Grid>
          </Grid>
        </Toolbar>

        <OrderTableComponent
          mode="user"
          orders={allOrders}
          rowCount={allCount}
          paginationModel={pagination}
          onPaginationModelChange={setPagination}
          loading={isLoadingOrder}
        />
      </Paper>
    </>
  );
}
