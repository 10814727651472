import { format as dateFnsFormat } from 'date-fns';
import dateFnsJa from 'date-fns/locale/ja';
import { atom, selector } from 'recoil';

import {
  Order,
  OrderLocalized,
  OrderResponse,
  OrderStats,
} from '../types/order';
import { localizedOrderStatus } from '../utils/constants';
import { convertOrderLocalized } from '../utils/order';

export const orderStats = atom<OrderStats[]>({
  default: [
    {
      count: 0,
      organization: '',
      productId: '',
    },
  ],
  key: 'orderStats',
});

export const orderState = atom<OrderResponse>({
  default: {
    '@nextLink': '',
    count: 0,
    total: 0,
    value: [],
  },
  key: 'orderState',
});

export const orderList = selector<OrderLocalized[]>({
  get: ({ get }) => {
    const list = get(orderState);
    return list.value.map((item) => convertOrderLocalized(item));
  },
  key: 'orderList',
});

export const selectedOrder = atom<OrderLocalized>({
  key: 'selectedOrder',
});

export const deletedOrders = atom<Order[]>({
  default: [],
  key: 'deletedOrders',
});

export const deletedOrdersLocalized = selector<OrderLocalized[]>({
  get: ({ get }) => {
    const list = get(orderState);
    return list.value.map((item) => ({
      cancelReason: item.cancelReason || '',
      closedAt: item.closedAt,
      createdAt: dateFnsFormat(
        new Date(item.createdAt),
        'yyyy年M月d日(E) HH:mm',
        { locale: dateFnsJa }
      ),
      currency: item.currency,
      customer: item.customer,
      gender: item.gender,
      id: item.id,
      lineItem: item.lineItems[0],
      localizedStatus: localizedOrderStatus[item.status],
      name: item.name,
      organization: item.organization,
      status: item.status,
      subTotalPrice: item.subTotalPrice,
      totalTax: item.totalTax,
    }));
  },
  key: 'deletedOrdersLocalized',
});

export const tabIndexState = atom({
  default: 0,
  key: 'tabIndexState',
});

export const calculateStatusCounts = selector({
  get: ({ get }) => {
    const orders = get(orderListStateAtom);
    let pendingCount = 0;
    let acceptedCount = 0;
    let processingCount = 0;

    orders.forEach((order) => {
      switch (order.status) {
        case 'PENDING':
          pendingCount++;
          break;
        case 'ACCEPTED':
          acceptedCount++;
          break;
        case 'PROCESSING':
          processingCount++;
          break;
        default:
          break;
      }
    });

    return {
      acceptedCount,
      pendingCount,
      processingCount,
    };
  },
  key: 'calculateStatusCounts',
});

export const orderListStateAtom = atom<OrderLocalized[]>({
  default: [],
  key: 'orderListStateAtom',
});
