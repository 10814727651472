import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Typography,
  Checkbox,
  MenuItem,
  TextField,
  FormHelperText,
  Select,
  Divider,
  useTheme,
  CircularProgress,
  styled,
} from '@mui/material';
import { format as dateFnsFormat } from 'date-fns';
import { useCallback, useEffect, useState, ReactNode } from 'react';
import {
  Controller,
  useForm,
  Control,
  FieldPath,
  UseFormWatch,
} from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import {
  getCatalog,
  updateProducts,
  updateVariants,
} from '../../adapter/catalog-service';
import { snackbarOpenState, snackbarTextState } from '../../domain/app';
import { organizationSelector } from '../../domain/organization';
import {
  ProductBulkForm,
  productBulkFormSchema,
  defaultProductBulkForm,
} from '../../schemas/catalog';
import { Product, ProductUpdate } from '../../types/catalog';
import { convertNewLine } from '../../utils';
import { getMenuItems } from '../../utils/components';
import { productStatusList, productCategoryList } from '../../utils/constants';
import { restoreSanitizedString } from '../../utils/format';
import { AttributeSelectItem } from '../Shared/AttributeSelect';
import { DateField } from '../Shared/DateField';
import { NumberField } from '../Shared/NumberField';
import { TimeSelect } from '../Shared/TimeSelect';

import { ProductLayout } from './ProductLayout';

// useNavigate を置き換え
import { useNavigateAdminDelegatable as useNavigate } from '@app/administrator/utils/useNavigateAdminDelegatable';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function FormControlItem(props: {
  checkName: FieldPath<ProductBulkForm>;
  children: ReactNode;
  control: Control<ProductBulkForm>;
  mixed?: boolean;
  required?: boolean;
  title: string;
  watch: UseFormWatch<ProductBulkForm>;
}) {
  const theme = useTheme();

  return (
    <ColBox
      gap={2}
      width={'40rem'}
      bgcolor={
        props.watch(props.checkName) ? theme.customPalette.selection : undefined
      }
      pt={2}
    >
      <RowBox gap={2} alignItems="center">
        <ColBox width="4rem" alignItems="center">
          <Controller
            name={props.checkName}
            control={props.control}
            render={({ field }) => (
              <Checkbox {...field} sx={{ height: '3rem', width: '3rem' }} />
            )}
          />
        </ColBox>
        <ColBox>
          <RowBox alignItems="center" gap={0.5}>
            <Typography>{props.title}</Typography>
            {props.required && (
              <Typography color={theme.customPalette.alert}>*</Typography>
            )}
            {props.mixed && (
              <Typography ml={2} color={'gray'} fontSize={'0.9rem'}>
                データ混在
              </Typography>
            )}
          </RowBox>
          {props.children}
        </ColBox>
      </RowBox>
      <Divider />
    </ColBox>
  );
}

type MixedValues = {
  additionalInformation?: boolean;
  attributesJob?: boolean;
  description?: boolean;
  // breakTime?: boolean;
  maxReservations?: boolean;
  period?: boolean;
  prRules?: boolean;
  price?: boolean;
  reservationNotes?: boolean;
  selection?: boolean;
  status?: boolean;
  time?: boolean;
  workLocation?: boolean;
};

export interface ProductBulkEditState {
  ids?: string[];
}

export function ProductBulkEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const locationState = location.state as ProductBulkEditState | null;
  const theme = useTheme();
  // プラン区分の状態
  const [, setCategoryEnum] = useState('');
  // 各プランデータの混在有無の状態
  const [mixedValues, setMixedValues] = useState<MixedValues>({});
  // データ読み込み済みかどうかの状態
  const [isLoadData, setIsLoadData] = useState(false);
  // データを登録中かどうかの状態
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [variantsIds, setVariantsIds] = useState([] as string[]);

  const { control, handleSubmit, watch, reset, formState } =
    useForm<ProductBulkForm>({
      defaultValues: defaultProductBulkForm,
      mode: 'onChange',
      resolver: zodResolver(productBulkFormSchema),
    });

  /** APIに渡すためのプランデータに変換 */
  const convertProduct = useCallback((formValue: ProductBulkForm) => {
    const data: ProductUpdate = {
      ...(formValue.checkPeriod || formValue.checkStatus
        ? {
            publication: {
              ...(formValue.checkPeriod
                ? {
                    since: new Date(formValue.startPeriod).toISOString(),
                    until: new Date(formValue.endPeriod).toISOString(),
                  }
                : {}),
              ...(formValue.checkStatus
                ? { status: formValue.status || 'ACTIVE' }
                : {}),
            },
          }
        : {}),
      ...(formValue.checkWorkLocation
        ? {
            locationIds: [],
          }
        : {}),
      ...(formValue.checkDescription
        ? {
            description: formValue.description,
          }
        : {}),
      ...(formValue.checkAdditionalInformation
        ? { additionalInformation: formValue.additionalInformation }
        : {}),
      ...(formValue.checkPrice || formValue.checkVariantsDescription
        ? {
            variants: [
              {
                ...(formValue.checkPrice
                  ? {
                      price: {
                        amount: formValue.variantsPriceAmount ?? 0,
                        currency: 'JPY',
                        taxIncluded: true,
                      },
                    }
                  : undefined),
                ...(formValue.checkVariantsDescription
                  ? {
                      description: formValue.reservationNotes,
                    }
                  : undefined),
              },
            ],
          }
        : {}),
      customFields: {
        ...(formValue.checkOrderConditions
          ? {
              prRules: formValue.prRules,
            }
          : {}),
        ...(formValue.checkWorkLocation ? {} : {}),
        ...(formValue.checkCount
          ? {
              maxReservations: formValue.maxReservations ?? 0,
            }
          : {}),
      },
    };
    return data;
  }, []);

  const handleRegisterButtonClick: Parameters<typeof handleSubmit>[0] =
    useCallback(
      async (formValue) => {
        if (!locationState?.ids?.length) {
          return;
        }
        try {
          setIsLoadingRegister(true);
          const data = convertProduct(formValue);
          const result = await updateProducts(
            locationState.ids,
            data,
            organizationState.id
          );
          if (result.status !== 200) {
            throw new Error(`${result.status} ${result.statusText}`);
          }

          if (data.variants?.[0]) {
            const resultVariants = await updateVariants(
              variantsIds,
              data.variants[0]
            );
            if (resultVariants.status !== 200) {
              throw new Error(
                `Variants ${resultVariants.status} ${resultVariants.statusText}`
              );
            }
          }

          navigate('/product');
        } catch (error) {
          setSnackbarText(
            `プランの更新に失敗しました, ${
              error instanceof Error ? error.message : error
            }`
          );
          setSnackbarOpen(true);
          return [[], []] as AttributeSelectItem[][];
        } finally {
          setIsLoadingRegister(false);
        }
      },
      [
        convertProduct,
        locationState,
        organizationState.id,
        variantsIds,
        navigate,
        setSnackbarText,
        setSnackbarOpen,
      ]
    );

  /** プランデータの読み込み */
  const loadProducts = useCallback(async () => {
    if (!locationState?.ids?.length) {
      return;
    }
    try {
      const result = await getCatalog(organizationState.id, {
        ids: locationState.ids,
        order: 'createdAt desc',
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status}`);
      }
      const productList = result.data.value;

      // 更新時用にvariantsのIdを取得
      setVariantsIds(
        productList.map((p) => p.variants[p.variants.length - 1].id)
      );

      // データ混在をチェック
      const isMixed = <U,>(callbackfn: (value: Product) => U) => {
        return Array.from(new Set(productList.map(callbackfn))).length > 1;
      };
      const mix: MixedValues = {
        additionalInformation: isMixed((p) => p.additionalInformation),
        description: isMixed((p) => p.description),
        maxReservations: isMixed((p) => p.customFields?.maxReservations),
        period: isMixed(
          (p) => `${p.publication?.since ?? ''}～${p.publication?.until ?? ''}`
        ),
        prRules: isMixed((p) => p.customFields?.prRules),
        price: isMixed(
          (p) =>
            `${p.variants[p.variants.length - 1]?.sku ?? ''} ${
              p.variants[p.variants.length - 1]?.price.amount ?? ''
            }`
        ),

        reservationNotes: isMixed(
          (p) => p.variants[p.variants.length - 1]?.description
        ),
        status: isMixed((p) => p.publication?.status),
        time: isMixed(
          (p) =>
            `${p.customFields?.startTime ?? ''}～${
              p.customFields?.endTime ?? ''
            }`
        ),
      };
      setMixedValues(mix);

      // フォーム用のプランデータを生成
      const product = productList[0];
      const formValues: ProductBulkForm = {
        ...defaultProductBulkForm,
        additionalInformation: mix.additionalInformation
          ? ''
          : restoreSanitizedString(product.additionalInformation),
        day: productList
          .map((p) =>
            p.customFields?.days && p.customFields?.days.length > 0
              ? new Date(p.customFields?.days[0])
              : undefined
          )
          .sort((a, b) => (a?.getTime() ?? 0) - (b?.getTime() ?? 0))[0],
        description: mix.description
          ? ''
          : restoreSanitizedString(product.description),
        endPeriod: mix.period
          ? ''
          : dateFnsFormat(new Date(product.publication.until), 'yyyy/MM/dd'),
        endTime: mix.time ? '' : product.customFields?.endTime ?? '',
        maxReservations: mix.maxReservations
          ? null
          : product.customFields?.maxReservations ?? null,
        prRules: mix.prRules
          ? ''
          : convertNewLine(product.customFields?.prRules ?? ''),

        reservationNotes: mix.reservationNotes
          ? ''
          : convertNewLine(
              product.variants?.[product.variants.length - 1]?.description ?? ''
            ),
        startPeriod: mix.period
          ? ''
          : dateFnsFormat(new Date(product.publication.since), 'yyyy/MM/dd'),
        startTime: mix.time ? '' : product.customFields?.startTime ?? '',
        status: mix.status ? '' : product.publication?.status ?? '',
        variantsPriceAmount: mix.price
          ? null
          : product.variants?.[product.variants.length - 1]?.price.amount ??
            null,
      };
      setCategoryEnum(
        productCategoryList.find((c) => c.name === product.category.name)?.id ??
          ''
      );
      reset(formValues);
    } catch (error) {
      setSnackbarText(
        `プランの取得に失敗しました。, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [
    locationState,
    organizationState,
    reset,
    setSnackbarText,
    setSnackbarOpen,
  ]);

  useEffect(() => {
    void (async () => {
      await loadProducts();
      setIsLoadData(true);
    })();
  }, [loadProducts]);

  // プランデータが読み込めるまで内容を表示しない
  if (!isLoadData) {
    return (
      <ProductLayout sx={{ minWidth: 1000, px: 0 }}>
        <RowBox alignItems="center" gap={1} px={2}>
          <Typography variant="h5">一括編集</Typography>
        </RowBox>
        <Divider />
        <ColBox gap={1} px={2}>
          <Typography>
            編集対象のプランID：{locationState?.ids?.join('、')}
          </Typography>
        </ColBox>
        <CircularProgress sx={{ m: 'auto' }} />
      </ProductLayout>
    );
  }

  return (
    <ProductLayout sx={{ minWidth: 1000, px: 0 }}>
      <RowBox alignItems="center" gap={1} px={2}>
        <Typography variant="h5">一括編集</Typography>
        <RowBox alignItems="center" gap={2} ml="auto">
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            sx={{ width: '8rem' }}
            loading={isLoadingRegister}
            onClick={handleSubmit(handleRegisterButtonClick)}
          >
            一括編集
          </LoadingButton>
        </RowBox>
      </RowBox>

      <Divider />

      <ColBox gap={1} px={2}>
        <Typography>
          編集対象のプランID：{locationState?.ids?.join('、')}
        </Typography>
      </ColBox>

      <Divider />

      <ColBox px={2}>
        <ColBox gap={2} mb={5}>
          <Typography sx={{ color: theme.palette.grey[600] }}>
            一括で変更したい項目にチェックを入れてください。
          </Typography>
          <Typography sx={{ color: theme.palette.grey[600] }}>
            「データ混在」となっている箇所は、選択したプランの間でデータが異なる箇所です。一括編集で選択しなければ、元のデータのままとなります。
          </Typography>
        </ColBox>
        {formState.errors.checkAll?.message && (
          <Typography color={theme.customPalette.alert}>
            {formState.errors.checkAll.message}
          </Typography>
        )}
        <FormControlItem
          title="掲載ステータス"
          required
          mixed={mixedValues.status}
          checkName="checkStatus"
          control={control}
          watch={watch}
        >
          <Controller
            name="status"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Select
                  {...field}
                  error={!!error}
                  sx={{ bgcolor: theme.palette.common.white, width: '20rem' }}
                  displayEmpty
                  renderValue={(selected: string) => {
                    if (selected) {
                      return productStatusList.find((a) => a.id === selected)
                        ?.name;
                    }
                    return (
                      <span
                        style={{
                          color: theme.customPalette.lightGray,
                        }}
                      >
                        掲載ステータスを選択
                      </span>
                    );
                  }}
                >
                  <MenuItem value="" disabled>
                    掲載ステータスを選択
                  </MenuItem>
                  {getMenuItems(productStatusList)}
                </Select>
                {error ? (
                  <FormHelperText error sx={{ ml: 2 }}>
                    {error.message}
                  </FormHelperText>
                ) : undefined}
              </>
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="掲載期間"
          required
          mixed={mixedValues.period}
          checkName="checkPeriod"
          control={control}
          watch={watch}
        >
          <RowBox alignItems={'baseline'} gap={1}>
            <Controller
              name="startPeriod"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DateField
                  {...field}
                  ref={undefined}
                  inputRef={field.ref}
                  format="yyyy/MM/dd"
                  error={!!error}
                  sx={{ width: '10rem' }}
                  InputProps={{ sx: { bgcolor: theme.palette.common.white } }}
                  helperText={error?.message}
                />
              )}
            />
            <Typography>～</Typography>
            <Controller
              name="endPeriod"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DateField
                  {...field}
                  ref={undefined}
                  inputRef={field.ref}
                  format="yyyy/MM/dd"
                  error={!!error}
                  sx={{ width: '10rem' }}
                  InputProps={{ sx: { bgcolor: theme.palette.common.white } }}
                  helperText={error?.message}
                />
              )}
            />
          </RowBox>
        </FormControlItem>
        <FormControlItem
          title="来店地"
          required
          mixed={mixedValues.workLocation}
          checkName="checkWorkLocation"
          control={control}
          watch={watch}
        >
          <RowBox alignItems="center" gap={0.5}>
            <Typography>来店地情報</Typography>
            <Typography color={theme.customPalette.alert}>*</Typography>
          </RowBox>
        </FormControlItem>
        <FormControlItem
          title="プラン内容"
          required
          mixed={mixedValues.description}
          checkName="checkDescription"
          control={control}
          watch={watch}
        >
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                error={!!error}
                sx={{ width: '22rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                multiline
                rows={2.5}
                placeholder="プラン内容を入力"
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="応募条件"
          mixed={mixedValues.prRules}
          checkName="checkOrderConditions"
          control={control}
          watch={watch}
        >
          <Controller
            name="prRules"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                error={!!error}
                sx={{ width: '22rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                multiline
                rows={2.5}
                placeholder="応募条件を入力"
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="その他情報"
          mixed={mixedValues.additionalInformation}
          checkName="checkAdditionalInformation"
          control={control}
          watch={watch}
        >
          <Controller
            name="additionalInformation"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                error={!!error}
                sx={{ width: '22rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                multiline
                rows={2.5}
                placeholder="その他情報を入力"
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="来店時間"
          required
          mixed={mixedValues.time}
          checkName="checkTime"
          control={control}
          watch={watch}
        >
          <RowBox alignItems="baseline" gap={2}>
            <Controller
              name="startTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TimeSelect
                  {...field}
                  startTime="00:00"
                  endTime="23:55"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Typography>～</Typography>
            <Controller
              name="endTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TimeSelect
                  {...field}
                  startTime="00:00"
                  endTime="96:55"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </RowBox>
        </FormControlItem>
        <FormControlItem
          title="インセンティブ"
          mixed={mixedValues.reservationNotes}
          checkName="checkVariantsDescription"
          control={control}
          watch={watch}
        >
          <Controller
            name="reservationNotes"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                error={!!error}
                sx={{ width: '22rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                multiline
                rows={2.5}
                placeholder="インセンティブを入力"
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="募集人数"
          required
          mixed={mixedValues.maxReservations}
          checkName="checkCount"
          control={control}
          watch={watch}
        >
          <Controller
            name="maxReservations"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumberField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                sx={{ width: '10rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                placeholder="募集人数を入力"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
      </ColBox>
    </ProductLayout>
  );
}
