import MoodIcon from '@mui/icons-material/Mood';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import { Box, Button, Typography, Tooltip, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { endOfMonth, format as dateFnsFormat } from 'date-fns';
import dateFnsJa from 'date-fns/locale/ja';
import { useRecoilValue } from 'recoil';

import { StarsRating } from '@app/components/Shared/StarsRating';
import { organizationSelector } from '@app/domain/organization';
import { Review } from '@app/types/review';
import { convertNewLineToBr } from '@app/utils/components';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

export interface ReviewItemProps {
  isAdmin?: boolean;
  onClickEdit?: () => void;
  review: Review;
}

export function ReviewItem({ isAdmin, review, onClickEdit }: ReviewItemProps) {
  const theme = useTheme();
  const now = new Date();
  const createdDate = new Date(review.createdAt);
  const nowNum = Number(dateFnsFormat(now, 'yyyyMMddHHmmss'));
  const createdNum = Number(dateFnsFormat(createdDate, 'yyyyMMddHHmmss'));
  const organizationState = useRecoilValue(organizationSelector);

  const sa = nowNum - createdNum;
  const createdStr =
    sa >= 1 * 10 ** 10
      ? `${Math.floor(sa / 10 ** 10)}年前`
      : nowNum - createdNum >= 1 * 10 ** 8
      ? `${
          Math.floor(sa / 10 ** 8) -
          (now.getFullYear() === createdDate.getFullYear() ? 0 : 100 - 12)
        }月前`
      : sa >= 1 * 10 ** 6
      ? `${
          Math.floor(sa / 10 ** 6) -
          (now.getMonth() === createdDate.getMonth()
            ? 0
            : 100 - endOfMonth(createdDate).getDate())
        }日前`
      : sa >= 1 * 10 ** 4
      ? `${
          Math.floor(sa / 10 ** 4) -
          (now.getDate() === createdDate.getDate() ? 0 : 100 - 24)
        }時間前`
      : sa >= 1 * 10 ** 2
      ? `${
          Math.floor(sa / 10 ** 2) -
          (createdDate.getHours() === now.getHours() ? 0 : 100 - 60)
        }分前`
      : '0分前';

  const rateList = [
    { name: '医療の質', rate: review.subRates?.quality },
    { name: '診察の速さ', rate: review.subRates?.speed },
    { name: '接遇・清潔感', rate: review.subRates?.reception },
    {
      name: '職員間コミュニケーション',
      rate: review.subRates?.communication,
    },
    {
      name: '店舗方針の遵守',
      rate: review.subRates?.followRule,
    },
    {
      name: '勤怠（遅刻・遅延）',
      rate: review.subRates?.attendance,
    },
  ];
  return (
    <RowBox gap={1} alignItems="baseline">
      <Typography width="5rem">{review.customFields?.reviewBy}</Typography>
      <ColBox gap={3} width="45rem">
        <RowBox gap={1} alignItems="center">
          <StarsRating iconProps={{ fontSize: 'small' }} rate={review.rate} />
          <Tooltip
            title={dateFnsFormat(createdDate, 'yyyy年MM月dd日(eee) HH時mm分', {
              locale: dateFnsJa,
            })}
            placement="right"
            enterDelay={1000}
          >
            <Typography color={theme.palette.grey[600]}>
              {createdStr}
            </Typography>
          </Tooltip>
          {(isAdmin || organizationState.id === review.reviewedBy) && (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ ml: 'auto', width: '8rem' }}
              onClick={onClickEdit}
            >
              編集
            </Button>
          )}
        </RowBox>
        <ColBox gap={0.5}>
          <RowBox gap={1}>
            <MoodIcon sx={{ color: theme.palette.primary.light }} />
            <Typography width="6rem">よかった点</Typography>
            <Typography>:</Typography>
            <Typography width="30rem">
              {rateList
                .filter((r) => r.rate > 3)
                .map((r) => r.name)
                .join('、') || 'なし'}
            </Typography>
          </RowBox>
          <RowBox gap={1}>
            <MoodBadIcon sx={{ color: theme.palette.grey[600] }} />
            <Typography width="6rem">わるかった点</Typography>
            <Typography>:</Typography>
            <Typography width="30rem">
              {rateList
                .filter((r) => r.rate < 3)
                .map((r) => r.name)
                .join('、') || 'なし'}
            </Typography>
          </RowBox>
        </ColBox>
        <Typography>{convertNewLineToBr(review.comment)}</Typography>
      </ColBox>
    </RowBox>
  );
}
