import { User } from './user';

export interface OrderStats {
  count: number;
  organization: string;
  productId: string;
}

export interface OrderResponse {
  '@nextLink': string;
  count: number;
  total: number;
  value: Array<Order>;
}

export interface Order {
  cancelReason: SupportedCancelReason | null;
  canceledAt: string;
  cart: string;
  checkout: string;
  closedAt: string;
  createdAt: string;
  currency: string;
  customFields?: {
    appointmentDate: string;
    appointmentTime: string;
    cancelMessage: string;
    cancelRequestAt: string;
    cancelType: number;
    peopleCount: number;
    productDay: string[];
    productType: string;
    workResult: string;
    workResultAt: string;
  };
  customer: OrderCustomer;
  gender: string;
  id: string;
  lineItems: Array<OrderLineItem>;
  name: string;
  organization: string;
  status: SupportedOrderStatus;
  subTotalPrice: number;
  totalTax: number;
  updatedAt: string;
}

export interface OrderLocalized {
  cancelReason: string;
  createdAt: string;
  currency: string;
  customFields?: {
    appointmentDate: string;
    appointmentTime: string;
    cancelMessage: string;
    cancelRequestAt: string;
    cancelType: number;
    peopleCount: number;
    productDay: string[];
    productType: string;
    snsUrl?: string;
    workResult: string;
    workResultAt: string;
  };
  customer: OrderCustomer;
  gender: string;
  id: string;
  lineItem: OrderLineItem;
  localizedStatus: string;
  name: string;
  organization: string;
  status: SupportedOrderStatus;
  subTotalPrice: number;
  totalTax: number;
}

export interface OrderLineItem {
  price: OrderLineItemPrice;
  product: string;
  productName: string;
  quantity: number;
  sku: string;
  variant: string;
  variantTitle: string;
}

export interface OrderLineItemPrice {
  amount: number;
  taxable: boolean;
}

export interface OrderCustomer {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  age: string;
  department: string;
  email: string;
  name: string;
  nameKana: string;
  phone: string;
  prefecture: string;
  preferredContactMethod: string;
  preferredTimeToContact: string;
  rate: string;
  user: User;
}

export const OrderStatus = {
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  WAITING: 'WAITING', //投稿確認タブ
} as const;

export type SupportedOrderStatus =
  (typeof OrderStatus)[keyof typeof OrderStatus];

export const CancelReason = {
  CUSTOMER: 'customer',
  DECLINED: 'declined',
  INVENTORY: 'inventory',
  OTHER: 'other',
} as const;

export type SupportedCancelReason =
  (typeof CancelReason)[keyof typeof CancelReason];
