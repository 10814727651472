import {
  // Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from '@mui/x-data-grid';
import { AxiosRequestConfig, CanceledError } from 'axios';
import { format as dateFnsFormat, parseISO } from 'date-fns';
import { ja as dateFnsJa } from 'date-fns/locale';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, /* Link, */ useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import { getOrganizationDetails } from '@app/adapter/organization-service';
import { PageTitle } from '@app/components/Shared/PageTitle';
import {
  StandardStyleDataGrid,
  INITIAL_PAGINATE_MODEL,
} from '@app/components/Shared/StandardStyleDataGrid';
import { loggedInUserState, snackbarOpenState } from '@app/domain/app';
import { organization as organizationAtom } from '@app/domain/organization';
import {
  Organization,
  SupportedOrganizationStatus,
  ORGANIZATION_STATUS,
  ORGANIZATION_STATUS_LABELS,
} from '@app/types/organization';
import { unescapeHtml } from '@app/utils/pattern';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

const SELECTABLE_ORGANIZATION_STATUS = {
  ...ORGANIZATION_STATUS,
  ALL: '__ALL__', // 重複しないように
};
export type SelectableOrganizationStatus =
  (typeof SELECTABLE_ORGANIZATION_STATUS)[keyof typeof SELECTABLE_ORGANIZATION_STATUS];

const COLUMNS: Array<GridColDef> = [
  {
    field: 'name',
    headerName: '名称',
    maxWidth: 300,
    minWidth: 100,
    width: 250,
  },
  {
    field: 'fullAddress',
    headerName: '所在地',
    width: 250,
  },
  {
    field: 'startAt',
    headerName: '利用開始',
    width: 200,
  },
  {
    field: 'statusLabel',
    headerName: 'ステータス',
    width: 80,
  },
  {
    field: 'menu',
    headerName: '',
    width: 40,
  },
];

// 新規追加画面からの遷移
export interface ClinicListLocationState {
  referrer?: string;
  success?: boolean;
}

export function ClinicList() {
  // snackbar
  const setSnackbar = useSetSnackbar();
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);

  const loggedInUser = useRecoilValue(loggedInUserState);
  const setOrganization = useSetRecoilState(organizationAtom);
  const navigateTo = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const locationState = location.state as ClinicListLocationState;
    if (!locationState) {
      return;
    }
    // 編集画面から戻るとき
    if (locationState?.success) {
      setSnackbar(true, '店舗情報を保存しました', 'success');
    }
  }, [location, setSnackbar]);

  //
  // Pagination
  //
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(INITIAL_PAGINATE_MODEL);
  const [rows, setRows] = useState<Array<Organization>>([]);
  const [rowTotal, setRowTotal] = useState<number>(
    INITIAL_PAGINATE_MODEL.pageSize
  );

  //
  // Filter条件
  //

  // キーワード入力
  const [filterKeyword, setFilterKeyword] = useState<string>('');
  const isImeOnRef = useRef<boolean>(false); // 日本語入力中の変換確定Enterを抑制
  const filterKeywordInputRef = useRef<HTMLInputElement>(null);
  const handleKeyDownFilterKeyword = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      filterKeywordInputRef.current &&
      !isImeOnRef.current &&
      event.key === 'Enter' // Enterで送信
    ) {
      setFilterKeyword(filterKeywordInputRef.current.value);
    }
  };

  // ステータス選択
  const [filterStatus, setFilterStatus] =
    useState<SelectableOrganizationStatus>(SELECTABLE_ORGANIZATION_STATUS.ALL);

  const handleChangeStatusSelect = (event: SelectChangeEvent) => {
    setFilterStatus(event.target.value);
    if (filterKeywordInputRef.current) {
      setFilterKeyword(filterKeywordInputRef.current.value.trim());
    }
  };

  //
  // API Request
  //
  const fetchOrganization = useCallback(
    async (abortController: AbortController) => {
      if (!loggedInUser || !loggedInUser.id) {
        console.error('invalid user', loggedInUser);
        return;
      }

      setIsLoading(true);
      try {
        const { page, pageSize } = currentPaginationModel;
        const $skip = Math.ceil(page * pageSize);
        const $top = pageSize;

        const $filterStatus =
          filterStatus !== SELECTABLE_ORGANIZATION_STATUS.ALL
            ? `status eq '${filterStatus}'`
            : null;
        const $filterKeyword =
          filterKeyword && filterKeyword.length > 0
            ? `name co '${filterKeyword}'`
            : null;
        const $filterJoined = [$filterStatus, $filterKeyword]
          .filter((item) => item !== null)
          .join(' and ')
          .trim();
        const $filter = $filterJoined.length > 0 ? $filterJoined : undefined;
        const config: AxiosRequestConfig = {
          params: { $filter, $skip, $top }, // query parameters
          signal: abortController.signal,
        };
        const userId = loggedInUser.id;

        const result = await getOrganizationDetails(userId, config);
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        console.debug('[ShopList] getOrganizationDetails() RESULT', {
          page,
          pageSize,
          result,
        });
        setRowTotal(result.data.total);
        setRows(
          result.data.value.map((item) => {
            const addressByCity = item.customFields.addressByCity || '';
            const addressLine3 = item.addressLine3 || '';
            const addressLine4 = unescapeHtml(
              item.customFields?.addressLine4 || ''
            );
            const dateString = item.createdAt;
            const startAt =
              (dateString &&
                dateFnsFormat(parseISO(dateString), 'yyyy年MM月dd日 HH:mm', {
                  locale: dateFnsJa,
                })) ||
              '';
            const statusLabel =
              ORGANIZATION_STATUS_LABELS[
                item.status as SupportedOrganizationStatus
              ] || item.status;
            const unescapedName = unescapeHtml(item.name);

            return {
              ...item,
              fullAddress: [addressByCity, addressLine3, addressLine4].join(
                ' '
              ),
              name: unescapedName,
              startAt,
              statusLabel,
            };
          })
        );
        return;
      } catch (error: unknown) {
        if (error instanceof CanceledError) {
          return; // canceled by useEffect clean-up : 何もしない
        } else if (error instanceof Error) {
          setSnackbar(true, error.message, 'error');
        } else {
          console.error('[ShopList] getOrganizations() UNKNOWN ERROR', error);
        }
        return;
      } finally {
        setIsLoading(false);
        setSnackbarOpen(false);
      }
    },
    [
      loggedInUser,
      currentPaginationModel,
      filterStatus,
      filterKeyword,
      setSnackbar,
      setSnackbarOpen,
    ]
  );

  useEffect(() => {
    const abortController = new AbortController();
    void fetchOrganization(abortController);
    // CLEAN-UP
    return () => {
      abortController.abort();
    };
  }, [fetchOrganization]);

  //
  // Render
  //

  return (
    <>
      <Grid container mb={2}>
        <Grid item xs={4}>
          <PageTitle title="店舗一覧" />
        </Grid>
        {/* 一時的に非表示 */}
        {/* <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Link to="/shop/create">
            <Button variant="contained" color="primary" size="small">
              店舗を追加
            </Button>
          </Link>
        </Grid> */}
      </Grid>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                inputRef={filterKeywordInputRef}
                variant="outlined"
                size="small"
                label="キーワードで絞り込み"
                defaultValue=""
                onCompositionStart={() => (isImeOnRef.current = true)}
                onCompositionEnd={() => (isImeOnRef.current = false)}
                onKeyDown={handleKeyDownFilterKeyword}
              />
              <span
                style={{
                  color: '#666',
                  fontSize: '12px',
                  verticalAlign: 'bottom',
                }}
              >
                ※Enterで送信
              </span>
            </Grid>
            <Grid item xs={8}>
              ステータス
              <Select
                onChange={handleChangeStatusSelect}
                value={filterStatus}
                size="small"
              >
                <MenuItem value={SELECTABLE_ORGANIZATION_STATUS.ALL}>
                  すべて
                </MenuItem>
                {Object.entries(ORGANIZATION_STATUS_LABELS)
                  .filter(([key]) => key === ORGANIZATION_STATUS.NORMAL) // NOTE:利用中のみ残す
                  .map(([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <StandardStyleDataGrid
                paginationModel={currentPaginationModel}
                onPaginationModelChange={setCurrentPaginationModel}
                columns={COLUMNS}
                rows={rows}
                rowCount={rowTotal}
                loading={isLoading}
                onRowClick={(params: GridRowParams<Organization>) => {
                  const selectedOrg = params.row;
                  setOrganization(selectedOrg);
                  navigateTo(`/shop/${selectedOrg.id}/view`);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
