// Copyright 2022 Co-Lift Inc.
import {
  AxiosPromise,
  AxiosResponse,
  AxiosRequestConfig,
  CanceledError,
} from 'axios';

import { Paginated } from '../types/common';
import {
  FollowListResponse,
  FollowUser,
  InvitationForm,
  InvitationItemDetail,
  User,
  UserInfoResponse,
} from '../types/user';
import { getAuthorizationHeader } from '../utils/authorization';

import { axiosInstance, USER_SERVICE } from './axios';

import { filterSyntaxGen } from '@app/utils';

/**
 * Register a president account with permission 010.
 */
export function register(
  email: string,
  password: string
): AxiosPromise<{ id: string }> {
  return axiosInstance
    .post(`${USER_SERVICE}/users`, {
      email,
      password,
      typeId: '010',
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * 認証スキップしてUser登録が可能
 */
export function createUserForAdmin(
  email: string,
  password: string
): AxiosPromise<{ id: string }> {
  return axiosInstance
    .post(`${USER_SERVICE}/admin/users`, {
      email,
      name: 'name', //TODO:API必須外れたら削除
      password,
      typeId: '010',
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getUser(userId: string): AxiosPromise<User> {
  return axiosInstance.get(`${USER_SERVICE}/users/${userId}`);
}

export function sendVerificationEmail(userId: string): AxiosPromise<void> {
  return axiosInstance.post(
    `${USER_SERVICE}/users/${userId}:sendVerificationEmail`,
    {}
  );
}

export function updateUser(
  userId: string,
  familyName: string,
  familyNameKana: string,
  firstName: string,
  firstNameKana: string,
  token?: string
): AxiosPromise<void> {
  return axiosInstance
    .patch(
      `${USER_SERVICE}/users/${userId}`,
      {
        customFields: {
          familyName,
          familyNameKana,
          firstName,
          firstNameKana,
        },
      },
      {
        headers: {
          'x-nb-token': token,
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get Invitation list
 *
 * @param organizationId
 * @returns
 * blocks-9ec7
 */
export function getInvitationList(
  organizationId: string,
  top: number,
  offset: number
): AxiosPromise<Paginated<InvitationItemDetail>> {
  return axiosInstance.get(
    `${USER_SERVICE}/invitations?orgId=${organizationId}&$top=${top}&$skip=${offset}`
  );
}

/**
 * Invite a member to an organization.
 */
export function inviteMember(
  member: InvitationForm
): AxiosPromise<InvitationItemDetail> {
  return axiosInstance.post(`${USER_SERVICE}/invitations`, {
    ...member,
  });
}

/**
 * API wrapper for accept invitation in user-service
 * @param invitationId
 * @param opts
 * @param token
 * @returns void
 * blocks-9ec7
 */
export function acceptInvitation(
  invitationId: string,
  opts: { name: string; password: string },
  token: string
) {
  return axiosInstance.patch(
    `${USER_SERVICE}/invitations/${invitationId}`,
    {
      ...opts,
      typeId: '001',
    },
    {
      headers: getAuthorizationHeader(token),
    }
  );
}

/**
 * blocks-5f90
 */
export function verifyEmail(token: string): AxiosPromise<void> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/verify_email`, undefined, {
      headers: getAuthorizationHeader(token),
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * send email to reset password
 * @param email - user email
 */
export function sendResetPasswordEmail(email: string): AxiosPromise<null> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/send_reset_password_email`, { email })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * reset password
 * @param newPassword - new password
 * @param token - onetime token
 * @returns
 */
export function resetPassword(
  newPassword: string,
  token: string
): AxiosPromise<null> {
  return axiosInstance
    .post(
      `${USER_SERVICE}/users/reset_password`,
      { password: newPassword },
      {
        headers: getAuthorizationHeader(token),
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * reset password from account settings
 * @param userId
 * @param newPassword
 */
export function resetPasswordFromSetting(
  userId: string,
  newPassword: string
): AxiosPromise<null> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, { password: newPassword })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getUserInfoById(userId: string): Promise<UserInfoResponse> {
  return axiosInstance
    .get(`${USER_SERVICE}/users/${userId}`)
    .then((response) => {
      console.log('getUserInfoById Response:', response.data);
      return response.data;
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function changeNameFromSetting(
  userId: string,
  newFamilyName: string,
  newFirstName: string
): AxiosPromise<null> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, {
      customFields: {
        familyName: newFamilyName,
        firstName: newFirstName,
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

// MEMO：現状APIがないので仮のもの
export function changeEmailFromSetting(
  userId: string,
  newEmail: string
): AxiosPromise<null> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, {
      email: newEmail,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get avatar upload url
 * @param userId
 * @param contentType
 * @param contentLength
 */
export function getAvatarUploadUrl(
  userId: string,
  contentType: string,
  contentLength: number
): AxiosPromise<string> {
  const params = new URLSearchParams();
  params.append('contentType', contentType);
  params.append('contentLength', contentLength.toString());

  return axiosInstance
    .get(`${USER_SERVICE}/users/${userId}/avatarUploadUrl?${params}`)
    .catch((error) => {
      throw new Error(error.response.data);
    });
}

/**
 * Update user avatar
 */
export function updateAvatar(
  userId: string,
  avatar: string
): AxiosPromise<{ id: string }> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, {
      avatar,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getUserFollowList(
  userId: string,
  type: 'user' | 'organization' | 'product',
  options?: {
    followIds?: string[];
    pageNumber?: number | 0;
    pageSize?: number | 10;
  }
): Promise<FollowListResponse> {
  const urlParams: string[][] = [];

  if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
    urlParams.push([`$top`, options.pageSize.toString()]);
    urlParams.push([
      `$skip`,
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }

  const filterParam: string[] = [];
  if (options?.followIds?.length) {
    filterParam.push(`followId in ${filterSyntaxGen(options.followIds)}`);
  }

  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  return axiosInstance
    .get(
      `${USER_SERVICE}/follows?followerId=${userId}&type=${type}&${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .then((response: AxiosResponse<FollowListResponse>) => {
      return response.data;
    })
    .catch((error) => {
      console.error(
        'Failed to fetch follow list:',
        error.response ? error.response.data : error.message
      );
      throw error;
    });
}

export function followUser(
  userId: string,
  followerId: string,
  followerType: 'user' | 'organization' | 'product'
): Promise<FollowUser> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/${userId}/follow`, {
      followerId,
      followerType,
    })
    .then((response: AxiosResponse<FollowUser>) => {
      return response.data;
    })
    .catch((error) => {
      console.error(
        'Failed to follow user:',
        error.response ? error.response.data : error.message
      );
      throw error;
    });
}

export function unFollowUser(
  userId: string,
  followerId: string,
  followerType: 'user' | 'organization' | 'product'
): Promise<FollowUser> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/${userId}/unfollow`, {
      followerId,
      followerType,
    })
    .then((response: AxiosResponse<FollowUser>) => {
      return response.data;
    })
    .catch((error) => {
      console.error(
        'Failed to unFollow user:',
        error.response ? error.response.data : error.message
      );
      throw error;
    });
}

//
// ユーザ一覧取得
//
export function getUsers(
  config?: AxiosRequestConfig
): AxiosPromise<Paginated<User>> {
  return axiosInstance.get(`${USER_SERVICE}/users`, config).catch((error) => {
    if (error instanceof CanceledError) {
      throw error;
    } else if (error.response && 'message' in error.response.data) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error(error.message);
    }
  });
}
