import { Container, Typography, Box } from '@mui/material';

const boxStyle = {
  mb: 3,
  ml: 2,
};

const marginBottom2Style = {
  mb: 2,
};

const marginBottom5Style = {
  mb: 5,
};

const TitleTypographyStyle = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  mb: 1,
};

export function TermsPage() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 7, textAlign: 'center' }}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ fontSize: '3rem', fontWeight: 'bold' }}
        >
          ＜TieUp店舗会員規約＞
        </Typography>

        <Box sx={{ mb: 2, textAlign: 'left' }}>
          <Typography paragraph sx={{ mb: 12, mt: 10 }}>
            本規約は、株式会社F.lab（以下「当社」といいます）が提供する
            「TieUp」の店舗向けサービスの利用にあたり、本件サービスの店舗会員に適用される遵守事項を定めます。
          </Typography>

          <Typography paragraph sx={TitleTypographyStyle}>
            第1条（定義等）
          </Typography>
          <Typography paragraph sx={marginBottom2Style}>
            1. 本規約で用いる用語の定義は以下のとおりとします。
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              (ア)
              「本件サービス」とは、当社が店舗会員に対し、当該店舗会員に関する各種情報を当社所定の
              インターネットサイト「TieUp」上に掲載し、公衆送信の方法によりインターネットユーザーに公開するサービスをいいます。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (イ)
              「店舗会員」とは、本規約に承諾したうえで当社の指定する方法に従って本件サービス利用の会員登録を申し込み、当社が当該申し込みを承認した店舗をいいます。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (ウ)
              「店舗会員情報」とは、店舗名、電話番号、住所、申込者名、店舗との関係、メールアドレス等当社が指定する店舗会員の申し込み時に必要な情報をいいます。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (エ)
              「掲載情報」とは、TieUp上に掲載する店舗会員掲載情報をいいます。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (オ)
              「店舗会員掲載情報」とは、画像、PR支援条件、店舗詳細情報、プラン情報などの店舗会員によりTieUp上に掲載された情報をいいます。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (カ)
              「ユーザーによる掲載情報」とは、店舗情報、画像などのTieUpユーザー会員によりTieUp上に掲載された情報をいいます。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (キ)
              「店舗管理画面」とは、本件サービス利用にあたり当社が店舗会員に提供するインターネット上の画面をいいます。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              (ク)「ユーザ会員」とは、本件サービスの利用者のうち、店舗会員でないものをいいます。
            </Typography>
          </Box>
          <Typography paragraph sx={marginBottom5Style}>
            2.店舗会員は、予め本規約を承諾のうえ、当社の指定する方法に従って会員登録を申し込むものとし、会員登録中、本規約を遵守するものとします。
          </Typography>
          <Typography paragraph sx={marginBottom5Style}>
            3.当社は会員登録申し込みの際に審査を行い、当社が店舗会員として不適切と判断した場合、登録を認めないことがあります。
            また、承認後であっても当社が不適切と判断した場合は承認の取り消しを行う場合があります。
          </Typography>
          <Typography paragraph sx={marginBottom5Style}>
            4.当社から店舗会員に対する通知の方法は、当社の選択により、店舗会員が当社に届け出た住所・メールアドレス・FAX番号・店舗管理画面に送付または送信する
            方法その他当社が適切と認めた方法のいずれかにより行います。
            当該方法のいずれかにより送付または送信した時点において、当社から店舗会員に対する通知がなされたものとみなします。
          </Typography>

          <Typography paragraph sx={TitleTypographyStyle}>
            第2条（アカウント管理）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.店舗会員は、当社から発行されたID、パスワード（以下総称して「アカウント」といいます）の利用にあたり、
              当該利用に関する正当な権限を有することを保証するものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.店舗会員は当社から発行されたアカウントの管理責任を負うものとし、第三者に譲渡・貸与・開示してはならないものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              3.店舗会員は、アカウントの管理不十分、使用上の過誤、第三者の使用などに起因する損害につき、自ら責任を負うものとし、
              アカウントが第三者によって不正に使用されていることが判明した場合には、速やかに当社に連絡するものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              4.アカウントが不正に利用された場合であっても、当社は当該利用が店舗会員による適正な利用とみなし、
              当該利用により発生した一切の損害等につき何らの責任も負わないものとします。
              但し当社に責めに帰すべき事由が存した場合はこの限りではありません。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              5.店舗会員は店舗会員情報に変更がある場合には、当社所定の方法により速やかに当該情報を届け出るものとします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第3条（本件サービスについて）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              店舗会員は、本件サービスの内容等は当社が任意に定めるものとし、店舗会員に対する事前に通知のうえ変更・終了する場合があることを予め承諾するものとします。
              また、災害・事故、その他緊急事態が発生した際は、本件サービスを停止する場合があります。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第4条（掲載情報および外部SNS投稿内容）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.店舗会員は、会員登録中、当社所定の方法に従って自らの責任において店舗会員掲載情報等を掲載もしくは変更できるものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.原則として、文字情報及び画像等掲載情報の掲載場所及び順序は当社が決定するものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              3.店舗会員は、会員登録中、一般公開情報以外の店舗会員掲載情報を任意で削除できるものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              4.店舗会員は、理由の如何を問わず会員登録解除後においては、店舗会員掲載情報の掲載・削除に関して当社の任意の判断に従うことに同意します。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              5.店舗会員は、当社が自らの判断により何ら事前の通知なく店舗会員掲載情報の編集・変更・削除を行うことに同意します。
              また、当社はその理由の如何を問わず店舗会員掲載情報の削除義務を負うものではありません。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              6.ユーザ会員が外部SNSに投稿した内容は、外部SNSの利用規約に準ずるものとし、当社は一切の関与をいたしません。
              削除や変更についてはユーザ会員と店舗会員が直接協議いただくものとします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第5条（利用料）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              店舗会員は、申込書の通り本件サービスの利用料を支払うものとします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第6条（著作権）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.店舗会員が本件サービスへ店舗会員掲載情報（画像のアップロードを含む）の掲載を行った時点で、当該情報の内容の国内外における複製権、
              公衆送信権、翻訳権・翻案権等の全ての著作権（著作権法27条及び28条の権利を含む）その他の著作権法上の権利（当社から第三者に対する再利用許諾権を含む）
              を店舗会員が無償で当社に対して許諾したものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.当社は必要に応じて、店舗会員が本件サービスを通じて登録・提供した情報の全部または一部を店舗会員への事前の通知・承認または対価の支払いを要せず、
              内容の変更・複写・移動を行うほか、自由に利用することができるものとします。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              3.当社は、本条の規定に従い第2項の情報を利用したことにより、店舗会員もしくは第三者に発生した損害について一切の責任を負いません。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第7条（守秘義務）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              店舗会員は、会員登録中または会員登録解除後にかかわらず、本件サービスに関連して知り得た情報、その他当社の機密に属すべき一切の事項を第三者に漏洩してはならないものとします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第8条（禁止事項）
          </Typography>
          <Typography paragraph sx={marginBottom5Style}>
            1.店舗会員は、当社が提供する本件サービスの利用、本件サービスへのアクセスに関して、以下の行為を行ってはならないものとします。
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              [1]
              TieUpに掲載されている情報その他本件サービスで取得可能な情報を、本件サービスと類似するサービスその他の他のサイトに転載し、
              またはインターネット以外の媒体に転用する行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              [2]
              TieUp上で、飲食店としての店舗情報以外の情報を掲載するなどして、飲食店としての店舗以外の営業活動の宣伝をする行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              [3] 宗教活動、政治活動などの目的で本件サービスを利用する行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              [4] 上記各行為に準じる行為
            </Typography>
          </Box>
          <Typography paragraph sx={marginBottom5Style}>
            2.店舗会員は次の行為を行ってはならないものとします。行った場合は事前の通知無く、本件サービスの利用を停止される場合があることを予め承諾するものとします。
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.法令の定めに違反する行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.犯罪に結びつく行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              3.公序良俗に反する行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              4.ユーザ会員および消費者の判断に錯誤を与える恐れのある行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              5.他の店舗会員その他の第三者に対し、財産権（知的財産権を含む）の侵害、プライバシーの侵害、誹謗中傷その他不利益を与える行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              6.TieUp上で店舗会員の運営する店舗に関わる情報以外を宣伝する行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              7.当社のサービス業務の運営・維持を妨げる行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              8.虚偽の情報を故意に登録する行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              9.TieUpに関し利用しうる情報を改竄する行為
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              10.有害なコンピュータプログラム等を送信または書き込む行為
            </Typography>
          </Box>
          <Typography paragraph sx={marginBottom5Style}>
            3.店舗会員が前二項に定める行為を行った場合、当社は、かかる行為により掲載された情報等をTieUp上から削除し、
            または当該行為を行った店舗会員に対して当該行為の差止を請求できるものとします。
          </Typography>

          <Typography paragraph sx={TitleTypographyStyle}>
            第9条（本件サービスの停止）
          </Typography>
          <Typography paragraph sx={marginBottom5Style}>
            店舗会員は、当社が提供する本件サービスにおいて、下記の事情により一定期間停止される場合があることを予め承諾し、
            本件サービス停止による損害の補償等を当社に請求しないこととします。
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.当社のサーバ、ソフトウェア等の点検、修理、補修等のための停止
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.コンピュータ、通信回線等の事故による停止
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              3.その他やむをえない事情による停止
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第10条（免責等）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.当社は、店舗会員が情報掲載に関して被った損害（その原因の如何を問わない）について、その損害を賠償する責を負わないものとします。
              なお、当社は、店舗会員が掲載しようとした情報についてその掲載を拒絶したこと、当該掲載の承諾の判断に時間を要したこと、
              店舗会員掲載情報を編集・変更・削除したこと、または当該情報を削除しなかったことにより、店舗会員もしくは第三者に発生した損害について、
              その法的根拠如何を問わず、一切の責任を負いません。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.当社は、本件サービスの提供に関連する一切の行為について、店舗会員に対して、その法的根拠如何に関わらず、一切の損害賠償支払義務を負いません。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              3.本件サービスにおいて、店舗会員から提供された情報（プログラムを含む）によって第三者の知的財産権（著作権、意匠権、特許権、実用新案権、商標権、営業秘密を含む）、
              名誉、プライバシーその他第三者の権利または利益を侵害し、当社が損害賠償の支払を余儀なくされた場合は、当社は当該権利または利益侵害の原因となる情報を提供した
              店舗会員に対しその損害及び当該権利または利益の侵害に基づく請求に対する対応に関わる一切の費用（訴訟費用、賠償金、弁護士費用など）を請求できるものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              4.店舗会員間及び店舗会員と第三者の間でトラブルが発生した場合は、当該当事者間で一切の解決を図るものとし、当社は何ら負担を負わないものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              5.当社は、本件サービスの提供に関連する一切の行為について、第三者に委託することができるものとします。なおこの場合、
              当社は当該第三者に本規約規定に定める当社の負う義務を遵守させるものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              6.なお、事前に店舗会員へ通知のうえ（緊急の場合はこの限りではありません）、本件サービスを変更・停止したことにより、
              店舗会員または第三者に損害や不利益が発生しても、当社は一切の責任を負わないものとします。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              7.当社は、ユーザ会員によるSNSの投稿について、店舗会員に対して、一切の責任は負いません。当該投稿が景品表示法その他の法令に反する場合も、同様です。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第11条（有効期間）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              会員登録の有効期間はアカウント発行日より6ヶ月間とします。ただし、期間満了の1ヵ月前までに当社または店舗会員からの一方から書面又はメールによる
              更新拒絶の意思表示がない限り、会員登録は6ヶ月間更新されるものとし、以後も同様とします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第12条（店舗会員による解約）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              店舗会員は前条の期間中、本サービスの退会または解約をすることができません。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第13条（当社による解除）
          </Typography>
          <Typography paragraph sx={marginBottom2Style}>
            1.当社は、店舗会員が次のいずれかの事由に該当した場合には、何らの催告なしに会員登録を解除することができるものとします。
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              (1)
              本規約の条項に違反したとき、又は本サービスの利用料の支払いを1回でも遅延した時。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (2) 手形又は小切手の不渡りが発生した時
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (3)
              差押え、仮差押え、仮処分その他の強制執行又は滞納処分または滞納処分の申し立てを受けたとき
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (4) 破産、民事再生、会社更生または特別清算の申し立てがされた時
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (5) 前3号の他、店舗会員の信用状態に重大な変化が生じたとき
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (6)
              解散又は営業停止その他当社において店舗会員が運営する店舗の営業実態の確認ができないと判断したとき
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (7)
              営業方法等について行政当局による注意又は勧告、もしくは行政処分を受けたとき
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (8)
              店舗会員が当社のコンピュータに保存されているデータを当社に無断で閲覧、変更もしくは破壊したとき、又はその恐れがあると当社が判断したとき
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (9)
              営業方法等が公序良俗に反し又はTieUpにふさわしくないと当社が判断したとき
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (10)
              自己もしくはその役員・従業員、自己の親会社、大株主ないし実質的に会社を支配する者もしくは自己の子会社(以下、総称して「グループ会社」といいます)
              またはグループ会社の役員・従業員が、反社会的勢力(暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動標榜ゴロ、特殊知能暴力集団等またはこれらに準じる者)に該当することが判明したとき
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              (11)
              自らまたは第三者を利用して、次の①ないし⑤のいずれかに該当する行為を行ったとき
            </Typography>
            <Box sx={boxStyle}>
              <Typography paragraph sx={marginBottom2Style}>
                ① 暴力的な要求行為
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ② 法的な責任を超えた不当な要求行為
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ③ 取引に関して、脅迫的な言動をし、または暴力を用いる行為
              </Typography>
              <Typography paragraph sx={marginBottom2Style}>
                ④
                風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為
              </Typography>
              <Typography paragraph sx={marginBottom5Style}>
                ⑤ その他①ないし④のいずれかに準ずる行為
              </Typography>
            </Box>
          </Box>
          <Typography paragraph sx={marginBottom2Style}>
            2.当社は前項各号にかかわらず、会員登録の継続が困難と認めたときは、店舗会員に対し、書面またはメールによる催告の上、会員登録を解除することができます。
          </Typography>

          <Typography paragraph sx={TitleTypographyStyle}>
            第14条（規約の変更）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom2Style}>
              1.当社は、本規約を変更できるものとし、当社が任意に定めた効力発生日から変更後の本規約の効力が発生するものとします。
            </Typography>
            <Typography paragraph sx={marginBottom2Style}>
              2.前項の場合、当社は、効力発生日の1週間以上前の相当な時期までに、店舗会員に対して、本規約の変更の内容及び効力発生日を通知いたします。
              但し、当該変更による店舗会員の不利益の程度が軽微であると当社が判断した場合、その期間を短縮することができるものとします。
            </Typography>
            <Typography paragraph sx={marginBottom5Style}>
              3.前項の規定は、本規約の変更が店舗会員の一般の利益に適合する場合には適用しないものとします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第15条（専属的合意管轄裁判所）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              当社と店舗会員の間で訴訟の必要が生じた場合は、訴額に応じて東京簡易裁判所または東京地方裁判所を専属的合意管轄裁判所とします。
            </Typography>
          </Box>

          <Typography paragraph sx={TitleTypographyStyle}>
            第16条（協議）
          </Typography>
          <Box sx={boxStyle}>
            <Typography paragraph sx={marginBottom5Style}>
              本規約に定めのない事項及び本規約の各条項について疑義が生じた場合、当社及び店舗会員が誠意をもって協議し、解決するものとします。
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography paragraph sx={{ mt: 10, my: 8 }}>
          最終改正：2023年 12 月 11日
          <br />
          株式会社F.lab
          <br />
          東京都港区赤坂3-11-15 赤坂見附4階
          <br />
          代表取締役 藤田貴道
        </Typography>
      </Box>
    </Container>
  );
}
