import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { sendVerificationEmail } from '../../adapter/user-service';
import {
  snackbarSeverityState,
  snackbarOpenState,
  snackbarTextState,
  userAuthInfoSelector,
} from '../../domain/app';
import { getVerifyToken, verifyEmailToken } from '../../domain/network-actions';

export function ResendRegistrationEmail(): ReactElement {
  const navigate = useNavigate();
  const theme = useTheme();
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarSeverity = useSetRecoilState(snackbarSeverityState);
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    const token = getVerifyToken();
    if (_.isEmpty(token)) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    verifyEmailToken(token).then((data) => {
      if (data.status === 204) {
        setSnackbarSeverity('success');
        setSnackbarText('Email verification completed, please login');
        navigate('/login ');
      } else {
        setSnackbarSeverity('error');
        setSnackbarText('Email verification failed');
      }
      setSnackbarOpen(true);
    });
  }, [navigate, setSnackbarOpen, setSnackbarSeverity, setSnackbarText]);

  useEffect(() => {
    sendVerificationEmail(userAuthInfoState.id)
      .then(() => {
        setSnackbarSeverity('success');
        setSnackbarText('メールを送信しました');
      })
      .catch(() => {
        setSnackbarSeverity('error');
        setSnackbarText('メール送付失敗しました');
      });
    setSnackbarOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardContentComponent = (
    <Stack sx={{ textAlign: 'center' }}>
      <Box>
        <Typography fontWeight={600} variant="h6">
          ご登録ありがとうございます
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2.5 }}></Box>
      <Box
        sx={{
          '& > *': {
            margin: '10px',
          },
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          justifyItems: 'center',
        }}
      >
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="tantySubText">
            ご登録いただいたメールアドレス宛に本登録手続き用メールを送信しました。
          </Typography>
          <br />
          <br />
          <Typography variant="tantySubText">
            メール本文に記述されているURLをクリックして頂き、本登録手続画面より手続きを行ってください。
          </Typography>
        </Box>
      </Box>
    </Stack>
  );

  return (
    <Container
      sx={{
        ...(isMd
          ? { maxWidth: '512px !important' }
          : {
              bgcolor: theme.customPalette.white,
              maxWidth: '450px !important',
              py: 5,
            }),
      }}
    >
      {isMd ? (
        <Card sx={{ mt: 5, py: 5 }}>
          <CardContent>{CardContentComponent}</CardContent>
        </Card>
      ) : (
        <Box sx={{ mt: 8, py: 5 }}>{CardContentComponent}</Box>
      )}
    </Container>
  );
}
