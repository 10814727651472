import {
  Select,
  MenuItem,
  Box,
  SelectProps,
  BoxProps,
  useTheme,
  Typography,
  FormHelperText,
} from '@mui/material';
import { forwardRef } from 'react';

export interface TimeSelectProps extends Omit<BoxProps, 'onChange'> {
  disabled?: boolean;
  /** 選択内容の終了時間（HH:MM","HH:MM:SS"形式） */
  endTime?: string;
  error?: SelectProps['error'];
  /** ヘルプテキスト */
  helperText?: string;
  /** 時間選択のプロパティ */
  hourProps?: SelectProps<string>;
  /** 空白選択を入れるかどうか */
  isEmpty?: boolean;
  /** 分間隔 */
  minuteInterval?: number;
  /** 分選択のプロパティ */
  minutesProps?: SelectProps<string>;
  /**
   * 値変更時のイベント
   * @param value "HH:MM:SS"形式、未選択なら空白
   * @returns
   */
  onChange?: (value: string) => void | Promise<void>;
  size?: SelectProps['size'];
  /** 選択内容の開始時間（"HH:MM","HH:MM:SS"形式） */
  startTime?: string;
  /** 値（HH:MM","HH:MM:SS"形式、未選択なら空白） */
  value?: string;
}

// MEMO:ReactHookFormのControllerで使用する場合forwardRefでrefを渡せるようにしないとWarningとなる。
export const TimeSelect = forwardRef(function (
  {
    startTime,
    endTime,
    hourProps,
    minutesProps,
    minuteInterval,
    value,
    isEmpty,
    size,
    error,
    onChange,
    helperText,
    disabled,
    ...boxProps
  }: TimeSelectProps,
  ref
) {
  const valueArray = value?.split(':');
  const selectHour = valueArray?.[0] ? Number(valueArray[0]).toString() : '';
  const selectMinutes = valueArray?.[1] ? Number(valueArray[1]).toString() : '';

  const startTimeArray = startTime?.split(':') ?? [];
  const startHour = Number(startTimeArray[0] ?? 0);
  const startMinutes = Number(startTimeArray[1] ?? 0);
  const endTimeArray = endTime?.split(':') ?? [];
  const endHour = Number(endTimeArray[0] ?? 23);
  const endMinutes = Number(endTimeArray[1] ?? 55);

  const hourList = Array.from(Array(endHour - startHour + 1).keys()).map(
    (item) => startHour + item
  );

  const getMonthList = (selectHour: string) => {
    const minuteInterval2 = minuteInterval ?? 5;
    return Array.from(Array(Math.ceil(60 / (minuteInterval2 ?? 5))).keys())
      .map((item) => minuteInterval2 * item)
      .filter((item) => {
        if (startHour === Number(selectHour) && startMinutes > item) {
          return false;
        }
        if (endHour === Number(selectHour) && endMinutes < item) {
          return false;
        }
        return true;
      });
  };

  const minutesList = getMonthList(selectHour);

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={0.5}
        color={disabled ? theme.palette.grey[500] : undefined}
        {...boxProps}
      >
        <Select
          inputRef={ref as React.Ref<unknown>}
          size={size}
          error={error}
          fullWidth
          disabled={disabled}
          {...hourProps}
          sx={[
            {
              backgroundColor: theme.palette.background.default,
              width: '5rem',
            },
            // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
            ...(hourProps?.sx
              ? Array.isArray(hourProps.sx)
                ? hourProps.sx
                : [hourProps.sx]
              : []),
          ]}
          MenuProps={{
            ...hourProps?.MenuProps,
            sx: {
              maxHeight: '25rem',
              ...(hourProps?.MenuProps?.sx
                ? Array.isArray(hourProps?.MenuProps.sx)
                  ? hourProps?.MenuProps.sx
                  : [hourProps?.MenuProps.sx]
                : []),
            },
          }}
          value={selectHour}
          onChange={async (e) => {
            const minutesList = getMonthList(e.target.value);
            const minutes =
              startHour === Number(e.target.value) &&
              startMinutes > Number(selectMinutes)
                ? minutesList[0].toString()
                : endHour === Number(e.target.value) &&
                  endMinutes < Number(selectMinutes)
                ? [...minutesList].reverse()[0].toString()
                : selectMinutes;
            await onChange?.(
              `${e.target.value.padStart(2, '0')}:${minutes.padStart(
                2,
                '0'
              )}:00`
            );
          }}
          displayEmpty
        >
          {(isEmpty || !selectHour) && <MenuItem value={''}>{'　'}</MenuItem>}
          {hourList.map((hour) => (
            <MenuItem value={hour.toString()} key={hour}>
              {hour.toString().padStart(2, '0')}
            </MenuItem>
          ))}
        </Select>
        <Typography>:</Typography>
        <Select
          size={size}
          error={error}
          fullWidth
          disabled={disabled}
          {...minutesProps}
          sx={[
            {
              backgroundColor: theme.palette.background.default,
              width: '5rem',
            },
            // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
            ...(minutesProps?.sx
              ? Array.isArray(minutesProps.sx)
                ? minutesProps.sx
                : [minutesProps.sx]
              : []),
          ]}
          MenuProps={{
            ...minutesProps?.MenuProps,
            sx: {
              maxHeight: '25rem',
              ...(minutesProps?.MenuProps?.sx
                ? Array.isArray(minutesProps?.MenuProps.sx)
                  ? minutesProps?.MenuProps.sx
                  : [minutesProps?.MenuProps.sx]
                : []),
            },
          }}
          value={selectMinutes}
          onChange={async (e) => {
            await onChange?.(
              `${selectHour.padStart(2, '0')}:${e.target.value.padStart(
                2,
                '0'
              )}:00`
            );
          }}
          displayEmpty
        >
          {(isEmpty || !selectMinutes) && (
            <MenuItem value={''}>{'　'}</MenuItem>
          )}
          {minutesList.map((minutes) => (
            <MenuItem value={minutes.toString()} key={minutes}>
              {minutes.toString().padStart(2, '0')}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {helperText && (
        <FormHelperText error={error} sx={{ ml: 2 }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
});
