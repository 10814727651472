import { format as dateFnsFormat } from 'date-fns';
import dateFnsJa from 'date-fns/locale/ja';

import { Order, OrderLocalized } from '../types/order';
import { localizedOrderStatus } from '../utils/constants';

export function convertOrderLocalized(item: Order) {
  const a = {
    cancelReason: item.cancelReason || '',
    closedAt: item.closedAt,
    createdAt: dateFnsFormat(
      new Date(item.createdAt),
      'yyyy年M月d日(E) HH:mm',
      { locale: dateFnsJa }
    ),
    currency: item.currency,
    customFields: item.customFields,
    customer: item.customer,
    gender: item.gender,
    id: item.id,
    lineItem: item.lineItems[0],
    localizedStatus: localizedOrderStatus[item.status],
    name: item.name,
    organization: item.organization,
    status: item.status,
    subTotalPrice: item.subTotalPrice,
    totalTax: item.totalTax,
  } as OrderLocalized;
  return a;
}
