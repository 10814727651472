import { Box, Tabs, Tab, Typography, Grid } from '@mui/material';

type TabsComponentProps = {
  acceptedCount: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  pendingCount: number;
  processingCount: number;
  value: number;
  waitingCount: number;
};

export const TabsComponent: React.FC<TabsComponentProps> = ({
  value,
  onChange,
  processingCount,
  acceptedCount,
  pendingCount,
  waitingCount,
}) => {
  function a11yProps(index: number) {
    return {
      'aria-controls': `simple-tabpanel-${index}`,
      id: `simple-tab-${index}`,
    };
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={onChange} aria-label="basic tabs example">
        <Tab label={`応募中 (${pendingCount})`} {...a11yProps(0)} />
        <Tab label={`来店予定 (${acceptedCount})`} {...a11yProps(1)} />
        <Tab label={`投稿待ち (${processingCount})`} {...a11yProps(2)} />
        {/* 投稿確認タブ(暫定) */}
        <Tab label={`投稿確認 (${waitingCount})`} {...a11yProps(3)} />
        <Tab label="すべて" {...a11yProps(4)} />
      </Tabs>
    </Box>
  );
};

interface CustomTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const CustomTabPanel: React.FC<CustomTabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </Grid>
  );
};
