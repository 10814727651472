import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  ListItem,
  List,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { EquipmentCheckModal } from './EquipmentCheckModal';
import { ProgressStepper } from './ProgressStepper';

import {
  getLocationByLevel,
  getLocationByParentId,
  getAttributesByName,
} from '@app/adapter/catalog-service';
import {
  cityDataAtom,
  prefectureDataAtom,
  registerState,
  selectedCityDataAtom,
  selectedPrefectureDataAtom,
  userState,
} from '@app/domain/register';
import { UploadFile } from '@app/routes/UploadFile';
import {
  OrganizationFormData,
  RegistrationForm,
} from '@app/schemas/registration';
import { SearchResult } from '@app/types/organization';
import { convertKana } from '@app/utils/format';
import { resizeImageURL } from '@app/utils/image';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

interface OrganizationFormProps {
  onSubmit: (formData: OrganizationFormData) => void;
}

/**
 * blocks-5f90
 *
 * A form to let new vendors put their information.
 * After they submit the information, tanty admins will give them checkups.
 */
export function OrganizationForm({
  onSubmit,
}: OrganizationFormProps): ReactElement {
  const userInfo = useRecoilValue(userState);
  const [registerData, setRegisterData] = useRecoilState(registerState);

  const { control, watch, handleSubmit, formState, setValue } =
    useForm<OrganizationFormData>({
      defaultValues: registerData,
      mode: 'onChange',
      resolver: RegistrationForm.resolver,
    });

  const theme = useTheme();
  const setSnackbar = useSetSnackbar();
  const { errors, isValid } = formState;
  const shouldSkipValidation = watch('shouldSkipValidation');
  const [prefectureData, setPrefectureData] =
    useRecoilState(prefectureDataAtom);
  const [cityData, setCityData] = useRecoilState(cityDataAtom);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [filterKeyword, setFilterKeyword] = useState<string>('');
  const [selectedItemName, setSelectedItemName] = useState<string>('');
  const [genreError, setGenreError] = useState<string | null>(null);

  const [, setSelectedPrefectureData] = useRecoilState(
    selectedPrefectureDataAtom
  );
  const setSelectedCityData = useSetRecoilState(selectedCityDataAtom);

  const watchAddressLine1 = watch('addressLine1');

  const performSearch = useCallback(
    async (keyword: string) => {
      if (!keyword.trim()) {
        setSearchResults([]);
        return;
      }
      const normalizedHiragana = convertKana(keyword);
      const normalizedKatakana =
        keyword === normalizedHiragana
          ? convertKana(normalizedHiragana)
          : keyword;

      const namesToSearch = [normalizedHiragana, normalizedKatakana].filter(
        (value, index, self) => self.indexOf(value) === index
      );

      const filterQuery = {
        groupName: 'corporation',
        name: namesToSearch,
      };

      try {
        const response = await getAttributesByName({
          filter: filterQuery,
        });
        setSearchResults(response.data.value);
      } catch (error) {
        console.error('検索中にエラーが発生しました:', error);
        setSnackbar(true, '検索中にエラーが発生しました', 'error');
      }
    },
    [setSnackbar]
  );

  useEffect(() => {
    void performSearch(filterKeyword);
  }, [filterKeyword, performSearch]);

  const handleSelectOrganization = useCallback(
    (item: SearchResult) => {
      setRegisterData((prevData) => ({
        ...prevData,
        description: item.id,
        descriptionName: item.name,
      }));
      setSelectedItemName(item.name);
      setFilterKeyword('');
      setSearchResults([]);
    },
    [setRegisterData]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLocationByLevel();
        if (response.data?.value) {
          setPrefectureData(
            response.data.value.map(({ id, name }) => ({ id, name }))
          );
        }
      } catch (error) {
        console.error('Failed to fetch prefecture data:', error);
      }
    };
    void fetchData();
  }, [setPrefectureData]);

  const handlePrefectureChange = async (e?: SelectChangeEvent<string>) => {
    if (!e) {
      const response = await getLocationByLevel();
      if (response.data?.value) {
        setPrefectureData(
          response.data.value.map(({ id, name }) => ({ id, name }))
        );
      }
      return;
    }
    const selectedId = e.target.value as string;
    const selectedPrefecture = prefectureData.find(
      (pref) => pref.id === selectedId
    );
    // 都道府県の再選択時に市町村の選択を初期化
    setValue('addressLine2', '');
    setCityData([]);

    setRegisterData((prev) => ({
      ...prev,
      addressLine1: selectedPrefecture ? selectedPrefecture.id : '',
    }));
    if (selectedPrefecture) {
      setSelectedPrefectureData(selectedPrefecture);
    }
    try {
      const response = await getLocationByParentId(selectedId);
      if (response.data?.value) {
        setCityData(response.data.value.map(({ id, name }) => ({ id, name })));
      }
    } catch (error) {
      console.error('Failed to fetch city data:', error);
    }
  };

  const handleCityChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCityId = e.target.value;
    const selectedCity = cityData.find((city) => city.id === selectedCityId);
    if (!selectedCity) {
      console.warn('[OrganizationForm] handleCityChange() unexpected city id', {
        cityData,
        selectedCityId,
      });
      return;
    }
    setSelectedCityData(selectedCity);
    const selectedPrefectureName =
      prefectureData.find((pref) => pref.id === watchAddressLine1)?.name || '';
    const fullAddress = `${selectedPrefectureName}${selectedCity.name}`;

    // 連結した都道府県と市区郡をcustomField.addressByCityに格納
    setRegisterData((prev) => ({
      ...prev,
      addressLine2: selectedCity?.id as string,
      customFields: {
        ...prev.customFields,
        addressByCity: fullAddress,
      },
    }));
    setValue('customFields.addressByCity', fullAddress);
  };

  const submit = useCallback(
    (data: OrganizationFormData) => {
      if (registerData.customFields.genre.length === 0) {
        setGenreError('ジャンルを1つ以上選択してください');
        return;
      }
      setRegisterData((prev) => ({
        ...prev,
        ...data,
        addressLine2: registerData.addressLine2,
        customFields: {
          ...prev.customFields,
          ...data.customFields,
          genre: prev.customFields.genre,
        },
      }));
      onSubmit(data);
    },
    [
      onSubmit,
      registerData.addressLine2,
      setRegisterData,
      registerData.customFields.genre.length,
    ]
  );

  const handleSelectedEquipments = (genre: string[]) => {
    setRegisterData((prevData) => {
      const newData = {
        ...prevData,
        customFields: {
          ...prevData.customFields,
          genre,
        },
      };
      return newData;
    });
    if (genre.length === 0) {
      setGenreError('ジャンルを1つ以上選択してください');
    } else {
      setGenreError(null);
    }
  };

  return (
    <Box>
      <ProgressStepper activeStep={1} />
      <Typography
        variant="h6"
        fontWeight={700}
        textAlign="center"
        sx={{ mb: 5 }}
      >
        店舗の登録
      </Typography>
      <Typography
        variant="body2"
        textAlign="left"
        gutterBottom
        component="span"
      >
        店舗の情報をご記入ください。登録後、すぐに利用開始できます。
        <br />
        <Grid sx={{ display: 'flex' }}>
          <Typography component="span" color="error" sx={{ mb: 5 }}>
            *
          </Typography>
          は必須事項です
        </Grid>
      </Typography>

      <form
        onSubmit={handleSubmit(submit, (errors) => {
          console.log(errors);
        })}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  法人名
                </Typography>
              </FormLabel>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={selectedItemName || field.value}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setFilterKeyword(newValue);
                      setSelectedItemName('');
                      setRegisterData((prevData) => ({
                        ...prevData,
                        description: '',
                        descriptionName: newValue,
                      }));
                      field.onChange(newValue);
                    }}
                    error={!!error}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'法人名を入力'}
                  />
                )}
              />
              {searchResults.length > 0 && !selectedItemName && (
                <List
                  sx={{
                    '& ul': { padding: 0 },
                    bgcolor: 'background.paper',
                    border: `solid 1px ${theme.customPalette.borderColor}`,
                    maxHeight: 300,
                    overflow: 'auto',
                    position: 'relative',
                    width: '80%',
                  }}
                  subheader={<li />}
                >
                  {searchResults.map((item, itemIndex) => (
                    <ListItem
                      key={`item-${item.id}-${itemIndex}`}
                      button
                      onClick={() => handleSelectOrganization(item)}
                      style={{ alignItems: 'center', display: 'flex' }}
                    >
                      <Grid style={{ alignItems: 'center', display: 'flex' }}>
                        <Typography style={{ marginRight: '8px' }}>
                          {item.name}
                        </Typography>
                        {item.description && (
                          <Typography>({item.description})</Typography>
                        )}
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  店舗名
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="name"
                control={control}
                defaultValue={registerData.name}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={registerData.name}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        name: e.target.value,
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="店舗名を入力"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  連絡先電話番号
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue={registerData.phoneNumber}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={registerData.phoneNumber}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        phoneNumber: e.target.value,
                      });
                      field.onChange(e);
                    }}
                    type="tel"
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="連絡先電話番号（ハイフンなし）で入力"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid container sx={{ alignItems: 'center', pt: 3, px: 3 }}>
            <Grid item xs={9}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    display={'flex'}
                    component="span"
                  >
                    住所
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography>
                </FormLabel>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <Controller
                      name="postalCode"
                      control={control}
                      defaultValue={registerData.postalCode || ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          value={registerData.postalCode}
                          onChange={(e) => {
                            setRegisterData({
                              ...registerData,
                              postalCode: e.target.value,
                            });
                            field.onChange(e);
                          }}
                          error={error ? true : false}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="郵便番号を入力"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {errors.postalCode &&
                errors.postalCode.type !== 'invalid_string' ? (
                  <FormHelperText error>
                    正しい郵便番号を入力してください
                  </FormHelperText>
                ) : undefined}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ pt: 3, px: 4 }} alignItems="center">
            <Box sx={{ flex: '1 1 auto', pl: 2, pt: 0.5 }}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="addressLine1"
                  control={control}
                  rules={{ required: '都道府県を選択してください' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="outlined" error={!!error}>
                      <Select
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          void handlePrefectureChange(e);
                        }}
                        onOpen={() => {
                          void handlePrefectureChange();
                        }}
                        margin="dense"
                        displayEmpty
                        renderValue={(selectedId) => {
                          const selectedName =
                            prefectureData.find(
                              (pref) => pref.id === selectedId
                            )?.name || '';
                          if (selectedName) {
                            return selectedName;
                          }
                          return (
                            <span
                              style={{ color: theme.customPalette.lightGray }}
                            >
                              都道府県を選択
                            </span>
                          );
                        }}
                      >
                        <MenuItem value="" disabled>
                          都道府県を選択
                        </MenuItem>
                        {prefectureData.map((prefecture) => (
                          <MenuItem key={prefecture.id} value={prefecture.id}>
                            {prefecture.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: '1 1 auto', pl: 1 }}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="addressLine2"
                  control={control}
                  defaultValue={registerData.addressLine2}
                  render={({ field, fieldState: { error } }) =>
                    registerData.addressLine1 && cityData.length === 0 ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                        <Typography ml={2}>更新中</Typography>
                      </Box>
                    ) : (
                      <TextField
                        {...field}
                        value={cityData.length > 0 ? field.value : ''}
                        onChange={(e) => {
                          handleCityChange(e);
                          field.onChange(e);
                        }}
                        error={error ? true : false}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="市町村を選択"
                        select
                      >
                        {cityData.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid container spacing={3} sx={{ px: 3 }}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="addressLine3"
                  control={control}
                  defaultValue={registerData.addressLine3}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      onChange={(e) => {
                        setRegisterData({
                          ...registerData,
                          addressLine3: e.target.value,
                        });
                        field.onChange(e);
                      }}
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder="町名・番地を入力"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ px: 3 }}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="customFields.addressLine4"
                  control={control}
                  defaultValue={registerData.customFields.addressLine4}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        const updatedCustomFields = {
                          ...registerData.customFields,
                          addressLine4: e.target.value,
                        };
                        setRegisterData({
                          ...registerData,
                          customFields: updatedCustomFields,
                        });
                        field.onChange(e);
                      }}
                      margin="dense"
                      placeholder="建物名・部屋番号を入力"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  アクセス情報
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.access"
                control={control}
                defaultValue={registerData.customFields.access}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          access: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="アクセス情報を入力   例）JR山手線新宿駅から徒歩5分"
                    multiline
                    rows={2}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  店舗のWebページ
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.clinicUrl"
                control={control}
                defaultValue={registerData.customFields.clinicUrl}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          clinicUrl: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="https://www.tieup.com"
                    multiline
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  店長名
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setValue('shouldSkipValidation', isChecked);
                      if (isChecked) {
                        const ownerName = ` ${userInfo.customFields.familyName} ${userInfo.customFields.firstName}`;
                        setValue('customFields.ownerName', ownerName);
                        setRegisterData({
                          ...registerData,
                          customFields: {
                            ...registerData.customFields,
                            ownerName,
                          },
                        });
                      }
                    }}
                  />
                }
                label="担当者ユーザーの名前と同様"
              />
              <Controller
                name="customFields.ownerName"
                control={control}
                defaultValue={registerData.customFields.ownerName}
                rules={{
                  required: !shouldSkipValidation,
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          ownerName: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={!shouldSkipValidation && error ? true : false}
                    helperText={!shouldSkipValidation && error?.message}
                    margin="dense"
                    placeholder="店長名を入力"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              fontWeight={700}
              component="span"
            >
              ジャンル
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              sx={{ mt: 1 }}
            >
              <EquipmentCheckModal
                selectedItems={registerData.customFields.genre}
                onUpdate={handleSelectedEquipments}
              />
              {genreError && (
                <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                  {genreError}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  component="span"
                >
                  ジャンル（その他）
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.genreOther"
                control={control}
                defaultValue={registerData.customFields.genreOther}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          genreOther: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    margin="dense"
                    placeholder="その他のジャンルを入力"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={10} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  店舗イメージ・写真(サイズは最大5MBまで)
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="iconImageUri"
                control={control}
                defaultValue={registerData.iconImageUri}
                render={({ field, fieldState: { error } }) => (
                  <UploadFile
                    sx={{ padding: '10px 10px 10px 0' }}
                    fileUrl={field.value}
                    isError={error ? true : false}
                    helperText={error?.message}
                    setImageUrl={async (data: string) => {
                      const resizeUrl = await resizeImageURL(
                        data,
                        800,
                        534,
                        3 / 2
                      );
                      setRegisterData({
                        ...registerData,
                        iconImageUri: resizeUrl,
                      });
                      field.onChange(resizeUrl);
                    }}
                    addImageButtonText="画像をアップロード"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <Typography
                color="textPrimary"
                variant="subtitle2"
                fontWeight={700}
                display={'flex'}
                component="span"
              >
                店舗の一言アピール(200文字まで)
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="customFields.clinicAppealMessage"
                control={control}
                defaultValue={registerData.customFields.clinicAppealMessage}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          clinicAppealMessage: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="例：都内では数少ない喫煙席もご用意しております！"
                    multiline
                    rows={9}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Grid item sx={{ pb: 3 }}>
              <Typography
                component="span"
                sx={{ color: theme.customPalette.gray, fontWeight: 300 }}
              >
                送信いただく個人情報については、厳正な管理の下、プライバシーポリシーに従って利用させていただきます。
              </Typography>
            </Grid>
            <Controller
              name="userAgreement"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={error ? true : false}>
                  <FormControlLabel
                    label={
                      <Typography component="span" variant="body2">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/terms"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography
                            component="span"
                            color="primary"
                            variant="body2"
                          >
                            利用規約
                          </Typography>
                        </Link>
                        に同意
                      </Typography>
                    }
                    control={
                      <Checkbox
                        {...field}
                        checked={registerData.userAgreement}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setRegisterData({
                            ...registerData,
                            userAgreement: isChecked,
                          });
                          field.onChange(e);
                        }}
                      />
                    }
                  />
                  {error ? (
                    <FormHelperText sx={{ ml: 0 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: -2 }}>
            <Controller
              name="privacyPolicy"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={error ? true : false}>
                  <FormControlLabel
                    label={
                      <Typography component="span" variant="body2">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/privacy-policy"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography
                            component="span"
                            color="primary"
                            variant="body2"
                          >
                            プライバシーポリシー
                          </Typography>
                        </Link>
                        に同意
                      </Typography>
                    }
                    control={
                      <Checkbox
                        {...field}
                        checked={registerData.privacyPolicy}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setRegisterData({
                            ...registerData,
                            privacyPolicy: isChecked,
                          });
                          field.onChange(e);
                        }}
                      />
                    }
                  />
                  {error ? (
                    <FormHelperText sx={{ ml: 0 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <Grid item xs={12} sx={{ pt: 6, px: 3 }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={!isValid}
              >
                送信
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
