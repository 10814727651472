// Copyright 2022 Co-Lift Inc.
import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Typography,
  TextField,
  Grid,
  Divider,
  Button,
  InputAdornment,
  IconButton,
  Link as MUILink,
} from '@mui/material';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { setRequestInterceptor } from '../adapter/axios';
import { getOrganizationDetails } from '../adapter/organization-service';
import { getUser } from '../adapter/user-service';
import {
  loggedInUserState,
  userAuthInfoSelector,
  snackbarTextState,
  snackbarOpenState,
  afterLoginRouteState,
  useClearAuthStateAndStorage,
} from '../domain/app';
import { handleLogin } from '../domain/network-actions';
import { organization, allOrganizationsAtom } from '../domain/organization';
import { Organization } from '../types/organization';
import { storeAccessToken, storeUserId } from '../utils/auth';
import { isError } from '../utils/error';
import { isVendorUser } from '../utils/is_vendor_user';

import { InquiryFormLink } from '@app/components/Shared/InquiryFormLink';
import { socketMiddleware } from '@app/socket';
import { ERROR_MESSAGE } from '@app/utils/message';

interface IFormLoginInputs {
  email: string;
  password: string;
}

const loginForm = z.object({
  email: z.string().email({ message: 'メールアドレスを入力してください' }),
  password: z.string().min(1, { message: 'パスワードを入力してください' }),
});

const MAX_FETCH_ORGANIZATION_SIZE = 50;

export function Login() {
  const navigate = useNavigate();
  const setOrganization = useSetRecoilState(organization);
  const setAllOrganizations = useSetRecoilState(allOrganizationsAtom);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const [afterLoginRoute, setAfterLoginRoute] =
    useRecoilState(afterLoginRouteState);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();

  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormLoginInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    resolver: zodResolver(loginForm),
  });

  const [isLoading, setIsLoading] = useState(false);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onLogin = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        setIsLoading(true);
        const data = await handleLogin(email, password);
        const { accessToken, fingerprint } = data;
        setRequestInterceptor({ accessToken, fingerprint });

        const user = await getUser(data.id);

        if (!isVendorUser(user.data.typeId)) {
          throw new Error(ERROR_MESSAGE.INVALID_USER_TYPE);
        }

        if (!user.data.emailVerified) {
          navigate('/resend-email-verify');
          return;
        }
        if (user) {
          setLoggedInUser(user.data);
          setUserAuthInfo(data);

          storeAccessToken(data.accessToken);
          storeUserId(data.id);
        }

        // ログインしてたら常時socketの起動
        if (loggedInUser) {
          const userId = loggedInUser.id;
          void socketMiddleware(accessToken, fingerprint, userId);
        }

        const org = await getOrganizationDetails(data.id, {
          params: { $skip: 0, $top: MAX_FETCH_ORGANIZATION_SIZE }, // 先頭から取れるだけ取る
        });

        // 複数の Organization を切り替えられるようにするため
        const { total, value } = org.data;
        if (total > MAX_FETCH_ORGANIZATION_SIZE) {
          console.warn('店舗が多すぎるため、すべてを取得出来ません', org.data); // TODO: 仕様として登録可能な上限を決めるか, or paginateするか
        }
        if (Array.isArray(value)) {
          const allOrganizations = org?.data?.value;
          setAllOrganizations(allOrganizations);
        }

        if (!_.isEmpty(_.get(org, 'data.value', ''))) {
          const orgData = _.get(
            org,
            'data.value[0]'
          ) as unknown as Organization;
          setOrganization(orgData);
          if (orgData?.status !== 'normal') {
            navigate('/register/organization');
          } else if (afterLoginRoute) {
            navigate(afterLoginRoute);
            setAfterLoginRoute(null);
          } else {
            navigate('/');
          }
        } else {
          navigate('/register/organization');
        }
      } catch (error) {
        clearAuthStateAndStorage();
        let errorMessage = 'ログインに失敗しました';

        if (isError(error)) {
          if (error.message === 'email or password is wrong') {
            errorMessage = 'メールアドレスまたはパスワードが間違っています';
          } else {
            errorMessage += `, ${error.message}`;
          }
        }

        setSnackbarText(errorMessage);
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    },
    [
      setUserAuthInfo,
      setLoggedInUser,
      setOrganization,
      navigate,
      afterLoginRoute,
      setAfterLoginRoute,
      setSnackbarOpen,
      setSnackbarText,
      loggedInUser,
      setAllOrganizations,
      clearAuthStateAndStorage,
    ]
  );

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} lg={4}>
          <Card
            sx={{
              mb: 10,
              minWidth: '450px',
              pb: 5,
              pt: 5,
              textAlign: 'center',
            }}
          >
            <CardContent>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: '33.6px',
                }}
              >
                ログイン
              </Typography>
              <form onSubmit={handleSubmit(onLogin)}>
                <Grid container sx={{ mt: 5 }} justifyContent="center">
                  <Grid item xs={10}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      data-e2e="login-email-field"
                    >
                      <FormLabel>
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          fontWeight={600}
                          sx={{ textAlign: 'left' }}
                        >
                          メールアドレス
                        </Typography>
                      </FormLabel>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={error ? true : undefined}
                            helperText={
                              error ? errors.email?.message : undefined
                            }
                            margin="dense"
                            placeholder="メールアドレスを入力"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={10} sx={{ mt: 2 }}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      data-e2e="login-password-field"
                    >
                      <FormLabel>
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          fontWeight={600}
                          sx={{ textAlign: 'left' }}
                        >
                          パスワード
                        </Typography>
                      </FormLabel>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            type={isPasswordVisible ? 'text' : 'password'}
                            error={error ? true : undefined}
                            helperText={
                              error ? errors.password?.message : undefined
                            }
                            margin="dense"
                            placeholder="パスワードを入力"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handlePasswordVisibility}
                                    onMouseDown={(e) => e.preventDefault()}
                                  >
                                    {isPasswordVisible ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
                    <MUILink
                      component={Link}
                      to="/send_reset_password_email"
                      underline="none"
                      color={'#2F2F2F'}
                    >
                      パスワードを忘れた方
                    </MUILink>
                  </Grid>
                  <Grid item xs={10} sx={{ mt: 3 }}>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                      loading={isLoading}
                      data-e2e="login-button"
                      disabled={!isValid}
                      sx={{
                        '&:hover': {
                          backgroundColor: isValid
                            ? 'rgba(255, 244, 96, 0.8)'
                            : undefined,
                        },
                        backgroundColor: isValid ? '#FFD900' : undefined,
                      }}
                    >
                      ログイン
                    </LoadingButton>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                  <Divider sx={{ my: 3 }} />
                  <Typography
                    component="div"
                    align="center"
                    variant="body2"
                    sx={{
                      color: '#828A9B',
                      fontSize: 14,
                      fontWeight: 300,
                      lineHeight: '22.4px',
                    }}
                  >
                    まだ会員登録をされていない方
                  </Typography>
                  <Grid container sx={{ mt: 3 }} justifyContent="center">
                    <Grid item xs={10}>
                      <Button
                        type="submit"
                        variant="outlined"
                        fullWidth
                        sx={{
                          borderColor: '#DBDFE5',
                          color: '#2F2F2F',
                          fontWeight: 400,
                        }}
                        component={Link}
                        to="/register"
                      >
                        新規登録
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>

              {/* 問い合わせ */}
              <InquiryFormLink />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
