// Copyright 2022 Co-Lift Inc.
import { Backdrop, CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { EmailChangePage } from './components/AccountSetting/EmailChangePage';
import { LineCallback } from './components/AccountSetting/LineCallback';
import { NameChangePage } from './components/AccountSetting/NameChangePage';
import { PasswordChangePage } from './components/AccountSetting/PasswordChangePage';
import { ProductBulkEdit } from './components/Catalog/ProductBulkEdit';
import { ProductCreation } from './components/Catalog/ProductCreation';
import { ProductDetail } from './components/Catalog/ProductDetail';
import { ProductEditStatus } from './components/Catalog/ProductEditStatus';
import { TemplateCopyEdit } from './components/Chat/TemplateCopyEdit';
import { TemplateEdit } from './components/Chat/TemplateEdit';
import { TemplateList } from './components/Chat/TemplateList';
import { TemplateRegister } from './components/Chat/TemplateRegister';
import { DefaultLayout } from './components/Layout/DefaultLayout';
import { VendorLayout } from './components/Layout/VendorLayout';
import { LoginCallback } from './components/Login/LoginCallback';
import { DeletedOrders } from './components/Orders/DeletedOrders';
import { ConfirmedUserRoute } from './components/PrivateRoutes/ConfirmedUserRoute';
import { OrganizationUserRoute } from './components/PrivateRoutes/OrganizationUserRoute';
import { UnconfirmedUserRoute } from './components/PrivateRoutes/UnconfirmedUserRoute';
import { PrivacyPolicyPage } from './components/Registration/Confirmation/PrivacyPolicyPage';
import { TermsPage } from './components/Registration/Confirmation/TermsPage';
import { PostRegistrationMessage } from './components/Registration/PostRegistrationMessage';
import { ResendRegistrationEmail } from './components/Registration/ResendRegistrationEmail';
import { WaitingForResetPassword } from './components/WaitingResetPassword/WaitingForResetPassword';
import { AcceptInvitation } from './routes/AcceptInvitation';
import { AccountSetting } from './routes/AccountSetting';
//import { AdminInfo } from './routes/AdminInfo';
//import { Billing } from './routes/Billing';
import { ChatDetail } from './routes/ChatDetail';
import { ChatList } from './routes/ChatList';
import { Home } from './routes/Home';
import { Login } from './routes/Login';
import { Notification } from './routes/Notification';
import { OrderDetails } from './routes/OrderDetails';
import { Orders } from './routes/Orders';
//import { Payment } from './routes/Payment';
import { ProductManagement } from './routes/ProductManagement';
import { Register } from './routes/Register';
import { RegistrationConfirmation } from './routes/RegistrationConfirmation';
import { SendResetPasswordEmail } from './routes/SendResetPasswordEmail';
import { UserDetails } from './routes/UserDetails';
import { Users } from './routes/Users';

import { ClinicCreateEdit } from '@app/routes/ClinicCreateEdit';
import { ClinicDetail } from '@app/routes/ClinicDetail';
import { ClinicList } from '@app/routes/ClinicList';
import { NotFound } from '@app/routes/NotFound';

export function App() {
  return (
    <RecoilRoot>
      <Suspense
        fallback={
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <Router>
          <Routes>
            <Route path="/">
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/terms" element={<TermsPage />} />
              {/* Anonymous users */}
              <Route element={<DefaultLayout />}>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/login-callback/:provider"
                  element={<LoginCallback />}
                />
                <Route
                  path="/waiting-email-verify"
                  element={<PostRegistrationMessage />}
                />
                <Route path="/register">
                  <Route index element={<Register />} />
                </Route>

                <Route path="/line/callback" element={<LineCallback />} />
                <Route
                  path="/send_reset_password_email"
                  element={<SendResetPasswordEmail />}
                />
                <Route
                  path="/waiting_reset_password"
                  element={<WaitingForResetPassword />}
                />
                <Route
                  path="/accept_invitation"
                  element={<AcceptInvitation />}
                />
                <Route path="*" element={<NotFound />}></Route>
              </Route>
              {/* Unconfirmed users */}
              <Route
                element={
                  //TODO: REFACTOR SEPARATE LOGIC FROM ROUTING
                  <UnconfirmedUserRoute>
                    <DefaultLayout />
                  </UnconfirmedUserRoute>
                }
              ></Route>
              {/* Confirmed users who need to create their organization */}
              <Route
                element={
                  //TODO: REFACTOR SEPARATE LOGIC FROM ROUTING
                  <ConfirmedUserRoute>
                    <DefaultLayout />
                  </ConfirmedUserRoute>
                }
              >
                <Route
                  path="/register/organization"
                  element={<RegistrationConfirmation />}
                />
                <Route
                  path="/resend-email-verify"
                  element={<ResendRegistrationEmail />}
                />
              </Route>
              {/* Confirmed users who already have their organization */}
              <Route
                element={
                  //TODO: REFACTOR SEPARATE LOGIC FROM ROUTING
                  <OrganizationUserRoute>
                    <VendorLayout />
                  </OrganizationUserRoute>
                }
              >
                <Route index element={<Home />}></Route>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/product">
                  <Route index element={<ProductManagement />} />
                  <Route path="create" element={<ProductCreation />}></Route>
                  <Route
                    path="edit/status"
                    element={<ProductEditStatus />}
                  ></Route>
                  <Route path="view" element={<ProductDetail />}></Route>
                  <Route path="bulk" element={<ProductBulkEdit />}></Route>
                </Route>

                {/* 店舗 (Administratorと揃える) */}
                <Route path="/shop">
                  <Route index element={<ClinicList />} />
                  <Route path="create" element={<ClinicCreateEdit />} />
                  <Route path=":orgId">
                    <Route path="view" element={<ClinicDetail />} />
                    <Route path="edit" element={<ClinicCreateEdit />} />
                  </Route>
                </Route>

                {/*
                    現時点で未使用
                    <Route path="/admin-info" element={<AdminInfo />}></Route> 
                    <Route path="/billing" element={<Billing />}></Route>
                    <Route path="/payment" element={<Payment />}></Route>
                  */}

                <Route path="/notification" element={<Notification />}></Route>
                <Route path="/chat" element={<ChatList />}></Route>
                <Route path="/chats/:topicId" element={<ChatDetail />}></Route>
                <Route
                  path="/chat/templateList"
                  element={<TemplateList />}
                ></Route>
                <Route
                  path="/chat/templateList/register"
                  element={<TemplateRegister />}
                ></Route>
                <Route
                  path="/chat/templateList/edit/:templateId"
                  element={<TemplateEdit />}
                ></Route>
                <Route
                  path="/chat/templateList/copyedit/:templateId"
                  element={<TemplateCopyEdit />}
                ></Route>
                <Route
                  path="/account-setting"
                  element={<AccountSetting />}
                ></Route>
                <Route
                  path="/account-setting/password-change"
                  element={<PasswordChangePage />}
                />
                <Route
                  path="/account-setting/name-change"
                  element={<NameChangePage />}
                />
                <Route
                  path="/account-setting/email-change"
                  element={<EmailChangePage />}
                />
                <Route path="/orders" element={<Orders />}></Route>
                <Route
                  path="/orders/deleted"
                  element={<DeletedOrders />}
                ></Route>
                <Route path="/orders/:id" element={<OrderDetails />}></Route>
                <Route path="/users" element={<Users />}></Route>
                <Route path="/users/:id" element={<UserDetails />}></Route>
              </Route>
            </Route>
          </Routes>
        </Router>
      </Suspense>
    </RecoilRoot>
  );
}
