import { atom } from 'recoil';

import {
  OrganizationFormData,
  ORGANIZATION_FORM_DEFAULT_VALUES,
  UserInfoFormData,
} from '../schemas/registration';

export const userState = atom<UserInfoFormData>({
  default: {
    customFields: {
      familyName: '',
      familyNameKana: '',
      firstName: '',
      firstNameKana: '',
    },
  },
  key: 'userState',
});

export const registerState = atom<OrganizationFormData>({
  default: ORGANIZATION_FORM_DEFAULT_VALUES,
  key: 'registerState',
});

export const prefectureDataAtom = atom<{ id: string; name: string }[]>({
  default: [],
  key: 'prefectureDataAtom',
});

export const cityDataAtom = atom<{ id: string; name: string }[]>({
  default: [],
  key: 'cityDataAtom',
});

export const selectedPrefectureDataAtom = atom<{
  id: string;
  name: string;
} | null>({
  default: null,
  key: 'selectedPrefectureDataAtom',
});

export const selectedCityDataAtom = atom<{ id: string; name: string } | null>({
  default: null,
  key: 'selectedCityDataAtom',
});
