import { Grid, useTheme } from '@mui/material';
import React, { FC } from 'react';

import facebookLogo from '@app/assets/Facebook_Logo.png';
import GoogleLogo from '@app/assets/google.png';
import { ReactComponent as InstagramIcon } from '@app/assets/instagram-logo.svg';
import TikTokLogo from '@app/assets/tiktok-logo-main.png';
import { ReactComponent as XIcon } from '@app/assets/x-logo.svg';

interface SocialMediaIconsProps {
  urls: string[];
}

interface IconWithOverlayProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<Element>) => void;
  style: React.CSSProperties;
  url: string | undefined;
}

const IconWithOverlay: React.FC<IconWithOverlayProps> = ({
  url,
  style,
  children,
  onClick,
}) => {
  const overlayStyle: React.CSSProperties = {
    backgroundColor: url ? 'transparent' : 'white',
    height: '100%',
    opacity: url ? 0 : 0.93,
    position: 'absolute',
    width: '100%',
  };

  const imageStyle: React.CSSProperties = {
    height: '100%',
    objectFit: 'contain',
    width: '100%',
  };

  const containerStyle: React.CSSProperties = {
    cursor: url ? 'pointer' : 'default',
    height: style.height,
    position: 'relative',
    width: style.width,
  };

  return (
    <div style={containerStyle} onClick={onClick}>
      <div style={{ ...style, position: 'absolute' }}>
        {React.cloneElement(children as React.ReactElement, {
          style: imageStyle,
        })}
      </div>
      <div style={overlayStyle} />
    </div>
  );
};

export const SocialMediaIcons: FC<SocialMediaIconsProps> = ({ urls }) => {
  const theme = useTheme();
  const twitterUrl = urls?.find(
    (url) => url.includes('twitter.com') || url.includes('x.com')
  );
  const instagramUrl = urls?.find((url) => url.includes('instagram.com'));
  const tiktokUrl = urls?.find((url) => url.includes('tiktok.com'));
  const facebookUrl = urls?.find((url) => url.includes('facebook.com'));
  const googleUrl = urls?.find(
    (url) =>
      url.includes('g.co') ||
      url.includes('google.com') ||
      url.includes('maps.app.goo.gl') ||
      url.includes('maps.app')
  );

  const handleIconClick = (event: React.MouseEvent<Element>, url?: string) => {
    event.stopPropagation();
    if (url) {
      window.open(url, '_blank');
    }
  };

  const getIconStyle = (url: string | undefined) => ({
    color: url ? 'inherit' : theme.customPalette.silver,
    cursor: url ? 'pointer' : 'default',
  });

  return (
    <Grid style={{ display: 'flex', wordWrap: 'break-word' }}>
      <XIcon
        style={{
          ...getIconStyle(twitterUrl),
          height: '23px',
          marginTop: 3,
          paddingRight: 3,
          width: '23px',
        }}
        onClick={(e) => handleIconClick(e, twitterUrl)}
      />
      <IconWithOverlay
        url={instagramUrl}
        style={{ height: '28px', paddingRight: '3px', width: '28px' }}
        onClick={(e) => handleIconClick(e, instagramUrl)}
      >
        <InstagramIcon />
      </IconWithOverlay>
      <IconWithOverlay
        url={tiktokUrl}
        style={{
          height: '28px',
          marginBottom: 7,
          paddingRight: '3px',
          width: '28px',
        }}
        onClick={(e) => handleIconClick(e, tiktokUrl)}
      >
        <img src={TikTokLogo} alt="TikTok" />
      </IconWithOverlay>
      <IconWithOverlay
        url={facebookUrl}
        style={{
          height: '28px',
          marginBottom: 10,
          paddingRight: '3px',
          width: '28px',
        }}
        onClick={(e) => handleIconClick(e, facebookUrl)}
      >
        <img src={facebookLogo} alt="Facebook" />
      </IconWithOverlay>
      <IconWithOverlay
        url={googleUrl}
        style={{
          height: '28px',
          marginBottom: 7,
          paddingRight: '3px',
          width: '28px',
        }}
        onClick={(e) => handleIconClick(e, googleUrl)}
      >
        <img src={GoogleLogo} alt="Google" />
      </IconWithOverlay>
    </Grid>
  );
};
