import { atom } from 'recoil';

import { Paginated } from '../types/common';
import { Message } from '../types/notifications';

export const messagesState = atom<Partial<Paginated<Message>>>({
  default: {
    '@nextLink': '',
    count: 0,
    total: 0,
  },
  key: 'messagesState',
});
