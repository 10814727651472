import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  fetchCategories,
  getAttributes,
  getCatalog,
  getLocationById,
} from '@app/adapter/catalog-service';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import {
  Attribute,
  Category,
  Product,
  ProductLocation,
} from '@app/types/catalog';

/**
 * プランIDからプランリストを取得するフック
 * プランIDが変わる毎に読み込むプランが増加する
 * ※一度読み込まれたプランはストックし続ける
 * ※新たに読み込めるプランIDは100件まで
 * @returns プランリスト
 */
export function useProductsAdd(
  productIds: string[] | undefined,
  isAdmin = false
) {
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Product[] | undefined);
  const load = useCallback(async () => {
    try {
      if (!productIds?.length) {
        return;
      }
      // NOTE:未取得のプランIDのみ取得
      const productIds2 = productIds.filter(
        (id) => !state?.some((p) => p.id === id)
      );
      if (!productIds2.length) {
        return;
      }
      const result = await getCatalog(
        isAdmin ? undefined : organizationState.id,
        {
          ids: productIds2,
          isAdmin,
          pageNumber: 0,
          pageSize: productIds2.length,
        }
      );
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      if (!result.data.value.length) {
        return;
      }
      setState((prev) => [...(prev ?? []), ...result.data.value]);
    } catch (error) {
      setSnackbarText(
        `プランの取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [
    productIds,
    isAdmin,
    state,
    organizationState,
    setSnackbarOpen,
    setSnackbarText,
  ]);

  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * ジャンルリストを取得するフック
 * @returns ジャンルリスト
 */
export function useAttributesClinicalDepartment() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Attribute[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['clinicalDepartment'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data.value ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `ジャンルの取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * プラン種別リストを取得するフック
 * @returns プラン種別リスト
 */
export function useAttributesJobType() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Attribute[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['jobType'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data.value ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `プラン種別の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * プラン区分リストを取得するフック
 * @returns プラン区分リスト
 */
export function useProductCategories() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Category[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await fetchCategories();
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const category = result.data.value.find((c) => c.name === 'プラン区分');
      setState(category?.children ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `プラン区分の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 住所を取得するフック
 * @returns 住所
 */
export function useProductLocation(locationId: string | undefined) {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as ProductLocation | undefined);
  const load = useCallback(async () => {
    try {
      if (!locationId) {
        return;
      }
      const result = await getLocationById(locationId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data);
    } catch (error) {
      setSnackbarText(
        `住所の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [locationId, setSnackbarOpen, setSnackbarText]);

  useEffect(() => {
    void load();
  }, [load]);
  return state;
}
