import { zodResolver } from '@hookform/resolvers/zod';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
  SelectChangeEvent,
  ListItem,
  List,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  createAttribute,
  getLocationByLevel,
  getLocationByParentId,
  getAttributeById,
  getAttributesByName,
} from '@app/adapter/catalog-service';
import {
  createChangeRequest,
  createOrganization,
  updateOrganizationInfo,
} from '@app/adapter/organization-service';
import { EquipmentCheckModal } from '@app/components/Registration/Confirmation/EquipmentCheckModal';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { loggedInUserState, userAuthInfoSelector } from '@app/domain/app';
import { organization as organizationAtom } from '@app/domain/organization';
import { ClinicListLocationState } from '@app/routes/ClinicList';
import { UploadFile } from '@app/routes/UploadFile';
import {
  OrganizationFormData,
  OrganizationFormValidator,
  ORGANIZATION_FORM_DEFAULT_VALUES,
} from '@app/schemas/registration';
import {
  Organization,
  ORGANIZATION_STATUS,
  SearchResult,
} from '@app/types/organization';
import { urlToBase64 } from '@app/utils/base64';
import { isError } from '@app/utils/error';
import { convertKana } from '@app/utils/format';
import {
  HTTP_STATUS_CODE_OK,
  HTTP_STATUS_CODE_CREATED,
  HTTP_STATUS_CODE_NO_CONTENT,
} from '@app/utils/httpStatusCode';
import { resizeImageURL } from '@app/utils/image';
import {
  unescapeHtml,
  unescapeHtmlAndConvertNewLinesToHtml,
} from '@app/utils/pattern';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

const SELECTABLE_ORGANIZATION_STATUS = {
  ...ORGANIZATION_STATUS,
  ALL: '__ALL__', // 重複しないように
};

const DEFAULT_IMAGE_PATH = process.env.PUBLIC_URL + '/store-sample-image.jpg';

// Organization interface の Object を FormData interface に mapping
const mappingOrganizationFormData = (
  editTarget: Organization
): OrganizationFormData => {
  const mapped = Object.entries(ORGANIZATION_FORM_DEFAULT_VALUES).reduce(
    (mapping, [key, value]) => {
      const matched = Object.entries(editTarget).find(
        ([targetKey]) => targetKey === key
      );
      // 型の確認
      if (!matched || typeof matched[1] !== typeof value) {
        return mapping;
      }
      return { ...mapping, [key]: matched[1] };
    },
    { ...ORGANIZATION_FORM_DEFAULT_VALUES } as OrganizationFormData
  );
  return mapped;
};

//
// Component
//
export type SelectableOrganizationStatus =
  (typeof SELECTABLE_ORGANIZATION_STATUS)[keyof typeof SELECTABLE_ORGANIZATION_STATUS];

export function ClinicCreateEdit() {
  const setSnackbar = useSetSnackbar();
  const currentOrganization = useRecoilValue(organizationAtom);

  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const loggedInUser = useRecoilValue(loggedInUserState);

  const theme = useTheme();
  const navigateTo = useNavigate();

  const location = useLocation();
  const params = useParams();
  const isEditMode = location.pathname === `/shop/${params.orgId}/edit`;

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [filterKeyword, setFilterKeyword] = useState<string>('');

  //
  // react-hook-form
  //
  const orgDefaultValue = isEditMode
    ? // Edit mode
      {
        ...mappingOrganizationFormData(currentOrganization),
        privacyPolicy: true,
        userAgreement: true,
      }
    : // Create mode
      {
        ...ORGANIZATION_FORM_DEFAULT_VALUES,
        description: currentOrganization?.description || '',
        ownerId: loggedInUser?.id || '',
        privacyPolicy: true,
        userAgreement: true,
      };

  const { control, watch, handleSubmit, formState, setValue } =
    useForm<OrganizationFormData>({
      defaultValues: orgDefaultValue,
      mode: 'onChange',
      resolver: zodResolver(OrganizationFormValidator),
    });
  const { errors, isValid } = formState;
  const [registerData, setRegisterData] =
    useState<OrganizationFormData>(orgDefaultValue);
  const watchAddressLine1 = watch('addressLine1');
  const shouldSkipValidation = watch('shouldSkipValidation');

  //
  // fileUpload
  //
  const [iconImageFile, setIconImageFile] = useState<null | string>(null);

  //
  // 都道府県選択
  //
  const [prefectureData, setPrefectureData] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [cityData, setCityData] = useState<Array<{ id: string; name: string }>>(
    []
  );
  const [isCityFetching, setIsCityFetching] = useState<boolean>(false);
  useEffect(() => {
    if (prefectureData.length > 0) {
      return; // skip
    }
    const fetchPrefectureData = async () => {
      try {
        const response = await getLocationByLevel();
        if (response.data?.value) {
          setPrefectureData(
            response.data.value.map(({ id, name }) => ({ id, name }))
          );
        }
      } catch (error) {
        console.error('Failed to fetch prefecture data:', error);
      }
    };
    void (async () => {
      await fetchPrefectureData();
      if (isEditMode && currentOrganization.addressLine1) {
        await fetchCityData(currentOrganization.addressLine1);
      }
    })();
  }, [prefectureData, setPrefectureData, isEditMode, currentOrganization]);

  const fetchCityData = async (prefectureId: string) => {
    try {
      setIsCityFetching(true);

      const response = await getLocationByParentId(prefectureId);
      if (response.data?.value) {
        setCityData(response.data.value.map(({ id, name }) => ({ id, name })));
      }
    } catch (error) {
      console.error('Failed to fetch city data:', error);
    } finally {
      setIsCityFetching(false);
    }
  };
  const handlePrefectureChange = async (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value as string;
    const selectedPrefecture = prefectureData.find(
      (pref) => pref.id === selectedId
    );
    // 都道府県の再選択時に市町村の選択を初期化
    setValue('addressLine2', '');
    // setCityData([]);

    setRegisterData((prev) => ({
      ...prev,
      addressLine1: selectedPrefecture ? selectedPrefecture.id : '',
    }));
    void fetchCityData(selectedId);
  };

  const handleCityChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCityId = e.target.value;
    const selectedCity = cityData.find((city) => city.id === selectedCityId);
    if (!selectedCity) {
      console.warn('[shopCreateEdit] handleCityChange() unexpected city id', {
        cityData,
        selectedCityId,
      });
      return;
    }
    const selectedPrefectureName = prefectureData.find(
      (pref) => pref.id === watchAddressLine1
    )?.name;
    const addressByCity = `${selectedPrefectureName}${selectedCity.name}`;

    // 連結した都道府県と市区郡をcustomField.addressByCityに格納
    setRegisterData((prev) => ({
      ...prev,
      addressLine2: selectedCity?.id as string,
      customFields: {
        ...prev.customFields,
        addressByCity,
      },
    }));
    setValue('customFields.addressByCity', addressByCity);
  };

  const handleSelectedEquipments = (genre: string[]) => {
    setRegisterData((prev) => ({
      ...prev,
      customFields: {
        ...prev.customFields,
        genre,
      },
    }));
  };

  useEffect(() => {
    const fetchAttributeAndSetDescription = async () => {
      if (isEditMode && currentOrganization.description) {
        try {
          const attributeResult = await getAttributeById(
            currentOrganization.description
          );
          if (
            attributeResult.status === 200 &&
            attributeResult.data.value.length > 0
          ) {
            const updatedData = {
              ...registerData,
              descriptionName: attributeResult.data.value[0].name,
            };
            setRegisterData(updatedData);
          }
        } catch (error) {
          console.error('attributeの取得中にエラーが発生しました:', error);
          setSnackbar(true, 'attributeの取得中にエラーが発生しました', 'error');
        }
      }
    };

    void fetchAttributeAndSetDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, isEditMode]);

  const performSearch = useCallback(
    async (keyword: string) => {
      if (!keyword.trim()) {
        setSearchResults([]);
        return;
      }
      const normalizedHiragana = convertKana(keyword);
      const normalizedKatakana =
        keyword === normalizedHiragana
          ? convertKana(normalizedHiragana)
          : keyword;

      const namesToSearch = [normalizedHiragana, normalizedKatakana].filter(
        (value, index, self) => self.indexOf(value) === index
      );

      const filterQuery = {
        groupName: 'corporation',
        name: namesToSearch,
      };

      try {
        const response = await getAttributesByName({
          filter: filterQuery,
        });
        setSearchResults(response.data.value);
      } catch (error) {
        console.error('検索中にエラーが発生しました:', error);
        setSnackbar(true, '検索中にエラーが発生しました', 'error');
      }
    },
    [setSnackbar]
  );

  useEffect(() => {
    void performSearch(filterKeyword);
  }, [filterKeyword, performSearch]);

  const handleSelectOrganization = useCallback(
    (item: SearchResult) => {
      setRegisterData((prevData) => ({
        ...prevData,
        description: item.id,
        descriptionName: item.name,
      }));
      setFilterKeyword('');
      setSearchResults([]);
    },
    [setFilterKeyword]
  );

  //
  // Submit
  //
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handleSubmitOrganization = async () => {
    isEditMode
      ? await handleSubmitOrganizationEdit()
      : await handleSubmitOrganizationCreate();
  };
  //
  // register
  //

  const handleSubmitOrganizationCreate = useCallback(
    async (/*formData: OrganizationFormData*/) => {
      setIsSubmitting(true);
      const {
        name,
        postalCode,
        addressLine1,
        addressLine2,
        addressLine3,
        phoneNumber,
        description,
        customFields,
      } = registerData;

      try {
        const iconImageData = await urlToBase64(
          iconImageFile || DEFAULT_IMAGE_PATH
        );

        const creatingOrgData = {
          addressLine1,
          addressLine2,
          addressLine3,
          certificateImageData: '', // 今のところ使っていない
          customFields: {
            ...customFields,
            ownerUserId: userAuthInfoState.id,
          },
          description,
          iconImageData,
          name,
          ownerId: userAuthInfoState.id,
          phoneNumber,
          postalCode,
        };
        const orgResult = await createOrganization(creatingOrgData);
        if (orgResult.status === HTTP_STATUS_CODE_CREATED) {
          navigateTo('/shop', {
            state: {
              referrer: location.pathname,
              success: true,
            } as ClinicListLocationState,
          });
        } else {
          console.warn('レスポンスコード不正', orgResult);
          throw new Error(
            `サーバから異常な値がレスポンスされました : ${orgResult.status}`
          );
        }
      } catch (error) {
        if (isError(error)) {
          setSnackbar(
            true,
            `店舗情報の登録が出来ませんでした, ${error.message}`,
            'error'
          );
        } else {
          setSnackbar(true, `店舗情報の登録が出来ませんでした`, 'error');
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      registerData,
      location,
      navigateTo,
      setSnackbar,
      userAuthInfoState,
      iconImageFile,
    ]
  );

  //
  // Edit (Update)
  //
  const handleSubmitOrganizationEdit = useCallback(
    async (/*formData: OrganizationFormData*/) => {
      setIsSubmitting(true);
      const {
        name,
        postalCode,
        addressLine1,
        addressLine2,
        addressLine3,
        phoneNumber,
        customFields,
        descriptionName,
        description,
        // certificateImageUri,
      } = registerData;

      const isRequiredChangeRequest =
        name !== currentOrganization.name ||
        addressLine1 !== currentOrganization.addressLine1 ||
        addressLine2 !== currentOrganization.addressLine2 ||
        addressLine3 !== currentOrganization.addressLine3 ||
        postalCode !== currentOrganization.postalCode;

      try {
        // Create change request to update organization
        // TODO: Adminの確認が必要

        if (isRequiredChangeRequest) {
          const changeRequestData = {
            addressLine1,
            addressLine2,
            addressLine3,
            name,
            postalCode,
          };
          const changeResult = await createChangeRequest(
            currentOrganization.id,
            changeRequestData
          );
          if (changeResult.status !== HTTP_STATUS_CODE_NO_CONTENT) {
            console.warn(
              'createChangeRequest() レスポンスコード不正',
              changeResult
            );
            throw new Error(
              `サーバから異常な値がレスポンスされました : change-${changeResult.status}`
            );
          }
        }
        // Update organization information (変更なくて時実行)
        const iconImageData =
          (iconImageFile && (await urlToBase64(iconImageFile))) || undefined;

        let attributeId;
        if (!description && descriptionName) {
          const attributeData = await createAttribute(descriptionName);
          attributeId = attributeData.id;
        } else {
          attributeId = description;
        }

        const updateRequestData = {
          customFields,
          description: attributeId,
          iconImageData,
          phoneNumber,
        };
        const updateResult = await updateOrganizationInfo(
          currentOrganization.id,
          updateRequestData
        );
        if (updateResult.status === HTTP_STATUS_CODE_OK) {
          navigateTo('/shop', {
            state: {
              referrer: location.pathname,
              success: true,
            } as ClinicListLocationState,
          });
        } else {
          console.warn(
            'updateOrganizationInfo() レスポンスコード不正',
            updateResult
          );
          throw new Error(
            `サーバから異常な値がレスポンスされました : update-${updateResult.status}`
          );
        }
      } catch (error) {
        if (isError(error)) {
          setSnackbar(
            true,
            `店舗情報の登録が出来ませんでした, ${error.message}`,
            'error'
          );
        } else {
          setSnackbar(true, `店舗情報の登録が出来ませんでした`, 'error');
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      registerData,
      location,
      navigateTo,
      setSnackbar,
      currentOrganization,
      iconImageFile,
    ]
  );

  //
  // Render
  //

  if (!loggedInUser) {
    return null; // guard
  }

  // edit mode では市町村までfetch完了してからRendering
  if (prefectureData.length === 0 || (isEditMode && cityData.length === 0)) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
        <Typography ml={2}>読み込み中</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" mb={2}>
        <Link to="/shop">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ height: '40px', mr: 2, width: '40px' }}
          >
            <ArrowBackIcon />
          </Button>
        </Link>
        <PageTitle title="店舗管理" />
      </Box>
      <Card>
        <CardContent>
          <Typography variant="h6" mb={2}>
            編集
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <form
            onSubmit={handleSubmit(handleSubmitOrganization, (errors) => {
              console.warn('[shopCreate] handleSubmit ERROR', errors);
            })}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={700}
                      display={'flex'}
                      component="span"
                    >
                      法人名
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        value={registerData.descriptionName || field.value}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setFilterKeyword(newValue);
                          setRegisterData((prev) => ({
                            ...prev,
                            description: '',
                            descriptionName: newValue,
                          }));
                          field.onChange(newValue);
                        }}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="法人名を入力"
                      />
                    )}
                  />

                  {searchResults.length > 0 && (
                    <List
                      sx={{
                        '& ul': { padding: 0 },
                        bgcolor: 'background.paper',
                        border: `solid 1px ${theme.customPalette.borderColor}`,
                        maxHeight: 300,
                        overflow: 'auto',
                        position: 'relative',
                        width: '80%',
                      }}
                      subheader={<li />}
                    >
                      {searchResults.map((item, itemIndex) => (
                        <ListItem
                          key={`item-${item.id}-${itemIndex}`}
                          button
                          onClick={() => handleSelectOrganization(item)}
                          style={{ alignItems: 'center', display: 'flex' }}
                        >
                          <Grid
                            style={{ alignItems: 'center', display: 'flex' }}
                          >
                            <Typography style={{ marginRight: '8px' }}>
                              {item.name}
                            </Typography>
                            {item.description && (
                              <Typography>({item.description})</Typography>
                            )}
                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={700}
                      display={'flex'}
                      component="span"
                    >
                      店舗名
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue={registerData.name}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={isEditMode /* TODO:API側制約 */}
                        value={unescapeHtml(registerData.name)}
                        onChange={(e) => {
                          setRegisterData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                          field.onChange(e);
                        }}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="店舗名を入力"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={700}
                      display={'flex'}
                      component="span"
                    >
                      連絡先電話番号
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue={registerData.phoneNumber}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        value={registerData.phoneNumber}
                        onChange={(e) => {
                          setRegisterData((prev) => ({
                            ...prev,
                            phoneNumber: e.target.value,
                          }));
                          field.onChange(e);
                        }}
                        type="tel"
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="連絡先電話番号（ハイフンなし）で入力"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid container sx={{ alignItems: 'center', pt: 3, px: 3 }}>
                <Grid item xs={9}>
                  <FormControl fullWidth variant="outlined">
                    <FormLabel>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                        fontWeight={700}
                        display={'flex'}
                        component="span"
                      >
                        住所
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </Typography>
                    </FormLabel>
                    <Grid container alignItems="center">
                      <Grid item xs={7}>
                        <Controller
                          name="postalCode"
                          control={control}
                          defaultValue={registerData.postalCode || ''}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              disabled={isEditMode /* TODO:API側制約 */}
                              value={registerData.postalCode}
                              onChange={(e) => {
                                setRegisterData((prev) => ({
                                  ...prev,
                                  postalCode: e.target.value,
                                }));
                                field.onChange(e);
                              }}
                              error={!!error}
                              helperText={error?.message}
                              margin="dense"
                              placeholder="郵便番号を入力"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    {errors.postalCode &&
                    errors.postalCode.type !== 'invalid_string' ? (
                      <FormHelperText error>
                        正しい郵便番号を入力してください
                      </FormHelperText>
                    ) : undefined}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                sx={{ pt: 3, px: 4 }}
                alignItems="center"
              >
                <Box sx={{ flex: '1 1 auto', pl: 2, pt: 0.5 }}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="addressLine1"
                      control={control}
                      rules={{ required: '都道府県を選択してください' }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl variant="outlined" error={!!error}>
                          <Select
                            {...field}
                            disabled={isEditMode /* TODO:API側制約 */}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              void handlePrefectureChange(e);
                            }}
                            margin="dense"
                            displayEmpty
                            renderValue={(selectedId) => {
                              const selectedName =
                                prefectureData.find(
                                  (pref) => pref.id === selectedId
                                )?.name || '';
                              if (selectedName) {
                                return selectedName;
                              }
                              return (
                                <span
                                  style={{
                                    color: theme.customPalette.lightGray,
                                  }}
                                >
                                  都道府県を選択
                                </span>
                              );
                            }}
                          >
                            <MenuItem value="" disabled>
                              都道府県を選択
                            </MenuItem>
                            {prefectureData.map((prefecture) => (
                              <MenuItem
                                key={prefecture.id}
                                value={prefecture.id}
                              >
                                {prefecture.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ flex: '1 1 auto', pl: 1 }}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="addressLine2"
                      control={control}
                      defaultValue={registerData.addressLine2}
                      render={({ field, fieldState: { error } }) =>
                        isCityFetching ? (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress />
                            <Typography ml={2}>更新中</Typography>
                          </Box>
                        ) : (
                          <TextField
                            {...field}
                            disabled={isEditMode /* TODO:API側制約 */}
                            value={cityData.length > 0 ? field.value : ''}
                            onChange={(e) => {
                              handleCityChange(e);
                              field.onChange(e);
                            }}
                            error={!!error}
                            helperText={error?.message}
                            margin="dense"
                            placeholder="市町村を選択"
                            select
                          >
                            {cityData.length > 0 ? (
                              cityData.map((city) => (
                                <MenuItem key={city.id} value={city.id}>
                                  {city.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value="">(loading)</MenuItem>
                            )}
                          </TextField>
                        )
                      }
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid container spacing={3} sx={{ px: 3 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="addressLine3"
                      control={control}
                      defaultValue={registerData.addressLine3}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          disabled={isEditMode /* TODO:API側制約 */}
                          value={field.value}
                          onChange={(e) => {
                            setRegisterData((prev) => ({
                              ...prev,
                              addressLine3: e.target.value,
                            }));
                            field.onChange(e);
                          }}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="町名・番地を入力"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ px: 3 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="customFields.addressLine4"
                      control={control}
                      defaultValue={registerData.customFields.addressLine4}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={unescapeHtml(field.value)}
                          onChange={(e) => {
                            const updatedCustomFields = {
                              ...registerData.customFields,
                              addressLine4: e.target.value,
                            };
                            setRegisterData((prev) => ({
                              ...prev,
                              customFields: updatedCustomFields,
                            }));
                            field.onChange(e);
                          }}
                          margin="dense"
                          placeholder="建物名・部屋番号を入力"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={700}
                      display={'flex'}
                      component="span"
                    >
                      アクセス情報
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="customFields.access"
                    control={control}
                    defaultValue={registerData.customFields.access}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        value={unescapeHtml(field.value)}
                        onChange={(e) => {
                          setRegisterData((prev) => ({
                            ...prev,
                            customFields: {
                              ...prev.customFields,
                              access: e.target.value,
                            },
                          }));
                          field.onChange(e);
                        }}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="アクセス情報を入力   例）JR山手線新宿駅から徒歩5分"
                        multiline
                        rows={2}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={700}
                      display={'flex'}
                      component="span"
                    >
                      店舗のWebページ
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="customFields.clinicUrl"
                    control={control}
                    defaultValue={registerData.customFields.clinicUrl}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        onChange={(e) => {
                          setRegisterData((prev) => ({
                            ...prev,
                            customFields: {
                              ...prev.customFields,
                              clinicUrl: e.target.value,
                            },
                          }));
                          field.onChange(e);
                        }}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="https://www.pro-med.com"
                        multiline
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={700}
                      display={'flex'}
                      component="span"
                    >
                      店長名
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="customFields.ownerName"
                    control={control}
                    defaultValue={registerData.customFields.ownerName}
                    rules={{
                      required: !shouldSkipValidation,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        onChange={(e) => {
                          setRegisterData((prev) => ({
                            ...prev,
                            customFields: {
                              ...prev.customFields,
                              ownerName: e.target.value,
                            },
                          }));
                          field.onChange(e);
                        }}
                        error={!shouldSkipValidation && !!error}
                        helperText={!shouldSkipValidation && error?.message}
                        margin="dense"
                        placeholder="店長名を入力"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ px: 3 }}>
                <Stack
                  flexDirection="column"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    component="span"
                    sx={{ mb: 1 }}
                  >
                    ジャンル
                  </Typography>
                  <EquipmentCheckModal
                    selectedItems={registerData.customFields.genre}
                    onUpdate={handleSelectedEquipments}
                    disabled={isEditMode}
                  />
                  <Typography
                    color={theme.customPalette.gray}
                    variant="body2"
                    component="span"
                    sx={{ mt: 2 }}
                  >
                    ※インフルエンサー側に表示されるジャンルは最大2個までです
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={700}
                      component="span"
                    >
                      ジャンル（その他）
                    </Typography>
                  </FormLabel>
                  <Controller
                    name="customFields.genreOther"
                    control={control}
                    defaultValue={registerData.customFields.genreOther}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={unescapeHtml(field.value)}
                        onChange={(e) => {
                          setRegisterData((prev) => ({
                            ...prev,
                            customFields: {
                              ...prev.customFields,
                              genreOther: e.target.value,
                            },
                          }));
                          field.onChange(e);
                        }}
                        margin="dense"
                        placeholder="その他のジャンルを入力"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={10} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      fontWeight={700}
                      display={'flex'}
                      component="span"
                    >
                      店舗イメージ・写真(サイズは最大5MBまで)
                    </Typography>
                  </FormLabel>

                  {
                    // 画像設定済みの場合
                    isEditMode && !iconImageFile && (
                      <div
                        style={{
                          height: '300px',
                          padding: '10px',
                          width: '100%',
                        }}
                      >
                        <img
                          alt="shop"
                          style={{
                            height: '100%',
                            objectFit: 'contain',
                            width: 'auto',
                          }}
                          src={currentOrganization.iconImageUri}
                        />
                      </div>
                    )
                  }

                  <UploadFile
                    sx={{ padding: '10px 10px 10px 0' }}
                    fileUrl={
                      /*
                         制御しにくいので ReactHookForm の管理外とする
                       */
                      iconImageFile || ''
                    }
                    setImageUrl={async (data: string) => {
                      const resizeUrl = await resizeImageURL(
                        data,
                        800,
                        534,
                        3 / 2
                      );
                      setIconImageFile(resizeUrl);
                    }}
                    addImageButtonText="画像をアップロード"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ px: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    display={'flex'}
                    component="span"
                  >
                    店舗の一言アピール(200文字まで)
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography>
                  <Controller
                    name="customFields.clinicAppealMessage"
                    control={control}
                    defaultValue={registerData.customFields.clinicAppealMessage}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        value={unescapeHtmlAndConvertNewLinesToHtml(
                          field.value
                        )}
                        onChange={(e) => {
                          setRegisterData((prev) => ({
                            ...prev,
                            customFields: {
                              ...prev.customFields,
                              clinicAppealMessage: e.target.value,
                            },
                          }));
                          field.onChange(e);
                        }}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="大人気の飲み放題プランもご用意しております"
                        multiline
                        rows={9}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ px: 3 }}>
                <Grid item xs={12} sx={{ pt: 6, px: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!isValid}
                    loading={isSubmitting}
                  >
                    送信
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
