import _ from 'lodash';
import IO, { Socket } from 'socket.io-client';

import { CHAT_SERVICE } from './adapter/axios';
import { Message } from './types/chats';
import { getAuthorizationHeader } from './utils/authorization';
export class ChatClient {
  socket: Socket | undefined;
  async init(token: string, fingerprint: string) {
    if (!token || !fingerprint) {
      //logger.warn('token or fingerprint are not set');
      return false;
    }
    this.socket = IO(`${CHAT_SERVICE}/chats`, {
      query: {
        'x-nb-fingerprint': fingerprint,
        ...getAuthorizationHeader(token),
      },
      transports: ['websocket'],
    });
    return true;
  }
  isConnected() {
    return this.socket && this.socket.connected;
  }
  isReady() {
    return !_.isNil(this.socket);
  }
  disconnect() {
    this.socket?.removeAllListeners();
    this.socket?.disconnect();
  }

  setHandler(key: string, handler: (...args: unknown[]) => void) {
    if (!this.isReady()) {
      //logger.info('is not ready');
    }
    this.socket?.on(key, handler);
  }
  emit(key: string, payload: unknown) {
    this.socket?.emit(key, payload);
  }
}

export const chatSocket = new ChatClient();

export function attachListeners(
  socket: ChatClient,
  callback: (message: Message) => void
) {
  socket.setHandler('message', async (notification: unknown) => {
    callback(notification as Message);
  });
}

export function subscribeToStream(socket: ChatClient, userId: string) {
  socket.emit('subscribe', { userId });
}
