import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ReactElement, ReactNode, useCallback } from 'react';

interface ConfirmDialogProps {
  children?: ReactNode;
  okButtonLoading?: boolean;
  okButtonText?: string;
  onClose: (confirm?: boolean) => void;
  open: boolean;
  title?: ReactNode;
}

export function ConfirmDialog({
  okButtonLoading,
  okButtonText,
  open,
  onClose,
  title,
  children,
}: ConfirmDialogProps): ReactElement {
  const handleClose = useCallback((): void => {
    if (okButtonLoading) {
      return;
    }
    onClose(false);
  }, [okButtonLoading, onClose]);

  const handleConfirm = useCallback(async () => {
    onClose(true);
  }, [onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        id: 'e2e-test-confirm-dialog',
      }}
    >
      {title && <DialogTitle sx={{ minWidth: 450 }}>{title}</DialogTitle>}
      {children && (
        <DialogContent sx={{ minWidth: 450 }}>{children}</DialogContent>
      )}
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          disabled={okButtonLoading}
          variant="outlined"
          color="secondary"
          sx={{ width: '10rem' }}
          onClick={handleClose}
        >
          キャンセル
        </Button>
        <LoadingButton
          loading={okButtonLoading}
          variant="contained"
          sx={{ width: '10rem' }}
          onClick={handleConfirm}
        >
          {okButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
