import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Grid,
  Typography,
  Box,
  Paper,
  Divider,
  TextField,
  IconButton,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { postMessageTemplates } from '../../adapter/chat-service';
import { organization } from '../../domain/organization';

export const TemplateRegister = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const orgId = useRecoilValue(organization).id;

  const validateFields = () => {
    let isValid = true;
    if (!title.trim()) {
      setTitleError('テンプレートタイトルは必須です。');
      isValid = false;
    } else {
      setTitleError('');
    }
    if (!message.trim()) {
      setMessageError('メッセージは必須です。');
      isValid = false;
    } else {
      setMessageError('');
    }
    return isValid;
  };

  const handleRegister = async () => {
    if (validateFields()) {
      try {
        await postMessageTemplates(title, message, orgId);
        navigate('/chat/templateList');
      } catch (error) {
        console.error('テンプレートの登録に失敗しました:', error);
      }
    }
  };

  const handlePreview = () => {
    if (validateFields()) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Grid container sx={{ pb: 2 }}>
        <IconButton
          onClick={() => navigate('/chat/templateList')}
          sx={{
            backgroundColor: 'white',
            border: '1px solid',
            borderRadius: '4px',
            mr: 2,
            p: '0px 15px',
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          sx={{ pb: 2, pt: 2, textAlign: 'left' }}
        >
          テンプレート一覧
        </Typography>
      </Grid>
      <Paper elevation={1}>
        <Box sx={{ display: 'flex', padding: '30px' }}>
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h5">新規登録</Typography>
            <Grid>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handlePreview}
                sx={{ mr: 3, p: '4px 30px', textAlign: 'left' }}
              >
                プレビュー
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handlePreview}
                sx={{ p: '4px 30px', textAlign: 'left' }}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', padding: '30px' }}>
          <Grid sx={{ width: '100%' }}>
            <Grid sx={{ display: 'flex', pb: 5 }}>
              <Typography sx={{ color: theme.customPalette.alert }}>
                *
              </Typography>
              <Typography>は必須事項です</Typography>
            </Grid>
            <Grid sx={{ display: 'flex' }}>
              <Typography>テンプレートタイトル</Typography>
              <Typography sx={{ color: theme.customPalette.alert }}>
                *
              </Typography>
              <Typography sx={{ color: theme.customPalette.lightGray, pl: 3 }}>
                管理用のタイトルですユーザーには表示されません
              </Typography>
            </Grid>
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={Boolean(titleError)}
              helperText={titleError}
              sx={{ pb: 3, width: '80%' }}
            />
            <Grid sx={{ display: 'flex' }}>
              <Typography>メッセージ</Typography>
              <Typography sx={{ color: theme.customPalette.alert }}>
                *
              </Typography>
            </Grid>
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              error={Boolean(messageError)}
              helperText={messageError}
              multiline
              rows={6}
              sx={{ pb: 5, width: '80%' }}
            />
          </Grid>
        </Box>
      </Paper>
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <Grid sx={{ padding: '20px' }}>
          <DialogTitle>プレビュー</DialogTitle>
          <DialogContent>
            <Typography>{title}</Typography>
            <Grid
              sx={{
                backgroundColor: `${theme.palette.primary.main}`,
                borderRadius: '20px 20px 0px 20px',
                color: `${theme.customPalette.white}`,
                margin: '10px',
                padding: '12px 15px',
              }}
            >
              <Typography
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  __html: message.replace(/\n/g, '<br />'),
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseModal}
              color="secondary"
              variant="outlined"
            >
              閉じる
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleRegister}
              sx={{ p: '4px 30px', textAlign: 'left' }}
            >
              登録
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
};
